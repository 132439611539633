import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {EHeightUnits, IUser} from "@models/user";
import {Subject} from "rxjs";
import {cmToFeetAndInches, deepClone} from "@helpers/misc";
import {UserDetailsComponent} from "@shared/components/user-details/user-details.component";
import {CustomUnitPipe} from "@shared/pipes/custom-currency.pipe";
import {GestureConfig, GestureController, ModalController} from "@ionic/angular";
import {EPlatform, STORAGE_LIKE_TUTORIAL, TOAST_POSITION} from "@models/general";
import {EResultElo, IVoteWinner} from "@models/elo";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {MatchesService} from "@services/matches.service";
import {calculateEloRating} from "@helpers/elo";
import {UsersService} from "@services/users-service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {EloService} from "@services/elo-service";
import {Preferences} from "@capacitor/preferences";
import {TranslateService} from "@ngx-translate/core";
import {Gesture} from "@ionic/core";
import {Capacitor} from "@capacitor/core";
import {PurchaseConsumableComponent} from "@shared/components/purchase-consumable/purchase-consumable.component";
import {EPremiumType} from "@models/premium";
import {ModalService} from "@services/modal-service";

@Component({
    selector: 'app-custom-swiper',
    templateUrl: './custom-swiper.component.html',
    styleUrls: ['./custom-swiper.component.scss'],
})
export class CustomSwiperComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

    @ViewChild('swiper', {static: false})
    swiper?: any;
    @ViewChild('container', {static: false})
    container?: any;
    @ViewChild('swiperOverlay', {static: false})
    swiperOverlay?: any;
    @ViewChild('pointsStamp', {static: false})
    pointsStamp?: any;

    @Input()
    isUserDetails = false;

    @Input()
    displayLike = false;

    @Input()
    displayInstantMatch = false;

    @Input()
    isBehind = false;

    @Input()
    user?: IUser;

    @Input()
    user2?: IUser;

    @Input()
    swiperPagination = true;

    @Input()
    isTutorial = false;

    @Input()
    fromAdmin = false;

    @Output()
    voteEvent = new EventEmitter<IVoteWinner>();

    allMatchsUsersIds: string[] = [];
    allLikesUsersIds: string[] = [];
    currentUser?: IUser;
    displayBubble = false;
    voteInterval?: any;
    movingCard = false;
    selectedHeightUnit = EHeightUnits.centimeters;
    pastSelectionsIndexes: any[] = [];
    lastClickTime = 0;
    autoSwipe = true;
    newPoints = 0;
    winPoints = 0;
    losePoints = 0;
    currentGesture?: Gesture;
    EPlatform = EPlatform;
    platform = EPlatform.ios;
    private subscription = new Subject<void>();

    constructor(
        private router: Router,
        private matchesService: MatchesService,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService,
        private modalService: ModalService,
        private eloService: EloService,
        private gestureCtrl: GestureController,
        private customUnitPipe: CustomUnitPipe,
        private translate: TranslateService,
        private renderer: Renderer2,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private modalController: ModalController) {
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        this.resetCss();
        // @ts-ignore
        const user = changes?.user?.currentValue as IUser;
        const currentDocumentId = changes?.user?.currentValue?.documentId;
        const previousDocumentId = changes?.user?.previousValue?.documentId;


        if (currentDocumentId !== previousDocumentId) {
            this.user = user;
            // console.log(user);
            // console.log(this.isBehind);

            if (this.user?.documentId) {
                this.calculatePoints();
            }
        }
    }

    ngOnInit() {
        this.platform = Capacitor.getPlatform() as EPlatform;
        this.getCurrentUser();
        // this.getAutoSwiper();
        if (!this.isUserDetails) {
            this.getOnMoveCard();
            this.getOnEndCard();
        }
        this.getAllMatchsIds();
        this.getAllLikesIds();
    }

    ngAfterViewInit() {
        this.initSwiper();
    }

    ngOnDestroy() {
        this.unsubscribeSlideEvent();
        this.subscription.next();
        this.subscription.complete();
    }

    isAMatch(): boolean {
        if (!this.user?.documentId) {
            return false;
        }

        return this.allMatchsUsersIds.includes(this.user.documentId);
    }

    isALike(): boolean {
        if (!this.user?.documentId) {
            return false;
        }

        return this.allLikesUsersIds.includes(this.user.documentId);
    }

    async likeUser(instantMatch = false, event?: any) {
        if (!this.user?.documentId) {
            return;
        }
        event?.stopPropagation();

        if (this.isAMatch()) {
            return;
        }
        if (!instantMatch && this.isALike()) {
            return;
        }

        console.log("like", this.user?.firstName);
        if (this.isUserDetails) {
            const name = this.user.firstName;
            if (instantMatch) {
                const message = this.translate.instant('instantMatching', {name});
                await this.loadingToastAlertProvider.presentLoadingWithOptions(message);
            } else if (this.displayLike && !instantMatch) {
                const message = this.translate.instant('sendLikeTo', {name});
                await this.loadingToastAlertProvider.presentLoadingWithOptions(message);
            }
        }

        const message = this.translate.instant("likeSentTo", {name: this.user.firstName})
        this.loadingToastAlertProvider.presentToastWithOptions(message, false, TOAST_POSITION.TOP);
        setTimeout(() => {
            this.likeTutorial();
        }, 300);


        try {
            await this.matchesService.likeCall(this.user.documentId, instantMatch);
        } catch (e: any) {
            console.error(e);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async instantMatch(event?: any) {
        event?.stopPropagation();
        if (!this.user?.documentId || !this.isUserDetails || this.displayBubble) {
            return;
        }

        try {
            const message = this.translate.instant("areYouSureUseInstantMatch",
                {name: this.user?.firstName});
            await this.loadingToastAlertProvider.confirmationPopup(
                "instantMatchAlert", message);
        } catch (e: any) {
            return;
        }

        const instantsMatchLeft = this.currentUser?.instantMatchs || 0;
        if (instantsMatchLeft <= 0) {
            try {
                const message = this.translate.instant("buyInstantMatch",
                    {name: this.user?.firstName});
                await this.loadingToastAlertProvider.buyInstantsMatchPopup(
                    "instantMatchBuyTitle", message);
                this.openConsumablePremium();
                return;
            } catch (e: any) {
                return;
            }
        }

        this.displayBubble = true;
        await this.likeUser(true);
        this.displayBubble = false;
    }

    nextSlide(event: any): void {
        event?.stopPropagation();
        if (this.swiper?.nativeElement?.swiper) {
            this.swiper.nativeElement.swiper.slideNext();
            this.swiper.nativeElement.swiper.autoplay.stop();
        }
    }

    async vote(swipeLeft = false) {
        if (this.isBehind || !this.user || !this.user2 || this.movingCard || this.isUserDetails) {
            return;
        }
        if (!this.swiper?.nativeElement || !this.pointsStamp?.nativeElement) {
            return;
        }

        this.movingCard = true;
        const maxAppWidth = 744;
        const screenSize = window.innerWidth > maxAppWidth ? maxAppWidth : window.innerWidth;
        const timeMs = 445; // timeNeedToSwipe
        const delay = 12 * (timeMs / screenSize);

        const componentWidth = this.swiper.nativeElement.offsetWidth;
        const limitExtend = 50;
        console.log(componentWidth);
        let deltaX = 0;
        this.voteInterval = setInterval(() => {
            if (swipeLeft) {
                deltaX -= 10;
            } else {
                deltaX += 10;
            }
            this.matchesService.setOnMoveCard(deltaX);
            this.onMoveCard(deltaX);
            if (deltaX > componentWidth + limitExtend || deltaX < -1 * componentWidth - limitExtend) {
                clearInterval(this.voteInterval);
                this.matchesService.setOnEndCard(deltaX);
                this.onEndCard(deltaX);
            }
        }, delay)
    }

    prevSlide(event: any): void {
        event?.stopPropagation();
        if (this.swiper?.nativeElement?.swiper) {
            this.swiper.nativeElement.swiper.slidePrev();
            this.swiper.nativeElement.swiper.autoplay.stop();
        }
    }

    async alreadyMatch(ev?: any) {
        ev?.stopPropagation();
        try {
            const message = this.translate.instant("youAlreadyMatch", {name: this.user?.firstName});
            await this.loadingToastAlertProvider.presentAlert(
                "alreadyMatch", message)
        } catch (e: any) {

        }
    }

    async getUserDetails(user: IUser) {
        // this.router.navigate([ERoutes.USER, user.documentId], {replaceUrl: false});
        // return;
        const modal = await this.modalController.create({
            component: UserDetailsComponent as any,
            componentProps: {
                user,
                fromVote: true
            }
        });
        await modal.present();
        this.modalService.openingModal();
    }

    getHeight(heightCm: number): string {
        if (this.selectedHeightUnit === EHeightUnits.feetInches) {
            const resultFeetInches = cmToFeetAndInches(heightCm);
            const heightFeet = resultFeetInches?.feet || 0;
            const heightInches = resultFeetInches?.inches || ''

            return `${heightFeet}' ${heightInches}"`;
        }

        return this.customUnitPipe.transform(`${heightCm}`, 'cm') as string;
    }

    // private reinitSwiper() {
    //     this.cdr.detectChanges();
    //     setTimeout(() => {
    //         // TODO transparent image or swipe not working
    //         if (this.swiper?.nativeElement?.swiper) {
    //             console.log(this.user?.firstName, this.swiper.nativeElement.swiper);
    //             // this.swiper.nativeElement.swiper.updateSlides();
    //             // this.swiper.nativeElement.swiper.slideReset();
    //             // this.swiper.nativeElement.swiper.updateActiveIndex();
    //             this.swiper.nativeElement.swiper.slideTo(0);
    //             this.swiper.nativeElement.swiper.update();
    //             this.cdr.detectChanges();
    //         }
    //     }, 400)
    // }

    private async openConsumablePremium() {
        const modal = await this.modalController.create({
            component: PurchaseConsumableComponent as any,
            componentProps: {
                type: EPremiumType.instantMatch
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            const data = res?.data;
            console.log(data);
        })
    }

    private calculatePoints() {
        if (!this.user || !this.user2 || this.isUserDetails) {
            return;
        }
        let newRatings = calculateEloRating(this.user.elo, this.user2.elo, EResultElo.win);
        let newElo = newRatings.newRatingA;
        this.winPoints = newElo - this.user.elo;
        newRatings = calculateEloRating(this.user.elo, this.user2.elo, EResultElo.lose);
        newElo = newRatings.newRatingA;
        this.losePoints = newElo - this.user.elo;
    }

    private async voteEmit() {
        if (!this.user || !this.user2) {
            return;
        }

        // console.log(this.swiper.nativeElement.swiper);
        let winnerImg = "";
        if (this.swiper?.nativeElement?.swiper && this.user?.images?.length) {
            const activeIndex = this.swiper.nativeElement.swiper.activeIndex;
            winnerImg = this.user?.images[activeIndex];
        }
        console.log("winner", this.user?.firstName);
        console.log("loser", this.user2?.firstName);
        this.voteEvent.emit({
            winner: this.user,
            loser: this.user2,
            img: winnerImg
        })
    }

    private initSwiper() {
        setTimeout(() => {
            // this.setAutoplaySwiper();
            if (!this.isUserDetails) {
                this.initGesture();
            }
        }, 500)
    }

    private unsubscribeSlideEvent() {
        if (this.swiper?.nativeElement?.swiper) {
            this.swiper.nativeElement.swiper.off('slideChange')
        }
    }

    // private setAutoplaySwiper() {
    //     const delay = 3000;
    //     if (this.swiper?.nativeElement?.swiper) {
    //         this.swiper.nativeElement.swiper.update();
    //         if (this.autoSwipe && this.user?.images?.length) {
    //             this.swiper.nativeElement.swiper.params.autoplay.delay = delay;
    //             this.swiper.nativeElement.swiper.autoplay.start();
    //         }
    //     }
    // }

    private async likeTutorial() {
        let displayTutorial = (await Preferences.get({key: STORAGE_LIKE_TUTORIAL}))?.value;
        if (!displayTutorial) {
            const params = {
                name: this.user?.firstName || "profile 1",
                name2: this.user2?.firstName || "profile 2",
            };
            try {
                const alert = await this.loadingToastAlertProvider.presentAlertLikeTutorial(
                    "likeTutorialTitle", "likeTutorialDescription",
                    false, params, "help-modal");
                console.log(alert);
                if (alert?.length) {
                    await Preferences.set({
                        key: STORAGE_LIKE_TUTORIAL,
                        value: 'true',
                    })
                }
            } catch (e: any) {

            }
        }
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(async data => {
            this.currentUser = data;
        });
    }

    // private getAutoSwiper() {
    //     this.eloService.getAutoSwiper().pipe(takeUntil(this.subscription)).subscribe(async data => {
    //         this.autoSwipe = !!data;
    //         if (this.swiper?.nativeElement?.swiper?.autoplay) {
    //             if (this.autoSwipe) {
    //                 if (!this.swiper?.nativeElement?.swiper?.autoplay?.running) {
    //                     this.swiper.nativeElement.swiper.autoplay.start();
    //                 }
    //             } else {
    //                 if (this.swiper?.nativeElement?.swiper?.autoplay?.running) {
    //                     this.swiper.nativeElement.swiper.autoplay.stop();
    //                 }
    //             }
    //         }
    //     });
    // }

    private async initGesture() {
        if (this.currentGesture) {
            this.currentGesture.destroy();
        }
        if (!this.swiper?.nativeElement
            || !this.swiperOverlay?.nativeElement) {
            return;
        }
        const style = this.swiper.nativeElement.style;

        const options: GestureConfig = {
            el: this.swiperOverlay.nativeElement,
            gestureName: 'tinder-swipe',
            threshold: 10,
            onStart: () => {
                this.movingCard = true;
                style.transition = 'none';
            },
            onMove: (ev) => {
                this.matchesService.setOnMoveCard(ev.deltaX);
                this.onMoveCard(ev.deltaX);
            },
            onEnd: (ev) => {
                this.matchesService.setOnEndCard(ev.deltaX);
                this.onEndCard(ev.deltaX);
            },
        };

        this.currentGesture = this.gestureCtrl.create(options);
        this.currentGesture.enable();
    }

    private getOnMoveCard() {
        this.matchesService.getOnMoveCard().pipe(takeUntil(this.subscription)).subscribe(data => {
            if (!this.isBehind && !this.movingCard) {
                this.onMoveCard(-1 * data);
            }
        });
    }

    private getOnEndCard() {
        this.matchesService.getOnEndCard().pipe(takeUntil(this.subscription)).subscribe(data => {
            if (!this.isBehind && !this.movingCard) {
                this.onEndCard(-1 * data);
            }
        });
    }

    private onMoveCard(deltaX: number) {
        if (!this.container?.nativeElement || !this.pointsStamp?.nativeElement) {
            return;
        }

        // console.log(deltaX);
        const pointsStyle = this.pointsStamp.nativeElement.style;
        const style = this.container.nativeElement.style;

        style.transform = `translate3d(${deltaX}px, 0, 0) rotate(${
            deltaX / 20
        }deg)`;

        let pointsScale = Math.abs(deltaX / 50);
        pointsScale = pointsScale > 2 ? 2 : pointsScale;
        let opacity = Math.abs(deltaX / 150);
        opacity = opacity > 1 ? 1 : opacity;

        pointsStyle.opacity = 1;
        pointsStyle.transformOrigin = '50% 50%';
        pointsStyle.transform = `rotate(${deltaX / 20}deg) scale(${pointsScale})`;
        pointsStyle.transform = `translate(-50%, -50%) scale(${pointsScale})`;

        if (deltaX < 0) {
            this.newPoints = this.losePoints;
        } else {
            this.newPoints = this.winPoints;
        }
    }

    private onEndCard(deltaX: number) {
        if (!this.container?.nativeElement ||
            !this.pointsStamp?.nativeElement) {
            return;
        }
        const pointsStyle = this.pointsStamp.nativeElement.style;
        const style = this.container.nativeElement.style;
        const windowWidth = deepClone(this.container.nativeElement.offsetWidth);

        style.transition = 'transform 0.3s ease-out';
        const swipeDistance = 4;
        if (deltaX > windowWidth / swipeDistance) {
            style.transform = `translate3d(${windowWidth * 1.5}px, 0, 0)`;
            setTimeout(() => {
                this.voteEmit();
            }, 300)
        } else if (deltaX < -windowWidth / swipeDistance) {
            style.transform = `translate3d(-${windowWidth * 1.5}px, 0, 0)`;
        } else {
            style.transform = '';
            pointsStyle.opacity = 0;
            pointsStyle.transform = '';
        }
        this.movingCard = false;
    }

    private resetCss() {
        if (this.container?.nativeElement) {
            const style = this.container.nativeElement.style;
            style.transform = '';
            style.transition = 'none';
        }
        if (this.pointsStamp?.nativeElement) {
            const pointsStyle = this.pointsStamp.nativeElement.style;
            pointsStyle.opacity = 0;
            pointsStyle.transform = '';
        }
    }

    private getAllMatchsIds() {
        this.matchesService.getAllMatchsUsersIds().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.allMatchsUsersIds = data;
        });
    }

    private getAllLikesIds() {
        this.matchesService.getAllLikesUsersIds().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.allLikesUsersIds = data;
        });
    }

}
