import {DeviceInfo} from "@capacitor/device/dist/esm/definitions";
import {ELang, ERole, ERoutes} from "./general";
import {ECountries, ILatLong} from "./geo";
import {IVoteUser} from "./elo";
import {EStripeProduct} from "./stripe";
import {EUsersFiltersAdmin} from "./admin";

export interface IUser extends ILatLong, IVoteUser {
    documentId: string;
    created: number;
    updated: number;
    lastConnected: any;
    elo: number;
    email?: string;
    spark?: boolean; // mean user is generated
    isInit: boolean;
    emailVerified?: boolean;
    deleted?: boolean;
    visible: boolean;
    rankVisible: boolean;
    distanceVisible: boolean;
    sexualOrientationVisible: boolean;
    ageVisible: boolean;
    heightVisible: boolean;
    eloVisible: boolean;
    winCount: number;
    likesRatio: number; // likesReceivedCount/likesSentCount
    likesReceivedCount: number;
    likesSentCount: number; // number of likes user sent
    matchsIds: string[];
    votesReceivedCount: number;
    votesSentCount: number; // number of vote click of current user
    heightCm: number;
    lastVoteTimestamp: number; // timestamp of vote sent
    lang: ELang;
    isPremium: boolean;
    banned: boolean;
    roles?: ERole[];
    reportsCount: number;
    reportedBy: string[];
    geoHash?: string;
    images: string[];
    country?: ECountries,
    bio?: string;
    birthday: string;
    birthdayTimestamp: number;
    gender: EGender;
    sexualOrientation?: ESexualOrientation;
    firstName?: string;
    distanceKm?: number;
    age?: number;
    ageFilter: number;
    eloFilter: number;
    instagram?: string;
    tiktok?: string;
    profileBoosts: number;
    instantMatchs: number;
    boostInProgress: boolean;
    boostProgressCount: number;
    notificationTokens: string[];
    matchNotification: boolean;
    likeNotification: boolean;
    messageNotification: boolean;
    eloNotification: boolean;
    lastPremiumPayTimestamp?: number;
    stripeSubscriptionId?: string;
    stripeSubscriptionEnd?: number;
    isDemo?: boolean; // true for demo free copyrighted used
    deviceId?: string;
    ipAddress?: string;
    tinderGender?: EGender;
    others?: any; // data from tinder
    stripeProductsIdPaid: EStripeProduct[]; // when we receive a payment from stripe we add id here to display modal
}

export interface IUserHistory extends ILatLong {
    created?: number;
    device: DeviceInfo;
    lang: string;
    deviceId: string;
    ipAddress?: string;
    userAgent?: string;
}

export interface IUserLang {
    lang: string;
}

export enum EGender {
    WOMAN = "WOMAN",
    MAN = "MAN",
    OTHERS = "OTHERS",
    EVERYONE = "EVERYONE"
}

export enum ESexualOrientation {
    STRAIGHT = "STRAIGHT",
    GAY = "GAY",
    LESBIAN = "LESBIAN",
    BISEXUAL = "BISEXUAL",
    OTHERS = "OTHERS",
    EVERYONE = "EVERYONE"
}

export interface IUserCreationPayload {
    firstName: string;
    birthday: string;
    gender: EGender;
    sexualOrientation: ESexualOrientation;
    images: string[];
    heightCm?: number;
    latitude: number;
    longitude: number;
}


export enum EHeightUnits {
    centimeters = "centimeters",
    feetInches = "feetInches"
}

export enum EDistanceUnits {
    km = "Km",
    mi = "Mi"
}

export interface IDoubleRange {
    lower: number,
    upper: number
}

export interface IUserFilters {
    route?: ERoutes,
    gender?: EGender,
    sexualOrientation?: ESexualOrientation,
    kmRadius?: number,
    age?: IDoubleRange
    elo?: IDoubleRange
    filterBy?: EUsersFiltersAdmin
    lastDocId?: string
    lastDocIdEloFilter?: string
    lastDocIdAgeFilter?: string
}

export interface IFirestoreQueryUsers {
    gender?: EGender,
    sexualOrientation?: ESexualOrientation,
    minAge?: number,
    maxAge?: number,
    minElo?: number,
    maxElo?: number,
    myLatitude?: number,
    myLongitude?: number,
    kmRadius?: number,
    lastDocId?: string,
    eloFilters?: number[],
    ageFilters?: number[],
    isPremium?: boolean,
    route?: ERoutes
}

export interface IUserEvents {
    userUpdatedTimestamp: number;
    matchUpdated: string;
    matchUpdatedTimestamp: number;
    likeUpdated: string;
    likeUpdatedTimestamp: number;
}

