import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {onDarkModeChange, translateGender} from "@helpers/misc";
import {Preferences} from "@capacitor/preferences";
import {ELang, EPlatform, STORAGE_DARK_MODE, STORAGE_FILTERS} from "@models/general";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {Subject} from "rxjs";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {EDistanceUnits, EGender, EHeightUnits, IUser} from "@models/user";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Capacitor} from "@capacitor/core";
import {FiltersService} from "@services/filters.service";
import {EloService} from "@services/elo-service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {

    STORAGE_FILTERS = STORAGE_FILTERS;
    EDistanceUnits = EDistanceUnits;
    EHeightUnits = EHeightUnits;
    EPlatform = EPlatform;
    platform = EPlatform.ios;
    EGender = EGender;
    selectedGender = EGender.WOMAN;
    darkMode = false;
    visible = false;
    eloVisible = false;
    rankVisible = false;
    distanceVisible = false;
    currentUser?: IUser;
    sexualOrientationVisible = false;
    ageVisible = false;
    heightVisible = false;
    matchNotification = false;
    likeNotification = false;
    messageNotification = false;
    eloNotification = false;
    currentLang = ELang.en;
    selectedDistanceUnit = EDistanceUnits.km;
    selectedHeightUnit = EHeightUnits.centimeters;
    private subscription = new Subject<void>();

    constructor(
        private router: Router,
        private modalController: ModalController,
        private translate: TranslateService,
        private eloService: EloService,
        private filtersService: FiltersService,
        private usersService: UsersService,
        private loadingToastAlertProvider: LoadingToastAlertProvider) {
    }

    async ngOnInit() {
        this.platform = Capacitor.getPlatform() as EPlatform;
        this.currentLang = this.translate.currentLang as ELang;
        this.getCurrentUser();
        let {value} = await Preferences.get({key: STORAGE_DARK_MODE});
        this.darkMode = !!value;
        this.selectedHeightUnit = this.filtersService.allFilters.selectedHeightUnit;
        this.selectedDistanceUnit = this.filtersService.allFilters.selectedDistanceUnit;
        this.selectedGender = this.filtersService.allFilters.selectedGenderVote;
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    async logout() {
        this.closeModal();
        this.usersService.logout();
    }

    async genderChange() {
        await this.setStorageValue(STORAGE_FILTERS.STORAGE_GENDER_VOTE, this.selectedGender);
        this.eloService.setGenderChange(true);
    }

    getLangLabel(): string {
        switch (this.currentLang) {
            case ELang.fr:
                return 'Français';
            case ELang.en:
                return 'English';
        }
        return this.currentLang;
    }

    async setLanguage() {
        try {
            const languageCode = await this.loadingToastAlertProvider.presentAlertLanguage();
            if (languageCode) {
                this.currentLang = languageCode;
                this.translate.use(languageCode);
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    closeModal(event?: any) {
        event?.stopPropagation();
        this.modalController.dismiss();
    }

    async setStorageValue(key: STORAGE_FILTERS, value: any) {
        this.filtersService.setStorageValue(key, value);
    }

    darkModeChange() {
        onDarkModeChange(this.darkMode);
    }

    translateGenderCall(gender?: EGender): string {
        return translateGender(gender);
    }

    async updateUserSettingsCall() {
        let payload: IUser = {
            visible: this.visible,
            eloVisible: this.eloVisible
        } as IUser;
        if (this.currentUser?.isPremium) {
            payload = {
                ...payload,
                visible: this.visible,
                rankVisible: this.rankVisible,
                ageVisible: this.ageVisible,
                heightVisible: this.heightVisible,
                distanceVisible: this.distanceVisible,
                sexualOrientationVisible: this.sexualOrientationVisible,
                matchNotification: this.matchNotification,
                likeNotification: this.likeNotification,
                messageNotification: this.messageNotification,
                eloNotification: this.eloNotification,
            } as IUser;
        }
        try {
            await this.usersService.updateUserSettingsCall(payload);
        } catch (error: any) {
            console.error("updateUserSettingsCall", error);
            this.loadingToastAlertProvider.displayError(error);
        }
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
            this.visible = !!data?.visible;
            this.eloVisible = !!data?.eloVisible;
            this.rankVisible = !!data?.rankVisible;
            this.distanceVisible = !!data?.distanceVisible;
            this.sexualOrientationVisible = !!data?.sexualOrientationVisible;
            this.ageVisible = !!data?.ageVisible;
            this.heightVisible = !!data?.heightVisible;
            this.eloNotification = !!data?.eloNotification;
            this.matchNotification = !!data?.matchNotification;
            this.likeNotification = !!data?.likeNotification;
            this.messageNotification = !!data?.messageNotification;
        });
    }

}
