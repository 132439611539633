import {ERole} from "./general";
import {EGender, ESexualOrientation} from "./user";

export enum EUsersFiltersAdmin {
    reported = "reported",
    banned = "banned",
    all = "all",
}

export enum EUpdateUserAdmin {
    ban = "ban",
    unban = "unban",
    resetCount = "resetCount",
    gender = "gender",
    sexualOrientation = "sexualOrientation",
}

export interface IAdminUserPayload {
    roles: ERole[],
    documentId?: string
}

export interface IAdminGenderOrientation {
    id: EGender | ESexualOrientation,
    label: string,
    selected: boolean
}

export interface IUpdateAdminUser {
    action: EUpdateUserAdmin,
    gender?: EGender,
    sexualOrientation?: ESexualOrientation
    userId: string
}
