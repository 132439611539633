import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp, messaging} from "@app/app.module";
import {ERole, ERoutes, REGION} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {BehaviorSubject} from "rxjs";
import {IUser, IUserCreationPayload, IUserHistory} from "@models/user";
import {encryptChunk} from "@helpers/forge";
import {Router} from "@angular/router";
import {getAuth, getIdTokenResult} from "firebase/auth";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {MatchesService} from "@services/matches.service";
import {deleteToken, getMessaging} from "firebase/messaging";

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    notificationToken = '';
    // use on creation to don't display popup after account creation
    isAccountCreation = false;
    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);

    private user = new BehaviorSubject<IUser | undefined>(undefined);

    constructor(
        private matchesService: MatchesService,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private router: Router) {

    }

    getUser() {
        return this.user.asObservable();
    }

    setUser(user?: IUser) {
        this.user.next(user);
    }

    async onLogin(data: IUserHistory) {
        const callRequest = httpsCallable(this.functions, 'users-onLogin');
        const payloadString = JSON.stringify(data);
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    async updateUserCall(data: IUser) {
        const callRequest = httpsCallable(this.functions, 'users-updateUserCall');
        const payloadString = JSON.stringify(data);
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    async updateUserTokenCall(token: string) {
        try {
            const callRequest = httpsCallable(this.functions, 'users-updateUserTokenCall');
            await callRequest({token});
        } catch (e) {
            console.error(e);
        }
    }

    async removeUserTokenCall(token: string) {
        try {
            const callRequest = httpsCallable(this.functions, 'users-removeUserTokenCall');
            await callRequest({token});
            console.log(messaging);
            const m = getMessaging(firebaseApp);
            await deleteToken(m);
        } catch (e) {
            console.error(e);
        }
    }

    async deleteUserCall(documentId?: string) {
        const callRequest = httpsCallable(this.functions, 'users-deleteUserCall');
        return callRequest({documentId});
    }

    async getUserByIdCall(documentId: string) {
        const callRequest = httpsCallable(this.functions, 'users-getUserByIdCall');
        const payloadString = JSON.stringify({documentId});
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    saveInfosCall(infos: IUserCreationPayload): any {
        const callRequest = httpsCallable(this.functions, 'users-saveInfosCall');
        // const payloadString = JSON.stringify(infos);
        // const encryptedPayload = encryptChunk(payloadString);
        return callRequest(infos);
    }

    reportUserByIdCall(documentId: string): any {
        const callRequest = httpsCallable(this.functions, 'users-reportUserByIdCall');
        return callRequest({documentId});
    }

    updateUserSettingsCall(data: IUser) {
        const callRequest = httpsCallable(this.functions, 'users-updateUserSettingsCall');
        return callRequest(data);
    }

    updateUserInfosCall(data: IUser) {
        const callRequest = httpsCallable(this.functions, 'users-updateUserInfosCall');
        // const payloadString = JSON.stringify(data);
        // const encryptedPayload = encryptChunk(payloadString);
        return callRequest(data);
    }

    async logout() {
        await this.loadingToastAlertProvider.presentLoadingWithOptions("logout...");
        if (this.notificationToken) {
            await this.removeUserTokenCall(this.notificationToken);
        }
        try {
            await getAuth().signOut();
            this.router.navigate([ERoutes.LOGIN], {replaceUrl: true});
            this.matchesService.setAllMatches([]);
        } catch (err) {
            console.error(err);
            this.loadingToastAlertProvider.displayError(err);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async getUserCall() {
        const callRequest = httpsCallable(this.functions, 'users-getUserCall');
        try {
            const res = await callRequest();
            console.log(res);
            const user = res?.data as IUser;
            const matchsIds = user?.matchsIds || [];
            this.matchesService.setAllMatchsUsersIds(matchsIds);
            let roles = await this.checkAdminRole();
            user.roles = roles;
            this.setUser(user);
        } catch (e: any) {
            console.error(e);
            this.setUser();
            this.logout();
        }

    }

    private async checkAdminRole(): Promise<ERole[]> {
        const auth = getAuth();
        const currentUser = auth?.currentUser;
        let roles: ERole[] = [];
        if (currentUser) {
            try {
                const idTokenResult = await getIdTokenResult(currentUser) as any;
                roles = idTokenResult?.claims?.roles || [];
            } catch (e: any) {
                console.error(e);
            }
        }

        return roles;
    }

}
