<ion-content class="ion-padding">

    <div class="top-container-title">
        <div class="content-title">
            {{"experiencePWAShort" | translate}}
        </div>
        <div class="top-actions">
            <div (click)="close()" class="rounded-icon">
                <ion-icon color="myprimary" name="close"></ion-icon>
            </div>
        </div>
    </div>

    <div *ngIf="isInit" class="description-container ion-margin-top">
        <div class="message ion-text-center">
            {{"pwaDescription" | translate}}
        </div>
        <ion-button (click)="startTutorial()"
                    class="ion-margin-top"
                    color="myprimary"
                    expand="full"
                    mode="ios"
                    shape="round">
            {{"continue" | translate}}
        </ion-button>
    </div>

    <ng-container *ngIf="!isInit">
        <swiper-container #swiper
                          [loop]="false"
                          [modules]="swiperModules"
                          [slidesPerView]="1" effect="coverflow"
                          pagination="true">
            <swiper-slide *ngFor="let t of tutorial">
                <div class="item">
                    <div [ngStyle]="{ 'background-image': 'url(' +t.img+ ')'}" class="image">
                    </div>

                    <div *ngIf="t.label" class="label">
                        {{t.label | translate}}
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </ng-container>


</ion-content>
