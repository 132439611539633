<ion-content [fullscreen]="true" class="ion-padding">
    <div class="container">

        <div class="title-container">
            <div class="title">
                {{"youGotAMatch" | translate}}
            </div>
            <div class="description">
                {{"matchDescription" | translate:
                {name: getValue(EMatchKey.firstName, currentUser?.documentId)} }}
            </div>
        </div>

        <div class="pictures-wrapper">
            <div class="picture-container from-left">
                <div [ngStyle]="{ 'background': 'url(' + getValue(EMatchKey.picture, currentUser?.documentId) + ') center center no-repeat'}"
                     class="picture">
                </div>
            </div>
            <div class="picture-container from-right">
                <div [ngStyle]="{ 'background': 'url(' + currentUserPicture + ') center center no-repeat'}"
                     class="picture">
                </div>
            </div>
        </div>

        <div class="buttons-container">
            <ion-button (click)="sendMessage()"
                        class="from-left"
                        color="myprimary"
                        mode="ios"
                        shape="round">
                {{"sendMessage" | translate}}
            </ion-button>
            <ion-button (click)="close()"
                        class="from-right"
                        color="danger"
                        fill="outline"
                        mode="ios"
                        shape="round">
                {{"illSendLater" | translate}}
            </ion-button>
        </div>
    </div>

</ion-content>
