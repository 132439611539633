import {Injectable} from "@angular/core";
import {REGION} from '@models/general';
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {FirebaseStorage, getStorage} from 'firebase/storage';
import {Functions, getFunctions, httpsCallable} from "firebase/functions";

@Injectable({
    providedIn: 'root'
})
export class PicturesService {

    storageRef: FirebaseStorage = getStorage();
    db: Firestore = getFirestore(firebaseApp);

    functions: Functions = getFunctions(firebaseApp, REGION);

    constructor() {

    }


    /**************************************
     * USER
     ***************************************/

    /**************************************
     * CLOUD PICTURE INFO
     ***************************************/

    uploadImageCall(base64String: string) {
        const callRequest = httpsCallable(this.functions, 'image-uploadImageCall');
        return callRequest({base64String});
    }

    deleteImageCall(url: string) {
        const callRequest = httpsCallable(this.functions, 'image-deleteImageCall');
        return callRequest({url});
    }


    /**************************************
     * STORAGE
     ***************************************/

    // async uploadPicture(base64Encoded: string) {
    //     const auth = getAuth();
    //     const user = auth?.currentUser;
    //     if (!user) {
    //         return;
    //     }
    //     const uid = guid();
    //     let folder = 'users'
    //
    //     const storageRef = ref(this.storageRef, `${folder}/${user.uid}/${uid}`);
    //
    //     return uploadString(storageRef, base64Encoded, 'base64', {
    //         contentType: 'image/png',
    //     })
    // }
    //
    // getImgUrl(reference: StorageReference): Promise<any> {
    //     return getDownloadURL(reference);
    // }

}
