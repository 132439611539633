export enum EHistoryType {
    sent = 'sent',
    received = 'received'
}

export enum EVoteType {
    win = 'win',
    lose = 'lose',
    by = 'by'
}

export interface IHistoryPayload {
    historyType: EHistoryType;
    lastDocId?: string;
}

