import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {FIREBASE_CONFIG} from "@app/firebase.credentials";
import {initializeApp} from 'firebase/app';
import {CustomUnitPipe} from "@shared/pipes/custom-currency.pipe";
import {ServiceWorkerModule} from '@angular/service-worker';
import {SharedModule} from "@shared/shared.module";
import {getMessaging} from "firebase/messaging";

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(firebaseApp);


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({innerHTMLTemplatesEnabled: true}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        CustomUnitPipe],
    bootstrap: [AppComponent],
})
export class AppModule {
}
