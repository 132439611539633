export enum ENotificationType {
    elo = "elo",
    match = "match",
    like = "like",
    message = "message",
}

export interface INotificationsParams {
    elo?: number;
    name?: string;
    message?: string;
}
