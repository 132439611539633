<div class="images-container">
    <div (click)="cameraOrLibraryPopup(i)"
         *ngFor="let m of images; let i = index"
         [class.custom-border]="!m"
         class="image">

        <div *ngIf="m; else noImage"
             [ngStyle]="{ 'background': 'url(' +getStorageUrl(m) + ') center center no-repeat'}"
             class="picture">
            <ion-icon (click)="deletePicture($event, i, m)" name="close-circle"></ion-icon>
        </div>

        <ng-template #noImage>
            <div class="no-picture">
                <ion-icon name="add-circle"></ion-icon>
            </div>
        </ng-template>
    </div>

</div>
