import {DeviceInfo} from "@capacitor/device/dist/esm/definitions";

export const REGION = 'us-central1';
export const BUCKET_NAME = 'elospark-fafd7.appspot.com';
export const STORAGE_BASE = 'https://storage.googleapis.com/elospark-fafd7.appspot.com/users/';
export const WEBSITE_URL = 'https://demo.elospark.io'; // TODO
export const PUBLIC_VAPID_KEY = "BDlKETrTo5BfBjt05knajoIRRtEK63MIEGZINJ3I9582mftiVWuyL8uRfnZ-lttCl8M_WLod_XUSQLkaZDl9_XM";
export const INIT_ELO = 1200;
export const PUSH_IMAGE_URL = "https://firebasestorage.googleapis.com/v0/b/elospark-fafd7.appspot.com/o/admin%2Fapple-touch-icon.png?alt=media&token=ec4b4130-9415-429d-bbec-56ce1681f0ed";
export const SPARK_MATCH_PERCENT = 0.2; // TODO
export const STANDARD_DEVIATION = 200;
export const LIKES_LIMIT = 10;
export const HISTORY_LIMIT = 10;
export const MATCHES_LIMIT = 100;
export const WAITING_MATCHES_LIMIT = 3;
export const USERS_ADMIN_LIMIT = 50;
export const MESSAGE_LIMIT = 100;
export const GAME_LIMIT = 20;
export const VOTES_RECEIVED_LIMIT = 20;
export const GAME_BOOST_LIMIT = 20;
export const GAME_GEO_LIMIT = 10;
export const RANK_LIMIT = 20;
export const SPARKLES_LIMIT = 20;
export const RANK_GEO_LIMIT = 50;
export const MIN_AGE = 18;
export const MAX_AGE = 99;
export const AGE_STEP = 1;
export const MIN_DISTANCE = 1;
export const MAX_DISTANCE = 1000;
export const MAX_HEIGHT_CM = 250;
export const MIN_HEIGHT_CM = 100;
export const NAME_MAX_LENGTH = 20;
export const BIO_MAX_LENGTH = 500;
export const DISTANCE_STEP = 10;
export const MAX_ELO = 3000;
export const ELO_STEP = 100;
export const MAX_DISJUNCTIONS = 30;
export const MAX_IN_QUERY = 30;
export const MESSAGE_MAX_LENGTH = 300;
export const INSTAGRAM_MAX_LENGTH = 40;
export const TIKTOK_MAX_LENGTH = 40;
export const STORAGE_FIRST_LOGIN = 'eloSparkFirstLogin';
export const STORAGE_DARK_MODE = 'eloSparkDarkMode';
export const STORAGE_VOTE_TUTORIAL = 'eloSparkVoteTutorial';
export const STORAGE_SPARK_TUTORIAL = 'eloSparkSparkProfilesTutorial';
export const STORAGE_LIKE_TUTORIAL = 'eloSparkLikeTutorial';
export const STORAGE_LANG = 'eloSparkLang';
export const STORAGE_EMAIL = 'eloSparkEmail';
export const STORAGE_REMEMBER_ME = 'eloSparkRememberMe';
export const STORAGE_AUTO_SWIPE = 'eloSparkAutoSwipe';
export const STORAGE_DISCLAIMER = 'eloSparkDisclaimer';
export const STORAGE_LIKES_SENT = 'eloSparkLikesSentIds';
export const EMULATORS_PORT = 8001;

export enum STORAGE_FILTERS {
    STORAGE_KM_RADIUS_VOTE = 'kmRadiusValueVote',
    STORAGE_GENDER_VOTE = 'selectedGenderVote',
    STORAGE_GENDER_ADMIN = 'selectedGenderAdmin',
    STORAGE_FILTER_BY_ADMIN = 'selectedFilterByAdmin',
    STORAGE_AGE_RANGE_RANKING = 'ageRangeRanking',
    STORAGE_ELO_RANGE_RANKING = 'eloRangeRanking',
    STORAGE_KM_RADIUS_RANKING = 'kmRadiusValueRanking',
    STORAGE_SEXUAL_ORIENTATION_ADMIN = 'selectedSexualOrientationAdmin',
    STORAGE_SEXUAL_ORIENTATION_RANKING = 'selectedSexualOrientationRanking',
    STORAGE_AGE_RANGE_SPARKLES = 'ageRangeSparkles',
    STORAGE_KM_RADIUS_SPARKLES = 'kmRadiusValueSparkles',
    STORAGE_GENDER_SPARKLES = 'selectedGenderSparkles',
    STORAGE_GENDER_RANKING = 'selectedGenderRanking',
    STORAGE_SEXUAL_ORIENTATION_SPARKLES = 'selectedSexualOrientationSparkles',
    STORAGE_DISTANCE_UNITS = 'selectedDistanceUnit',
    STORAGE_HEIGHT_UNITS = 'selectedHeightUnit'
}

export interface ILabelValue {
    label: string;
    value: number;
}

export interface IDevice extends DeviceInfo {
    timestamp: number;
    userId: string;
}

export enum TOAST_POSITION {
    TOP = "top",
    MIDDLE = "middle",
    BOTTOM = "bottom"
}

export enum EPlatform {
    web = "web",
    ios = "ios",
    android = "android"
}

export enum ELang {
    fr = "fr",
    en = "en",
    id = "id",
    de = "de",
    es = "es",
    it = "it",
    pt = "pt",
    ru = "ru",
    vi = "vi",
    tr = "tr",
    ja = "ja",
    ko = "ko",
    zh = "zh",
    hi = "hi",
    ar = "ar",
    th = "th",
}

export enum ERoutes {
    LOGIN = "login",
    SPARK = "spark",
    SPARK0 = "spark0",
    ELO = "elo",
    LIKES = "likes",
    MATCHES = "matches",
    USER = "user",
    ADMIN = "admin853",
    ADMIN_REPORTS = "reports",
    ADMIN_CHATS = "chats",
    PROFILE = "profile",
    INFOS = "infos",
    SIGNIN = "signin",
    CREATION = "creation"
}

export enum ECollections {
    users = "users",
    usersEvents = "usersEvents",
    history = "history",
    matches = "matches",
    messages = "messages",
    stats = "stats",
    stripeWebhook = "stripeWebhook",
    settings = "settings",
    votes = "votes"
}

export enum EDocuments {
    stats = "stats",
    settings = "settings"
}

export enum EDarkMode {
    dark = "dark",
    light = "light"
}

export enum ERole {
    ADMIN = "ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN"
}

export enum FUNCTIONS_ERROR_CODE {
    OK = 'ok',
    CANCELLED = 'cancelled',
    UNKNOW = 'unknown',
    INVALID_ARGUMENT = 'invalid-argument',
    DEADLINE_EXCEEDED = 'deadline-exceeded',
    NOT_FOUND = 'not-found',
    ALREADY_EXISTS = 'already-exists',
    PERMISSION_DENIED = 'permission-denied',
    RESOURCES_EXHAUSTED = 'resource-exhausted',
    FAILED_PRECONDITION = 'failed-precondition',
    ABORTED = 'aborted',
    OUT_OF_RANGE = 'out-of-range',
    UNIMPLEMENTED = 'unimplemented',
    INTERNAL = 'internal',
    UNAVALAIBLE = 'unavailable',
    DATA_LOSS = 'data-loss',
    UNAUTHENTICATED = 'unauthenticated',
}

export interface IVisionSafeSearch {
    adult: ESafeSearch
    medical: ESafeSearch
    racy: ESafeSearch
    spoof: ESafeSearch
    violence: ESafeSearch
}

export enum ESafeSearch {
    "VERY_UNLIKELY" = "VERY_UNLIKELY",// It is very unlikely that the image contains adult content.
    "UNLIKELY" = "UNLIKELY",// It is unlikely that the image contains adult content.
    "POSSIBLE" = "POSSIBLE",//  It is possible that the image contains adult content.
    "LIKELY" = "LIKELY",// It is likely that the image contains adult content.
    "VERY_LIKELY" = "VERY_LIKELY",// It is very likely that the image contains adult content.
}

