<ion-header>
    <div class="top-container-title">
        <div class="content-title">
            <ion-icon color="myprimary"
                      name="diamond"></ion-icon>
            {{"premium" | translate}}
        </div>
        <div class="top-actions">
            <div (click)="close()" class="rounded-icon">
                <ion-icon color="myprimary" name="close"></ion-icon>
            </div>
        </div>
    </div>
</ion-header>
<ion-content class="ion-padding">


    <div class="items-container">

        <ng-container *ngIf="currentUser?.isPremium">
            <div class="already-premium">
                {{"alreadyPremium" | translate}}
            </div>
            <div *ngIf="!currentUser?.stripeSubscriptionId" class="premium-date">
                {{"validUntil" | translate:{date: convertTimestampToDate(currentUser?.stripeSubscriptionEnd)} }}
            </div>
        </ng-container>

        <div *ngFor="let p of premiumPackage" class="item">
            <div *ngIf="p.title" class="title">
                {{p.title | translate}}
            </div>
            <div *ngFor="let b of p.bonus" class="label-sub">
                <div class="label">
                    <ion-icon color="myprimary" name="checkmark" size="small"></ion-icon>
                    {{b.label | translate}}
                </div>
                <div class="sub">
                    {{b.sub | translate}}
                </div>
            </div>

        </div>

        <div *ngIf="!currentUser?.isPremium || !currentUser?.stripeSubscriptionId" class="price">
            {{"premiumPriceMonthly" | translate:{price: PREMIUM_PRICE_USD | customUnit:'$'} }}
        </div>

    </div>

    <ion-button (click)="submit()"
                *ngIf="!currentUser?.isPremium || !currentUser?.stripeSubscriptionId"
                class="ion-margin-top"
                color="myprimary"
                expand="full"
                mode="ios"
                shape="round">
        {{"continue" | translate}}
    </ion-button>

    <ion-button (click)="cancelSubscription()"
                *ngIf="currentUser?.stripeSubscriptionId"
                class="ion-margin-top cancel"
                color="danger"
                expand="full"
                fill="outline"
                mode="ios"
                shape="round">
        {{"cancelSubscription" | translate}}
    </ion-button>


</ion-content>
