<!--<span class="stamp is-approved">Approved</span>-->

<div #container
     *ngIf="user"
     [class.behind]="isBehind" class="container container-background">

    <div #swiperOverlay *ngIf="!isUserDetails" class="overlay-container">
        <div (click)="prevSlide($event)"
             [class.tutorial]="isTutorial"
             [class.w-arrow]="user?.images && user.images.length > 1"
             class="overlay prev"></div>
        <!--        <div (click)="getUserDetails(user)"-->
        <!--             *ngIf="!isUserDetails"-->
        <!--             [class.tutorial]="isTutorial"-->
        <!--             [style.cursor]="isUserDetails ? 'auto' : 'pointer'"-->
        <!--             class="overlay middle"></div>-->
        <div (click)="nextSlide($event)"
             [class.e-arrow]="user?.images && user.images.length > 1"
             [class.tutorial]="isTutorial"
             class="overlay next"></div>
    </div>


    <div (click)="getUserDetails(user)"
         *ngIf="!isUserDetails"
         class="rounded-action instant-match">
        <ion-icon name="eye"></ion-icon>
    </div>
    <!--    <div (click)="instantMatch($event)"-->
    <!--         *ngIf="!isUserDetails && !isAMatch()"-->
    <!--         class="rounded-action instant-match">-->
    <!--        <ion-icon color="primary" name="flash"></ion-icon>-->
    <!--    </div>-->

    <swiper-container #swiper
                      [loop]="true"
                      [pagination]="swiperPagination">
        <swiper-slide *ngFor="let p of user.images">
            <div *ngIf="isUserDetails && user.images.length > 1" class="overlay-container">
                <div (click)="prevSlide($event)"
                     [class.tutorial]="isTutorial"
                     [class.w-arrow]="user?.images && user.images.length > 1"
                     class="overlay prev"></div>
                <div (click)="nextSlide($event)"
                     [class.e-arrow]="user?.images && user.images.length > 1"
                     [class.tutorial]="isTutorial"
                     class="overlay next"></div>
            </div>
            <div [class.cover-bg]="isUserDetails"
                 [ngStyle]="{ 'background-image': 'url(' + p + ')'}"
                 class="bg-image">
            </div>
            <div [ngStyle]="{ 'background': 'url(' + p+ ') center center no-repeat'}"
                 class="image-layer">
            </div>
            <div #pointsStamp
                 [class.is-negative]="newPoints < 0"
                 class="new-points">
                {{ newPoints >= 0 ? '+' : '' }}{{newPoints}}
                <ion-icon color="myprimary" name="flame" size="small"></ion-icon>
            </div>
        </swiper-slide>
    </swiper-container>

    <ng-container *ngIf="!isUserDetails">
        <ion-icon *ngIf="user.images.length > 1"
                  class="swiper-button swiper-button-prev"
                  name="chevron-back-outline"></ion-icon>
        <ion-icon *ngIf="user.images.length > 1"
                  class="swiper-button swiper-button-next" name="chevron-forward-outline"></ion-icon>
    </ng-container>

    <div *ngIf="displayBubble" class="bubbling-heart">
        <div>
            <ion-icon name="heart"></ion-icon>
        </div>
        <div>
            <ion-icon name="heart"></ion-icon>
        </div>
        <div>
            <ion-icon name="heart"></ion-icon>
        </div>
        <div>
            <ion-icon name="heart"></ion-icon>
        </div>
    </div>

    <div class="dark-background">
        <div class="child-icon">
            <div (click)="vote(true)"
                 *ngIf="!isUserDetails"
                 class="rounded-action unlike">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>

        <div *ngIf="!isUserDetails"
             class="center-container">
            <div class="name-age">
                <ion-icon *ngIf="user?.isPremium"
                          color="myprimary"
                          name="diamond"
                          size="default" style="margin-right: 5px"></ion-icon>
                <ng-container *ngIf="!user.age">
                    {{user.firstName}}
                </ng-container>
                <ng-container *ngIf="user.age">
                    {{user.firstName}}, {{ user.age }}
                </ng-container>
            </div>

        </div>

        <div class=" child-icon">
            <div (click)="instantMatch($event)"
                 *ngIf="displayInstantMatch && !isAMatch()"
                 class="rounded-action"
                 style="margin-right: 10px">
                <ion-icon color="myprimary" name="magnet"></ion-icon>
            </div>
            <div (click)="likeUser(false, $event); vote()"
                 *ngIf="displayLike && !isALike() && !isAMatch()"
                 class="rounded-action">
                <ion-icon color="myprimary" name="heart"></ion-icon>
            </div>
        </div>


    </div>
</div>
