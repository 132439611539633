<ion-header>
    <ion-toolbar color="light">
        <ion-segment (ionChange)="typeChange()" [(ngModel)]="historyType"
                     [scrollable]="true"
                     color="myprimary"
                     mode="ios">
            <ion-segment-button [value]="EHistoryType.received">
                {{"votesReceived" | translate}}
            </ion-segment-button>
            <ion-segment-button [value]="EHistoryType.sent">
                {{"votesSent" | translate}}
            </ion-segment-button>
        </ion-segment>
        <ion-buttons slot="end">
            <div (click)="closeModal()" class="rounded-icon prim">
                <ion-icon name="close"></ion-icon>
            </div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="">
    <ion-refresher (ionRefresh)="refreshContent($event)"
                   *ngIf="historyType === EHistoryType.sent || currentUser?.isPremium"
                   [disabled]="isLoading" slot="fixed">
        <ion-refresher-content
                [pullingText]="'pullToRefresh' | translate"
                [refreshingText]="'refreshing' | translate"
                pullingIcon="chevron-down-circle-outline"
                refreshingSpinner="circles"></ion-refresher-content>
    </ion-refresher>

    <ng-container *ngIf="historyType === EHistoryType.received">
        <div *ngIf="!currentUser?.isPremium"
             class="enable-premium ion-text-center ion-margin-top error">
            {{"onlyPremiumCanSeeVotesReceived" | translate}}
            <ion-button (click)="openPurchasePremium()"
                        class="ion-margin-top"
                        color="myprimary"
                        mode="ios"
                        shape="round">
                {{"bePremium" | translate}}
            </ion-button>
        </div>
    </ng-container>


    <ion-grid *ngIf="isLoading" class="ion-no-padding" style="height: 100%;">
        <ion-row class="ion-align-items-center ion-justify-content-center" style="height: 100%;">
            <ion-col class="ion-text-center">
                <ion-spinner name="lines"></ion-spinner>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ng-container *ngIf="!isLoading">


        <div *ngIf="currentUser?.isPremium && historyType === EHistoryType.received && !votesDisplayed?.length"
             class="no-data">
            {{"noVotesReceived" | translate}}

            <ion-button (click)="openPurchaseConsumable(EPremiumType.boost)"
                        class="ion-margin-top"
                        color="myprimary"
                        mode="ios"
                        shape="round">
                {{"getMoreBoosts" | translate}}
            </ion-button>
        </div>

        <!--        <div *ngIf="votesDisplayed?.length" class="pull-refresh">-->
        <!--            {{"pullToRefresh" | translate}}-->
        <!--            <ion-icon name="arrow-down-outline" size="small"></ion-icon>-->
        <!--        </div>-->

        <div *ngIf="historyType===EHistoryType.sent && !votesDisplayed?.length" class="no-data">
            {{"noVotesSent" | translate}}

            <ion-button (click)="goToVote()"
                        class="ion-margin-top"
                        color="myprimary"
                        mode="ios"
                        shape="round">
                {{"goToVotePage" | translate}}
            </ion-button>
        </div>
    </ng-container>


    <div *ngIf="votesDisplayed?.length" class="waiting-matches-container">
        <div *ngFor="let m of votesDisplayed; let i = index"
             class="match-wrapper">
            <div class="winner-loser">
                <div (click)="getUserDetails(m, EVoteType.win)"
                     [ngStyle]="{ 'background': 'url(' + m.winnerPicture + ') center center no-repeat'}"
                     class="picture win">
                    <div class="name-age">
                        <div class="name">
                            {{m.winnerName}}
                        </div>
                        <div *ngIf="m.newWinnerElo" class="elo">
                            <ion-icon color="myprimary" name="flame" size="small"></ion-icon>
                            {{ m.newWinnerElo }}
                        </div>
                    </div>
                </div>
                <div (click)="getUserDetails(m, EVoteType.lose)"
                     [ngStyle]="{ 'background': 'url(' + m.loserPicture + ') center center no-repeat'}"
                     class="picture loser">
                    <div class="name-age">
                        <div class="name">
                            {{m.loserName}}
                        </div>
                        <div *ngIf="m.newLoserElo" class="elo">
                            <ion-icon color="myprimary" name="flame" size="small"></ion-icon>
                            {{ m.newLoserElo }}
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="getUserDetails(m, EVoteType.by)"
                 *ngIf="historyType===EHistoryType.received" class="by">
                {{"by" | translate}}
                <div [ngStyle]="{ 'background': 'url(' + m.userPicture + ') center center no-repeat'}"
                     class="picture"
                     style="margin-left: 10px">
                </div>
            </div>

        </div>
    </div>

    <ion-infinite-scroll (ionInfinite)="getHistoryByUserIdCall(historyType,$event)"
                         *ngIf="displayInfiniteScroll()">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

</ion-content>
