import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {EBonusCount, IPremium, PREMIUM_PRICE_USD} from "@models/premium";
import {TranslateService} from "@ngx-translate/core";
import {INIT_ELO} from "@models/general";
import {PremiumService} from "@services/premium.service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {IUser} from "@models/user";
import {Subject} from "rxjs";
import {EStripeProduct} from "@models/stripe";
import {getAuth} from "firebase/auth";
import {getStripeUrl} from "@helpers/stripe";
import {StripeService} from "@services/stripe-service";
import {environment} from "@environments/environment";

@Component({
    selector: 'app-purchase-premium',
    templateUrl: './purchase-premium.component.html',
    styleUrls: ['./purchase-premium.component.scss'],
})
export class PurchasePremiumComponent implements OnInit, OnDestroy {

    isProd = environment.production;
    PREMIUM_PRICE_USD = PREMIUM_PRICE_USD;
    premiumPackage: IPremium[] = [];
    currentUser?: IUser;
    private subscription = new Subject<void>();

    constructor(
        private premiumService: PremiumService,
        private modalController: ModalController,
        private stripeService: StripeService,
        private usersService: UsersService,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.getCurrentUser();
        // TODO add a check and if android or ios remove like and matchs
        this.premiumPackage = [{
            title: "premiumGetBonus",
            bonus: [{
                label: this.translate.instant('premiumInstantsMatchs', {count: EBonusCount.INSTANT_MATCH_LEVEL2}),
                sub: ''
            }, {
                label: this.translate.instant('premiumBoostsProfile', {count: EBonusCount.BOOST_LEVEL1}),
                sub: 'premiumBoostsProfileSub'
            }, {
                label: 'premiumUnlimitedLikes',
                sub: ''
            }, {
                label: 'premiumPriorityLikes',
                sub: 'premiumPriorityLikesSub'
            }]
        }, {
            title: "premiumImproveExperience",
            bonus: [{
                label: 'premiumBadge',
                sub: ''
            }, {
                label: 'premiumNoAds',
                sub: ''
            }]
        }, {
            title: "premiumFeatures",
            bonus: [{
                label: 'premiumLikesVisible',
                sub: ''
            }, {
                label: 'premiumVotesHistory',
                sub: 'premiumVotesHistorySub'
            }, {
                label: 'premiumVotesSent',
                sub: 'premiumVotesSentSub'
            }, {
                label: 'premiumEloReset',
                sub: this.translate.instant('premiumEloResetSub', {count: INIT_ELO})
            }]
        }, {
            title: "premiumVisibility",
            bonus: [{
                label: 'premiumControlPeopleSee',
                sub: ''
            }, {
                label: 'premiumSeeRanking',
                sub: ''
            }, {
                label: 'premiumSeeEloScore',
                sub: ''
            }]
        }]
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    close(data?: any) {
        this.modalController.dismiss(data);
    }

    async cancelSubscription() {
        if (!this.currentUser?.stripeSubscriptionId) {
            return;
        }

        const stripeSubscriptionEnd = this.currentUser?.stripeSubscriptionEnd || 0;
        const date = this.convertTimestampToDate(stripeSubscriptionEnd);
        try {
            const confirmMessage = this.translate.instant('sureCancelSubscription', {date});
            await this.loadingToastAlertProvider.confirmationPopup('cancelSubscription', confirmMessage);
            await this.loadingToastAlertProvider.presentLoadingWithOptions('cancellingSubscription');
            await this.stripeService.cancelSubscriptionCall();
            const message = this.translate.instant('successfullyCancelled', {date})
            await this.loadingToastAlertProvider.presentToastWithOptions(
                message, false)
        } catch (e: any) {
            this.loadingToastAlertProvider.displayError(e);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async submit() {

        let uid = getAuth()?.currentUser?.uid;
        if (!uid) {
            this.usersService.logout();
            return;
        }
        let product = this.isProd ? EStripeProduct.PREMIUM : EStripeProduct.PREMIUM_TEST;
        const url = getStripeUrl(product, uid, this.currentUser?.email);
        window.open(url, "_blank");
        this.close();
    }

    convertTimestampToDate(timestamp?: number): string {
        if (!timestamp) {
            return '';
        }
        // Create a new Date object using the timestamp (in milliseconds)
        const date = new Date(timestamp * 1000);

        // Extract the day, month, and year
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        // Format the date as DD/MM/YYYY
        return `${day}/${month}/${year}`;
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
        });
    }

}
