import {Component, Input, OnInit} from '@angular/core';
import {EStripeProduct} from "@models/stripe";
import {ModalController} from "@ionic/angular";
import {BOOSTS_COUNT, EBonusCount} from "@models/premium";

@Component({
    selector: 'app-stripe-modal',
    templateUrl: './stripe-modal.component.html',
    styleUrls: ['./stripe-modal.component.scss'],
})
export class StripeModalComponent implements OnInit {

    @Input()
    productId!: EStripeProduct;

    BOOSTS_COUNT = BOOSTS_COUNT;
    EBonusCount = EBonusCount;
    EStripeProduct = EStripeProduct;

    constructor(
        private modalController: ModalController) {
    }

    ngOnInit() {
        console.log(this.productId);
    }

    close(data?: any) {
        this.modalController.dismiss(data);
    }

    getBonusCount(): number {
        switch (this.productId) {
            case EStripeProduct.BOOST_LEVEL1:
            case EStripeProduct.BOOST_LEVEL1_TEST:
                return EBonusCount.BOOST_LEVEL1;
            case EStripeProduct.BOOST_LEVEL2:
            case EStripeProduct.BOOST_LEVEL2_TEST:
                return EBonusCount.BOOST_LEVEL2;
            case EStripeProduct.BOOST_LEVEL3:
            case EStripeProduct.BOOST_LEVEL3_TEST:
                return EBonusCount.BOOST_LEVEL3;
            case EStripeProduct.INSTANT_MATCH_LEVEL1:
            case EStripeProduct.INSTANT_MATCH_LEVEL1_TEST:
                return EBonusCount.INSTANT_MATCH_LEVEL1;
            case EStripeProduct.INSTANT_MATCH_LEVEL2:
            case EStripeProduct.INSTANT_MATCH_LEVEL2_TEST:
                return EBonusCount.INSTANT_MATCH_LEVEL2;
            case EStripeProduct.INSTANT_MATCH_LEVEL3:
            case EStripeProduct.INSTANT_MATCH_LEVEL3_TEST:
                return EBonusCount.INSTANT_MATCH_LEVEL3;
        }
        return 0;
    }

}
