import {Component, OnDestroy} from '@angular/core';

import {ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {
    ECollections,
    EDocuments,
    ELang,
    EMULATORS_PORT,
    EPlatform,
    ERoutes,
    REGION,
    STORAGE_DARK_MODE,
    STORAGE_LANG
} from '@models/general';
import {Subject} from 'rxjs';
import {doc, Firestore, getFirestore, onSnapshot, Unsubscribe} from "firebase/firestore";
import {firebaseApp} from "@app/app.module";
import {getAuth, onAuthStateChanged, sendEmailVerification, User} from 'firebase/auth';
import {Network} from "@capacitor/network";
import {Preferences} from "@capacitor/preferences";
import {Device} from "@capacitor/device";
import {environment} from "@environments/environment";
import {UsersService} from "@services/users-service";
import {Router} from "@angular/router";
import {IUser, IUserEvents, IUserHistory} from "@models/user";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {DeviceInfo} from "@capacitor/device/dist/esm/definitions";
import {register} from 'swiper/element/bundle';
import {MatchesService} from "@services/matches.service";
import {EloService} from "@services/elo-service";
import {takeUntil} from "rxjs/operators";
import {isTwitterProvider} from "@helpers/misc";
import {Capacitor} from "@capacitor/core";
import {getUserLocation} from "@shared/geolocation";
import {EStripeProduct} from "@models/stripe";
import {StripeModalComponent} from "@shared/components/stripe-modal/stripe-modal.component";
import {ModalService} from "@services/modal-service";
import {FirebaseMessagingService} from "@services/firebase-messaging-service";
import {ModalPWAComponent} from "@shared/components/modal-pwa/modal-pwa.component";
import {StripeService} from "@services/stripe-service";
import {ISettings} from "@models/settings";
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";

// need it to init swiper.js
register();

// TODO adsense
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {

    db!: Firestore;
    userEventsSnapshotListener!: Unsubscribe;
    settingsEventsSnapshotListener!: Unsubscribe;
    matchsEventsSnapshotListener!: Unsubscribe;
    authSubscription!: Unsubscribe;
    onLoginInit = false;
    dataInit = false;
    userEvents?: IUserEvents;
    currentPlatform = EPlatform.ios;
    env = environment;
    private subscription = new Subject<void>();

    constructor(
        private platform: Platform,
        private router: Router,
        private firebaseMessagingService: FirebaseMessagingService,
        private modalService: ModalService,
        private matchesService: MatchesService,
        private stripeService: StripeService,
        private modalController: ModalController,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private translate: TranslateService,
        private eloService: EloService,
        private matchesService1: MatchesService,
        private usersService: UsersService
    ) {
        this.initializeApp();
    }
    
    initializeApp() {
        // TODO
        if (environment.production) {
            console.log = () => {
            };
            console.error = () => {
            };
        }
        this.currentPlatform = Capacitor.getPlatform() as EPlatform;
        this.platform.ready().then(async () => {
            const currentURL = window.location.href;
            console.log(currentURL);
            if (currentURL?.includes('localhost')) {
                connectFunctionsEmulator(getFunctions(firebaseApp, REGION), "localhost", EMULATORS_PORT);
            }
            this.pressBackButton();
            this.db = getFirestore(firebaseApp);
            this.db = getFirestore(firebaseApp);
            this.getCurrentUser();
            await this.initTranslation();
            this.networkStatus();
            this.setDarkMode();
            const infos = await Device.getInfo();
            console.log(infos);
        });
    }

    ngOnDestroy() {
        Network.removeAllListeners();
        this.stopListeners();
        this.subscription.next();
        this.subscription.complete();
    }

    private async onLogin() {
        await this.loadingToastAlertProvider.presentLoadingWithOptions('initApp');
        try {
            let latitude = 0;
            let longitude = 0;
            try {
                const geo = await getUserLocation(this.translate);
                latitude = geo?.latitude || 0;
                longitude = geo?.longitude || 0;
            } catch (error: any) {
                console.error("onLogin", error)
            }
            const deviceId = (await Device.getId())?.identifier as string;
            const device = await Device.getInfo() as DeviceInfo;
            const payload: IUserHistory = {
                device,
                deviceId,
                lang: this.translate.currentLang as string,
                userAgent: navigator?.userAgent,
                latitude,
                longitude
            }
            await this.usersService.onLogin(payload);
        } catch (error: any) {
            console.error("onLogin", error)
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    private async initTranslation() {
        this.translate.setDefaultLang(ELang.en);

        let language = (await Device.getLanguageCode())?.value?.toLowerCase() || ELang.en;

        this.translate.onLangChange.subscribe(async (data: any) => {
            console.log(data);
            const value = data?.lang || ELang.en;
            await Preferences.set({
                key: STORAGE_LANG,
                value
            });
            this.usersService.updateUserCall({lang: value} as IUser)
        });

        let {value} = await Preferences.get({key: STORAGE_LANG});
        if (value) {
            this.translate.use(value);
        } else {
            this.translate.use(language);
        }

    }

    private stopListeners() {
        this.onLoginInit = false;
        if (this.userEventsSnapshotListener) {
            this.userEventsSnapshotListener();
            this.usersService.setUser();
        }
        if (this.settingsEventsSnapshotListener) {
            this.settingsEventsSnapshotListener();
        }
    }

    private async setDarkMode() {
        let {value} = await Preferences.get({key: STORAGE_DARK_MODE});
        if (value) {
            document.body.classList.toggle('dark', true);
        } else {
            document.body.classList.toggle('dark', false);
        }
    }

    private async initializeFirebase() {
        const auth = getAuth(firebaseApp);

        this.authSubscription = onAuthStateChanged(auth, async user => {
            console.log("onAuthStateChanged", user);
            this.eloService.uid = user?.uid || '';
            this.matchesService.uid = user?.uid || '';
            if (user?.uid) {
                if (isTwitterProvider(user?.providerData) || user?.emailVerified) {
                    if (!this.onLoginInit) {
                        this.onLoginInit = true;
                        this.onLogin();
                        this.firebaseMessagingService.getUserToken();
                        this.getUserEventsLive(user.uid);
                    }
                } else if (!isTwitterProvider(user?.providerData) && !user?.emailVerified
                    && !this.usersService.isAccountCreation) {
                    try {
                        await this.loadingToastAlertProvider
                            .resendVerificationLink('checkYourEmailHeader',
                                'mustValidateEmail', false);
                        this.sendVerificationEmail(user);
                    } catch (e: any) {
                        this.usersService.logout();
                    }

                } else {
                    this.usersService.logout();
                }
            } else {
                this.stopListeners();
            }
        });
    }

    private async getUserEventsLive(uid: string) {
        const referralQuery = doc(this.db, ECollections.usersEvents, uid);

        this.userEventsSnapshotListener = onSnapshot(referralQuery, async (d) => {
                console.log(d);
                if (d?.exists()) {
                    const userEvents = d.data() as IUserEvents;
                    console.log(userEvents);
                    if (userEvents?.userUpdatedTimestamp &&
                        this.userEvents?.userUpdatedTimestamp !== userEvents?.userUpdatedTimestamp) {
                        this.usersService.getUserCall();
                    }
                    if (userEvents?.matchUpdatedTimestamp &&
                        this.userEvents?.matchUpdatedTimestamp !== userEvents?.matchUpdatedTimestamp) {
                        // TODO erreur ici, quand on annule un match je pense
                        this.matchesService.getMatchByIdCall(userEvents.matchUpdated);
                    }
                    this.userEvents = userEvents;
                }
            },
            (error) => {
                console.error(error);
                // this.usersService.logout();
            }
        )
    }


    private async networkStatus() {
        const status = await Network.getStatus();
        console.log(status);
        if (status?.connected) {
            this.getSettingsVersionListener();
            this.initializeFirebase();
        } else {
            this.loadingToastAlertProvider.offlineStatus();
        }
        // it's fire only when there is an update of the network status
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status);
            const connected = !!status?.connected;
            if (connected) {
                this.loadingToastAlertProvider.dismissOfflineAlert();
                this.getSettingsVersionListener();
                this.initializeFirebase();
            } else {
                // if (this.authSubscription) {
                //     this.authSubscription();
                // }
                // this.stopListeners();
                this.loadingToastAlertProvider.offlineStatus();
            }
        });
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(async data => {
            if (data) {
                const lang = data.lang;
                if (lang) {
                    this.translate.use(lang);
                }
                if (data?.isInit) {
                    if (!this.dataInit) {
                        this.dataInit = true;
                        this.matchesService.getMatchesByUserIdCall();
                    }
                    const stripeProductsIdPaid = data?.stripeProductsIdPaid || [];
                    for (const p of stripeProductsIdPaid) {
                        this.openStripeModal(p);
                    }
                } else {
                    this.router.navigate([ERoutes.CREATION], {replaceUrl: true});
                }
            }
        });
    }

    // autorefresh the app when version is updated
    private async getSettingsVersionListener() {
        if (this.currentPlatform !== EPlatform.web) {
            return;
        }
        const settingsVersionDoc = doc(this.db, ECollections.settings, EDocuments.settings);
        this.settingsEventsSnapshotListener = onSnapshot(settingsVersionDoc, (d) => {
                if (d.exists()) {
                    const data = d?.data() as ISettings;
                    const version = environment.production ? data.version : data.versionDemo;
                    if (version !== environment.version) {
                        location.reload();
                    }
                }
            },
            (error) => {
                console.error(error);
            });
    }

    private async openStripeModal(productId: EStripeProduct) {
        const modal = await this.modalController.create({
            component: StripeModalComponent as any,
            cssClass: 'stripe-modal',
            componentProps: {
                productId
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            this.stripeService.stripePaymentModalCall(productId);
        })
    }

    private async openPWAModal() {
        const modal = await this.modalController.create({
            component: ModalPWAComponent as any,
            componentProps: {}
        });
        await modal.present();
        this.modalService.openingModal();
    }

    private pressBackButton() {
        if (Capacitor.getPlatform() === EPlatform.android) {
            this.platform.backButton.subscribeWithPriority(10, async () => {
                const modal = await this.modalController.getTop();
                if (modal) {
                    modal.dismiss();
                }
            });
        } else if (Capacitor.getPlatform() === EPlatform.web) {
            window.history.pushState({}, '');
            this.modalService.getModalState().subscribe(isOpen => {
                if (isOpen) {
                    window.history.pushState({}, '');
                }
            });
            // Event listener for popstate
            window.addEventListener('popstate', async (event) => {
                const modal = await this.modalController.getTop();
                if (modal) {
                    modal.dismiss();
                }
            });
        }
    }

    private async sendVerificationEmail(user: User) {
        await this.loadingToastAlertProvider.presentLoadingWithOptions();
        try {
            await sendEmailVerification(user);
            this.loadingToastAlertProvider.presentAlert('emailSent', 'checkYourEmailCreate',
                false, 'loginAlert');
        } catch (error) {
            console.error(error);
            this.loadingToastAlertProvider.displayError(error);
        }
        this.usersService.logout();
        this.loadingToastAlertProvider.dismissLoading();
    }


}
