import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EPlatform, ERole, ERoutes, TOAST_POSITION, WEBSITE_URL} from "@models/general";
import {EDistanceUnits, EGender, EHeightUnits, ESexualOrientation, IUser} from "@models/user";
import {UsersService} from "@services/users-service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {
    cmToFeetAndInches,
    copyToClipboard,
    kilometersToMiles,
    translateGender,
    translateSexualOrientation
} from "@helpers/misc";
import {ModalController} from "@ionic/angular";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {CustomUnitPipe} from "@shared/pipes/custom-currency.pipe";
import {MatchesService} from "@services/matches.service";
import {EloService} from "@services/elo-service";
import {AdminService} from "@services/admin-service";
import {EUpdateUserAdmin, IAdminGenderOrientation, IUpdateAdminUser} from "@models/admin";
import {AdminMessagesModalComponent} from "@shared/components/admin-messages-modal/admin-messages-modal.component";
import {Share} from "@capacitor/share";
import {TranslateService} from "@ngx-translate/core";
import {Capacitor} from "@capacitor/core";
import {ModalService} from "@services/modal-service";
import {FiltersService} from "@services/filters.service";

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
    // @ViewChild('swiper', {static: false})
    swiper: any;

    @Input()
    user?: IUser;

    @Input()
    fromVote = false;

    @Input()
    displayLike = false;

    @Input()
    displayInstantMatch = true;

    @Input()
    fromMessage = false; // to avoid infinite modal

    ERole = ERole;
    EGender = EGender;
    WEBSITE_URL = WEBSITE_URL;
    EUpdateUserAdmin = EUpdateUserAdmin;
    allMatchsIds: string[] = [];
    selectedHeightUnit = EHeightUnits.centimeters;
    selectedDistanceUnit = EDistanceUnits.km;
    currentUser?: IUser;
    isLoading = true;
    displayPrev = false;
    displayNext = false;
    dataInit = false;
    routeId?: string;
    EPlatform = EPlatform;
    platform = EPlatform.ios;
    adminGender: IAdminGenderOrientation[] = [];
    adminSexualOrientation: IAdminGenderOrientation[] = [];
    private subscription = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private customUnitPipe: CustomUnitPipe,
        private modalService: ModalService,
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
        private filtersService: FiltersService,
        private matchesService: MatchesService,
        private eloService: EloService,
        private translate: TranslateService,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private modalController: ModalController,
        private usersService: UsersService,
        private router: Router) {
    }

    async ngOnInit() {
        console.log(this.user);
        this.platform = Capacitor.getPlatform() as EPlatform;
        this.routeId = this.route?.snapshot?.params['id'];
        const userId = this.user?.documentId || this.routeId;
        if (!userId) {
            this.router.navigate([ERoutes.SPARK]);
            return;
        }
        this.getDistanceUnits();
        this.getHeightUnits();
        await this.getUserByIdCall(userId);
        this.getCurrentUser();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    isAMatch(): boolean {
        const userId = this.user?.documentId;
        if (!userId) {
            return false;
        }
        return this.allMatchsIds.includes(userId);
    }

    async shareProfile() {
        const url = `${WEBSITE_URL}/${ERoutes.USER}/${this.user?.documentId}`;
        const canShare = !!(await Share.canShare())?.value;
        if (canShare) {
            await Share.share({
                title: this.translate.instant("shareTitle"),
                text: this.translate.instant("shareText"),
                url,
                dialogTitle: this.translate.instant("shareDialogTitle"),
            });
        } else {
            copyToClipboard(url);
            this.loadingToastAlertProvider.presentToastWithOptions(
                'profileCopied', false, TOAST_POSITION.BOTTOM);
        }

    }

    getHeight(heightCm: number): string {
        if (this.selectedHeightUnit === EHeightUnits.feetInches) {
            const resultFeetInches = cmToFeetAndInches(heightCm);
            const heightFeet = resultFeetInches?.feet || 0;
            const heightInches = resultFeetInches?.inches || ''

            return `${heightFeet}' ${heightInches}"`;
        }

        return this.customUnitPipe.transform(`${heightCm}`, 'cm') as string;
    }

    getDistance(distanceKm: number): string {
        if (this.selectedDistanceUnit === EDistanceUnits.mi) {
            const resultMiles = kilometersToMiles(distanceKm);

            return this.customUnitPipe.transform(`${resultMiles}`, 'Mi') as string;
        }

        return this.customUnitPipe.transform(`${distanceKm}`, 'Km') as string;
    }

    openLink(id?: string, isTiktok = false) {
        if (!id) {
            return;
        }
        let url = "";
        let username = "";
        if (isTiktok) {
            username = id.replace(/@/g, '');
            url = `https://www.tiktok.com/@${username}`;
        } else {
            username = id.replace(/@/g, '');
            url = `https://www.instagram.com/${username}`;
        }
        window.open(url, "_blank");
    }

    async reportUser() {
        if (!this.user?.documentId) {
            return
        }
        try {
            await this.loadingToastAlertProvider.confirmationPopup(
                'report', 'areYouSureReport');
            await this.loadingToastAlertProvider.presentLoadingWithOptions();
            await this.usersService.reportUserByIdCall(this.user.documentId);
            await this.loadingToastAlertProvider.presentToastWithOptions(
                "thanksForReporting", false, TOAST_POSITION.MIDDLE);
        } catch (error) {
            console.log(error);
            if (error) {
                await this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            }
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async openUserMessages() {
        if (!this.currentUser?.roles?.includes(ERole.ADMIN) || !this.user?.documentId) {
            return;
        }
        const modal = await this.modalController.create({
            component: AdminMessagesModalComponent as any,
            componentProps: {
                user: this.user
            }
        });
        await modal.present();
        this.modalService.openingModal();
    }

    async updateAdminUser(action: EUpdateUserAdmin, gender?: EGender, sexualOrientation?: ESexualOrientation) {
        if (!this.currentUser?.roles?.includes(ERole.ADMIN) || !this.user?.documentId) {
            return;
        }
        if (action !== EUpdateUserAdmin.gender && action !== EUpdateUserAdmin.sexualOrientation) {
            await this.loadingToastAlertProvider.presentLoadingWithOptions();
        }
        try {
            const payload: IUpdateAdminUser = {
                action,
                gender,
                sexualOrientation,
                userId: this.user.documentId
            }
            await this.adminService.updateUserAdminCall(payload);
            this.getUserByIdAdminCall(this.user.documentId);
            if (action === EUpdateUserAdmin.unban || action === EUpdateUserAdmin.ban) {
                let messageSuccess = "User is now banned ! User can't vote anymore and profile will be hidden";
                if (action === EUpdateUserAdmin.unban) {
                    messageSuccess = "User is no more banned ! His profile will be visible";
                }
                this.loadingToastAlertProvider.presentToastWithOptions(
                    messageSuccess, false, TOAST_POSITION.MIDDLE);
            } else {
                this.loadingToastAlertProvider.presentToastWithOptions(
                    "Successfully updated", false, TOAST_POSITION.BOTTOM);
            }
        } catch (error: any) {
            this.loadingToastAlertProvider.displayError(error);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async deleteUserAdmin() {
        if (!this.currentUser?.roles?.includes(ERole.ADMIN) || !this.user?.documentId) {
            return;
        }


        try {
            const message = "Are you sure you want to delete this user ?";
            await this.loadingToastAlertProvider.confirmationPopup(
                "Delete user", message);
            await this.loadingToastAlertProvider.presentLoadingWithOptions("deleting user...");
            await this.usersService.deleteUserCall(this.user.documentId);
            this.loadingToastAlertProvider.presentToastWithOptions(
                "User successfully deleted", false, TOAST_POSITION.MIDDLE);
            this.close();
        } catch (error: any) {
            this.loadingToastAlertProvider.displayError(error);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async setUserAdminCall(roles: ERole[]) {
        if (!this.currentUser?.roles?.includes(ERole.SUPER_ADMIN) || !this.user?.documentId
            || this.user.spark) {
            return;
        }
        try {
            const message = `This user will get the following roles: ${roles?.toString()}, are you sure ?`;
            await this.loadingToastAlertProvider.confirmationPopup(
                "Admin role", message);
            await this.loadingToastAlertProvider.presentLoadingWithOptions();
            await this.adminService.setUserRole({
                documentId: this.user.documentId,
                roles
            });

            const messageSuccess = `This user have now the following roles: ${roles?.toString()} 
            User must reconnect to apply changes.`;
            this.loadingToastAlertProvider.presentToastWithOptions(
                messageSuccess, false, TOAST_POSITION.MIDDLE);
            this.getUserByIdAdminCall(this.user.documentId);
        } catch (error: any) {
            if (error) {
                this.loadingToastAlertProvider.displayError(error);
            }
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    close(isUnmatch = false, ev?: any) {
        ev?.stopPropagation();
        if (this.routeId) {
            this.router.navigate([ERoutes.SPARK]);
        } else {
            this.modalController.dismiss({isUnmatch});
        }
    }

    async cancelMatchUser(userId?: string) {
        if (!userId) {
            return;
        }

        try {
            await this.loadingToastAlertProvider.confirmationPopup('unmatch', 'areYouSureUnmatch');
            await this.loadingToastAlertProvider.presentLoadingWithOptions('cancellingMatch');
            await this.matchesService.cancelMatchCall(userId);
            this.loadingToastAlertProvider.presentToastWithOptions(
                "successfullyUnmatch",
                false, TOAST_POSITION.MIDDLE);
            this.matchesService.removeLikesUsersId(userId);
            this.close(true);
        } catch (error) {
            console.log(error);
            if (error) {
                this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            }
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    getPicture(images: string[]): string {
        if (images?.length) {
            return images[0];
        }

        return '';
    }

    translateSexualOrientation(orientation?: ESexualOrientation): string {
        return translateSexualOrientation(orientation);
    }

    translateGender(gender?: EGender): string {
        return translateGender(gender);
    }

    setGenderOrientation(id: EGender | ESexualOrientation, isGender = false) {
        if (isGender) {
            for (const g of this.adminGender) {
                g.selected = g.id === id;
            }
            this.updateAdminUser(EUpdateUserAdmin.gender, id as EGender, undefined);
        } else {
            for (const g of this.adminSexualOrientation) {
                g.selected = g.id === id;
            }
            this.updateAdminUser(EUpdateUserAdmin.sexualOrientation, undefined, id as ESexualOrientation);
        }
    }

    private initAdminGenderOrientation() {
        if (!this.user?.spark) {
            return;
        }
        this.adminGender = [{
            id: EGender.MAN,
            label: 'man',
            selected: this.user?.gender === EGender.MAN
        }, {
            id: EGender.WOMAN,
            label: 'woman',
            selected: this.user?.gender === EGender.WOMAN
        }, {
            id: EGender.OTHERS,
            label: 'others',
            selected: this.user?.gender === EGender.OTHERS
        }];
        this.adminSexualOrientation = [{
            id: ESexualOrientation.STRAIGHT,
            label: 'straight',
            selected: this.user?.sexualOrientation === ESexualOrientation.STRAIGHT
        }, {
            id: ESexualOrientation.GAY,
            label: 'gay',
            selected: this.user?.sexualOrientation === ESexualOrientation.GAY
        }, {
            id: ESexualOrientation.LESBIAN,
            label: 'lesbian',
            selected: this.user?.sexualOrientation === ESexualOrientation.LESBIAN
        }, {
            id: ESexualOrientation.BISEXUAL,
            label: 'bisexual',
            selected: this.user?.sexualOrientation === ESexualOrientation.BISEXUAL
        }, {
            id: ESexualOrientation.OTHERS,
            label: 'others',
            selected: this.user?.sexualOrientation === ESexualOrientation.OTHERS
        }]
    }

    private async getUserByIdCall(userId: string) {
        this.isLoading = true;
        try {
            const res = await this.usersService.getUserByIdCall(userId);
            console.log(res);
            this.user = res?.data as IUser;
        } catch (error) {
            console.log(error);
            await this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            this.router.navigate([ERoutes.SPARK]);
        }
        this.isLoading = false;
    }

    private async getUserByIdAdminCall(userId: string) {
        this.isLoading = true;
        try {
            const res = await this.adminService.getUserByIdAdminCall(userId);
            console.log(res);
            this.user = res?.data as IUser;
            this.initAdminGenderOrientation();
            this.cdr.detectChanges();
        } catch (error) {
            console.log(error);
            await this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
        }
        this.isLoading = false;
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
            if (data) {
                if (!this.dataInit) {
                    this.dataInit = true;
                    this.getAllMatchsIds();
                }
                if (data?.roles?.includes(ERole.ADMIN) && this.user?.documentId) {
                    this.getUserByIdAdminCall(this.user.documentId);
                }
            }
        });
    }


    private async getHeightUnits() {
        this.selectedHeightUnit = this.filtersService.allFilters.selectedHeightUnit;
    }

    private async getDistanceUnits() {
        this.selectedDistanceUnit = this.filtersService.allFilters.selectedDistanceUnit;
    }

    private getAllMatchsIds() {
        this.matchesService.getAllMatchsUsersIds().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.allMatchsIds = data;
        });
    }

}
