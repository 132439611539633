import {Injectable} from "@angular/core";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {REGION, STORAGE_AUTO_SWIPE, TOAST_POSITION} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {encryptChunk} from "@helpers/forge";
import {IUser, IUserFilters} from "@models/user";
import {IEloVotePayload, IGameResponse} from "@models/elo";
import {BehaviorSubject} from "rxjs";
import {Preferences} from "@capacitor/preferences";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {FiltersService} from "@services/filters.service";

@Injectable({
    providedIn: 'root'
})
export class EloService {

    // this is set from app.component
    uid = '';
    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);

    private _battles = new BehaviorSubject<IUser[][]>([]);
    private _genderChange = new BehaviorSubject<boolean>(false);
    private _autoSwiper = new BehaviorSubject<boolean>(false);

    constructor(private loadingToastAlertProvider: LoadingToastAlertProvider,
                private filtersService: FiltersService) {

        this.getAutoSwipe();
    }

    getGenderChange() {
        return this._genderChange.asObservable();
    }

    setGenderChange(change: boolean) {
        this._genderChange.next(change);
    }

    getAutoSwiper() {
        return this._autoSwiper.asObservable();
    }

    setAutoSwiper(auto: boolean) {
        this._autoSwiper.next(auto);
    }

    getBattles() {
        return this._battles.asObservable();
    }

    setBattles(battles: IUser[][]) {
        this._battles.next(battles);
    }

    async getFilteredUsersCall(data: IUserFilters) {
        const callRequest = httpsCallable(this.functions, 'elo-getFilteredUsersCall');
        const payloadString = JSON.stringify(data);
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    async voteCall(data: IEloVotePayload) {
        const callRequest = httpsCallable(this.functions, 'elo-voteCall');
        const payloadString = JSON.stringify(data);
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    async getGameCall() {
        const gender = this.filtersService.allFilters.selectedGenderVote;
        try {
            const payload: IUserFilters = {
                gender,
                kmRadius: this.filtersService.allFilters.kmRadiusValueVote
            }

            const callRequest = httpsCallable(this.functions, 'elo-getGameCall');
            const payloadString = JSON.stringify(payload);
            const encryptedPayload = encryptChunk(payloadString);
            const res = await callRequest(encryptedPayload) as any;

            console.log(res);
            const data = res?.data as IGameResponse;
            const battles = data?.battles as IUser[][] || [];
            if (!battles?.length) {
                this.loadingToastAlertProvider.presentToastWithOptions("noResult", true,
                    TOAST_POSITION.MIDDLE);
            }
            this.setBattles(battles);
        } catch (error) {
            console.log(error);
            this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
        }
    }

    private async getAutoSwipe() {
        let value = (await Preferences.get({key: STORAGE_AUTO_SWIPE}))?.value;
        this.setAutoSwiper(!value);
    }


}
