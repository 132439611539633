import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EHeightUnits} from "@models/user";
import {cmToFeetAndInches, feetAndInchesToCm} from "@helpers/misc";
import {FiltersService} from "@services/filters.service";
import {STORAGE_FILTERS} from "@models/general";

@Component({
    selector: 'app-user-height',
    templateUrl: './user-height.component.html',
    styleUrls: ['./user-height.component.scss'],
})
export class UserHeightComponent implements OnInit {

    @Input()
    heightCm?: number;

    @Output()
    heightCmEvent = new EventEmitter<number>();

    EHeight = EHeightUnits;
    selectedHeightUnit = EHeightUnits.centimeters;
    heightFeet?: number;
    heightInches?: number;

    constructor(
        private filtersService: FiltersService
    ) {
    }

    async ngOnInit() {
        this.selectedHeightUnit = this.filtersService.allFilters.selectedHeightUnit as EHeightUnits;
    }

    heightValueChange(event?: any) {
        if (this.selectedHeightUnit === EHeightUnits.feetInches) {
            const resultCm = feetAndInchesToCm(this.heightFeet, this.heightInches);
            this.heightCm = resultCm || undefined;
        }
        this.heightCmEvent.emit(this.heightCm);
    }

    async onHeightUnitChange() {


        this.filtersService.setStorageValue(STORAGE_FILTERS.STORAGE_HEIGHT_UNITS, this.selectedHeightUnit);
        if (this.selectedHeightUnit === EHeightUnits.centimeters) {
            const resultCm = feetAndInchesToCm(this.heightFeet, this.heightInches);
            this.heightCm = resultCm || undefined;
        } else {
            const resultFeetInches = cmToFeetAndInches(this.heightCm);
            this.heightFeet = resultFeetInches?.feet || undefined;
            this.heightInches = resultFeetInches?.inches || undefined;
        }
    }

}
