import {Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ELang, TOAST_POSITION} from "@models/general";

const alertDoNotClose = 'alert-do-not-close';

@Injectable({
    providedIn: 'root'
})
export class LoadingToastAlertProvider {

    private loading: any;
    private toast: any;
    private offlineAlert: any;

    constructor(
        public loadingController: LoadingController,
        public toastController: ToastController,
        private translate: TranslateService,
        private platform: Platform,
        public alertController: AlertController) {
    }


    async presentLoadingWithOptions(message: string = 'pleaseWait', duration = 10000) {
        this.dismissLoading();
        this.loading = await this.loadingController.create({
            spinner: 'bubbles',
            duration,
            message: this.translate.instant(message),
            translucent: true,
            mode: "ios",
            showBackdrop: false,
            cssClass: 'custom-class custom-loading'
        });
        return await this.loading.present();
    }

    async presentToastWithOptions(headerCode: any, isError: boolean, position?: TOAST_POSITION) {
        if (this.toast) {
            this.toast.dismiss();
        }
        headerCode = headerCode?.message || headerCode;
        headerCode = headerCode ? headerCode : isError ? 'error' : 'unknown';
        this.toast = await this.toastController.create({
            header: this.translate.instant(headerCode),
            // message: 'Click to Close',
            position: position || TOAST_POSITION.MIDDLE,
            animated: true,
            color: isError ? 'danger' : 'dark',
            duration: 3000,
            keyboardClose: false,
            translucent: false
        });
        this.toast.present();
    }

    async presentAlert(headerCode = 'alert',
                       message: string,
                       backdropDismiss = true,
                       cssClass = ''): Promise<any> {

        message = this.translate.instant(message);
        if (Array.isArray(message)) {
            message = message.join('');
        }
        console.log(message);
        const alert = await this.alertController.create({
            backdropDismiss,
            translucent: true,
            header: this.translate.instant(headerCode),
            message: message,
            cssClass,
            buttons: [{
                text: this.translate.instant('ok'),
                handler: () => {
                }
            }]
        });

        await alert.present();

        return alert;
    }

    async offlineStatus(): Promise<any> {
        this.offlineAlert = await this.alertController.create({
            backdropDismiss: false,
            translucent: true,
            header: this.translate.instant("noInternet"),
            message: this.translate.instant("offlineStatus"),
            cssClass: '',
            buttons: [{
                text: this.translate.instant('ok'),
                handler: () => {
                }
            }]
        });

        await this.offlineAlert.present();

        return this.offlineAlert;
    }

    dismissOfflineAlert() {
        if (this.offlineAlert) {
            this.offlineAlert.dismiss();
        }
    }

    async presentAlertLikeTutorial(headerCode = 'alert',
                                   message: string,
                                   backdropDismiss = true,
                                   params: any = {},
                                   cssClass = ''): Promise<any> {

        return new Promise(async (resolve, reject) => {

            const label = this.translate.instant("doNotShowAgain");
            const alert = await this.alertController.create({
                backdropDismiss,
                translucent: true,
                header: this.translate.instant(headerCode),
                message: this.translate.instant(message, params),
                cssClass,
                inputs: [
                    {
                        label,
                        type: 'checkbox',
                        value: true,
                    }
                ],
                buttons: [{
                    text: this.translate.instant('ok'),
                    handler: (data) => {
                        resolve(data);
                    }
                }]
            });

            alert.present();
        })

    }

    dismissLoading() {
        if (this.loading) {
            this.loading.dismiss();
        }
    }


    confirmationPopup(header = 'confirmation', message = 'areYouSure'): Promise<boolean> {

        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                backdropDismiss: false,
                cssClass: 'confirmationAlert',
                header: this.translate.instant(header),
                message: this.translate.instant(message),
                buttons: [{
                    text: this.translate.instant('no'),
                    handler: () => {
                        reject();
                    }
                }, {
                    text: this.translate.instant('yes'),
                    handler: () => {
                        resolve(true);
                    }
                }]
            });

            alert.present();
        });
    }

    buyInstantsMatchPopup(header = 'confirmation', message = 'areYouSure'): Promise<boolean> {

        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                backdropDismiss: false,
                cssClass: 'confirmationAlert',
                header: this.translate.instant(header),
                message: this.translate.instant(message),
                buttons: [{
                    text: this.translate.instant('cancel'),
                    handler: () => {
                        reject();
                    }
                }, {
                    text: this.translate.instant('getMore'),
                    handler: () => {
                        resolve(true);
                    }
                }]
            });

            alert.present();
        });
    }

    async presentAlertLanguage(isCreatePage = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                backdropDismiss: !isCreatePage,
                id: alertDoNotClose,
                header: 'Languages',
                inputs: [
                    {
                        name: 'english',
                        type: 'radio',
                        label: 'English',
                        value: ELang.en,
                        checked: this.translate.currentLang === ELang.en
                    },
                    {
                        name: 'española',
                        type: 'radio',
                        label: 'Española',
                        value: ELang.es,
                        checked: this.translate.currentLang === ELang.es
                    },
                    {
                        name: 'Portuguesa',
                        type: 'radio',
                        label: 'Portuguesa',
                        value: ELang.pt,
                        checked: this.translate.currentLang === ELang.pt
                    },
                    {
                        name: 'русский',
                        type: 'radio',
                        label: 'Pусский',
                        value: ELang.ru,
                        checked: this.translate.currentLang === ELang.ru
                    },
                    {
                        name: 'french',
                        type: 'radio',
                        label: 'Français',
                        value: ELang.fr,
                        checked: this.translate.currentLang === ELang.fr
                    },
                    {
                        name: 'bahasa Indonesia',
                        type: 'radio',
                        label: 'Bahasa Indonesia',
                        value: ELang.id,
                        checked: this.translate.currentLang === ELang.id
                    },
                    {
                        name: 'Deutsche',
                        type: 'radio',
                        label: 'Deutsche',
                        value: ELang.de,
                        checked: this.translate.currentLang === ELang.de
                    },
                    {
                        name: 'italiano',
                        type: 'radio',
                        label: 'Italiano',
                        value: ELang.it,
                        checked: this.translate.currentLang === ELang.it
                    },
                    {
                        name: 'Tiếng Việt',
                        type: 'radio',
                        label: 'Tiếng Việt',
                        value: ELang.vi,
                        checked: this.translate.currentLang === ELang.vi
                    },
                    {
                        name: 'Türk',
                        type: 'radio',
                        label: 'Türk',
                        value: ELang.tr,
                        checked: this.translate.currentLang === ELang.tr
                    },
                    {
                        name: '日本人',
                        type: 'radio',
                        label: '日本人',
                        value: ELang.ja,
                        checked: this.translate.currentLang === ELang.ja
                    },
                    {
                        name: '한국어',
                        type: 'radio',
                        label: '한국어',
                        value: ELang.ko,
                        checked: this.translate.currentLang === ELang.ko
                    },
                    {
                        name: '中文',
                        type: 'radio',
                        label: '中文',
                        value: ELang.zh,
                        checked: this.translate.currentLang === ELang.zh
                    },
                    {
                        name: 'हिन्दी',
                        type: 'radio',
                        label: 'हिन्दी',
                        value: ELang.hi,
                        checked: this.translate.currentLang === ELang.hi
                    },
                    {
                        name: 'عربى',
                        type: 'radio',
                        label: 'عربى',
                        value: ELang.ar,
                        checked: this.translate.currentLang === ELang.ar
                    },
                    {
                        name: 'ไทย',
                        type: 'radio',
                        label: 'ไทย',
                        value: ELang.th,
                        checked: this.translate.currentLang === ELang.th
                    }
                ],
                buttons: [{
                    text: 'Cancel',
                    handler: () => {
                        reject();
                    }
                }, {
                    text: 'Select',
                    handler: (data) => {
                        console.log(data);
                        resolve(data);
                    }
                }]
            });

            alert.present();
        });
    }


    displayError(error?: any, dismissLoading = true) {
        const message = error?.message || JSON.stringify(error) || 'error';
        if (dismissLoading) {
            this.dismissLoading();
        }
        if (!error) {
            return;
        }
        this.presentToastWithOptions(message, true, TOAST_POSITION.MIDDLE);
    }

    async resendVerificationLink(headerCode = 'alert', message: string, backdropDismiss = true) {

        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                backdropDismiss,
                header: this.translate.instant(headerCode),
                message: this.translate.instant(message),
                cssClass: 'loginAlert',
                buttons: [{
                    text: this.translate.instant('ok'),
                    handler: () => {
                        reject();
                    }
                }, {
                    text: this.translate.instant('resendLink'),
                    handler: () => {
                        resolve(true);
                    }
                }]
            });

            alert.present();
        });
    }

    dismissAlert() {
        this.alertController.getTop().then(data => {
            console.log(data);
            // we do this check because on login/create we have to keep the language alert popup displayed
            // by default
            if (data && data.id !== alertDoNotClose) {
                this.alertController.dismiss();
            }
        })
    }
}
