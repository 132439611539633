import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UserDetailsComponent} from "@app/shared/components/user-details/user-details.component";
import {FilterUsersComponent} from "@shared/components/filter-users/filter-users.component";
import {CustomUnitPipe} from "@shared/pipes/custom-currency.pipe";
import {CameraComponent} from "@shared/components/camera/camera.component";
import {UserHeightComponent} from "@shared/components/user-height/user-height.component";
import {CustomSwiperComponent} from "@shared/components/custom-swiper/custom-swiper.component";
import {AdminMessagesModalComponent} from "@shared/components/admin-messages-modal/admin-messages-modal.component";
import {PurchaseConsumableComponent} from "@shared/components/purchase-consumable/purchase-consumable.component";
import {PurchasePremiumComponent} from "@shared/components/purchase-premium/purchase-premium.component";
import {MessagesComponent} from "@shared/components/messages/messages.component";
import {SettingsComponent} from "@app/profile/components/settings/settings.component";
import {StripeModalComponent} from "@shared/components/stripe-modal/stripe-modal.component";
import {ModalPWAComponent} from "@shared/components/modal-pwa/modal-pwa.component";
import {HistoryComponent} from "@shared/components/history/history.component";
import {MatchModalComponent} from "@shared/components/match-modal/match-modal.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [CustomUnitPipe,
        UserDetailsComponent,
        AdminMessagesModalComponent,
        CameraComponent,
        UserHeightComponent,
        CustomSwiperComponent,
        FilterUsersComponent,
        PurchaseConsumableComponent,
        PurchasePremiumComponent,
        SettingsComponent,
        StripeModalComponent,
        ModalPWAComponent,
        MessagesComponent,
        HistoryComponent,
        MatchModalComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        CustomUnitPipe,
        UserDetailsComponent,
        AdminMessagesModalComponent,
        CameraComponent,
        SettingsComponent,
        UserHeightComponent,
        CustomSwiperComponent,
        StripeModalComponent,
        FilterUsersComponent,
        PurchaseConsumableComponent,
        PurchasePremiumComponent,
        ModalPWAComponent,
        MessagesComponent,
        HistoryComponent,
        MatchModalComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [CustomUnitPipe]
})

export class SharedModule {
}
