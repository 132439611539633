<ion-content class="ion-padding">
    <div class="container">

        <div class="top-container-title">
            <div class="content-title">
                {{"paymentSuccessful" | translate}}
            </div>
            <div class="top-actions">
                <div (click)="close()" class="rounded-icon">
                    <ion-icon color="myprimary" name="close"></ion-icon>
                </div>
            </div>
        </div>

        <ng-container *ngIf="productId===EStripeProduct.BOOST_LEVEL1
|| productId===EStripeProduct.BOOST_LEVEL2
|| productId===EStripeProduct.BOOST_LEVEL3
|| productId===EStripeProduct.BOOST_LEVEL1_TEST
|| productId===EStripeProduct.BOOST_LEVEL2_TEST
|| productId===EStripeProduct.BOOST_LEVEL3_TEST">
            <div class="message ion-text-center">
                {{"newBoostsReceived" | translate:{count: getBonusCount()} }}

                <div class="rounded-icon">
                    <ion-icon color="myprimary" name="flash"></ion-icon>
                </div>

            </div>


            <div class="description">
                {{"profileBoostsSuccessDescription" | translate:{boostsCount: BOOSTS_COUNT} }}
            </div>
        </ng-container>


        <ng-container *ngIf="productId===EStripeProduct.INSTANT_MATCH_LEVEL1
|| productId===EStripeProduct.INSTANT_MATCH_LEVEL2
|| productId===EStripeProduct.INSTANT_MATCH_LEVEL3
|| productId===EStripeProduct.INSTANT_MATCH_LEVEL1_TEST
|| productId===EStripeProduct.INSTANT_MATCH_LEVEL2_TEST
|| productId===EStripeProduct.INSTANT_MATCH_LEVEL3_TEST">
            <div class="message ion-text-center">
                {{"newInstantsMatchsReceived" | translate:{count: getBonusCount()} }}

                <div class="rounded-icon">
                    <ion-icon color="myprimary" name="magnet"></ion-icon>
                </div>

            </div>


            <div class="description">
                {{"instantsMatchsSuccessDescription" | translate:{boostsCount: BOOSTS_COUNT} }}
            </div>
        </ng-container>

        <ng-container *ngIf="productId===EStripeProduct.PREMIUM
|| productId===EStripeProduct.PREMIUM_TEST">
            <div class="message ion-text-center">
                {{"newPremiumReceived" | translate }}

                <div class="rounded-icon">
                    <ion-icon color="myprimary" name="diamond"></ion-icon>
                </div>

            </div>


            <div class="description">
                {{"premiumSuccessDescription" | translate }}
            </div>
        </ng-container>

        <!--        <ion-button (click)="close()"-->
        <!--                    class="ion-margin-top"-->
        <!--                    color="myprimary"-->
        <!--                    expand="full"-->
        <!--                    mode="ios"-->
        <!--                    shape="round">-->
        <!--            {{"iUnderstand" | translate}}-->
        <!--        </ion-button>-->
    </div>

</ion-content>
