import {EResultElo, IEloNewRating} from "../models/elo";
import {EMatchKey, IMatch} from "../models/matches";
import {IStats} from "../models/stats";
import {INIT_ELO, STANDARD_DEVIATION} from "../models/general";
import {EGender} from "../models/user";

export function calculateDynamicKFactor(playerRating: number, baseKFactor = 32) {
    // Adjust the K-factor based on the player's rating
    if (playerRating < 2100) {
        return baseKFactor;
    } else if (playerRating >= 2100 && playerRating < 2400) {
        return baseKFactor / 2;
    } else {
        return baseKFactor / 4;
    }
}

export function calculateEloRating(playerA: number, playerB: number, result: EResultElo, baseKFactor = 32): IEloNewRating {
    // Calculate dynamic K-factors
    const kFactorA = calculateDynamicKFactor(playerA, baseKFactor);
    const kFactorB = calculateDynamicKFactor(playerB, baseKFactor);

    // Constants
    const kA = kFactorA;
    const kB = kFactorB;

    // Calculate expected scores
    const expectedScoreA = 1 / (1 + Math.pow(10, (playerB - playerA) / 400));
    const expectedScoreB = 1 / (1 + Math.pow(10, (playerA - playerB) / 400));

    // Calculate new ratings
    const newRatingA = playerA + kA * (result - expectedScoreA);
    const newRatingB = playerB + kB * ((1 - result) - expectedScoreB);

    return {
        newRatingA: Math.round(newRatingA),
        newRatingB: Math.round(newRatingB),
    };
}

function factorielle(n: number): number {
    if (n === 0 || n === 1) {
        return 1;
    } else {
        return n * factorielle(n - 1);
    }
}

function combinaison(n: number, r: number): number {
    return factorielle(n) / (factorielle(r) * factorielle(n - r));
}

export function nombreDuelsPossibles(nombreIndividus: number, individusParDuel: number): number {
    // Ajouter une vérification pour éviter les duels avec la même personne
    if (individusParDuel === 2) {
        return (nombreIndividus * (nombreIndividus - 1)) / 2;
    } else {
        return combinaison(nombreIndividus, individusParDuel);
    }
}

export function getRandomIndex(arr: any[] = []): number {
    return Math.floor(Math.random() * arr.length);
}


export function getValueFromMatch(key: EMatchKey, match?: IMatch, myId?: string): string {
    if (!myId || !match) {
        return '';
    }
    const usersIds = match?.usersIds || [];
    const user2Id = usersIds?.find(u => u !== myId);
    if (user2Id) {
        if (key === EMatchKey.userId) {
            return user2Id;
        }
        const value = match[`${user2Id}${key}`] as string || '';
        return value;
    }

    return '';
}

export function combineEloAge(elo: number, age: number): number {
    // return Math.round(Math.sqrt(Math.pow(elo, 2) * Math.pow(age * 100, 2)));
    // return Math.round((elo * (age * 20)) / 2);
    // const eloPercent = elo / MAX_ELO;
    // const ageRange = MAX_AGE - MIN_AGE;
    // const ageDelta = age - MIN_AGE;
    // const agePercent = ageDelta / ageRange;
    //
    // return Math.round((eloPercent * 100) + (agePercent * 100))

    return Math.round((elo * age) / 2)
}

export function calculatePercentageBelow(givenValue: number, stats: IStats, gender: EGender): number {
    // Estimer la moyenne et l'écart type
    // const mean = (minValue + maxValue) / 2;
    // const stdDev = (maxValue - mean) / 3;
    if (!stats?.averageWomenElo) {
        return 0;
    }

    const stdDev = stats.standardDeviation || STANDARD_DEVIATION;
    let mean = stats.averageMenElo || INIT_ELO;
    if (gender === EGender.WOMAN) {
        mean = stats.averageWomenElo || INIT_ELO;
    } else if (gender === EGender.OTHERS) {
        mean = stats.averageOthersElo || INIT_ELO;
    }

    // Calculer le score Z
    const zScore = (givenValue - mean) / stdDev;

    // Calculer le pourcentage en utilisant la CDF de la distribution normale
    const percent = cdf(zScore) * 100;
    return parseInt(percent.toString());
}

function cdf(z: number): number {
    // Fonction pour calculer la CDF (cumulative distribution function)
    const Z = Math.abs(z);
    const T = 1 / (1 + Z / Math.sqrt(2));
    const D = 0.5 * Math.exp(-Math.pow(Z, 2) / 2);
    const prob = D * T * (1 + T * (-1.26551223 + T * (1.00002368 +
        T * (0.37409196 + T * (0.09678418 + T * (-0.18628806 +
            T * (0.27886807 + T * (-1.13520398 + T * (1.48851587 +
                T * (-0.82215223 + T * 0.17087277))))))))));
    const value = z > 0 ? 1 - prob : prob;
    return parseFloat(value.toFixed(2));
}

export function getOppositeGender(gender?: EGender): EGender {
    if (!gender) {
        gender = EGender.WOMAN;
    }
    if (gender === EGender.MAN) {
        return EGender.WOMAN;
    } else if (gender === EGender.WOMAN) {
        return EGender.MAN;
    } else if (gender === EGender.OTHERS) {
        return EGender.OTHERS;
    }
    return EGender.WOMAN;
}
