import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ERoutes} from "@models/general";
import {UserDetailsComponent} from "@app/shared/components/user-details/user-details.component";
import {AuthGuardService} from "@shared/guards/auth-guard.service";
import {EmailSigninComponent} from "@shared/components/email-signin/email-signin.component";
import {AuthAdminGuardService} from "@shared/guards/auth-admin-guard.service";

const routes: Routes = [
    {
        path: ERoutes.LOGIN,
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: ERoutes.ADMIN,
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthAdminGuardService]
    },
    {
        path: `${ERoutes.USER}/:id`,
        component: UserDetailsComponent
    },
    {
        path: `${ERoutes.SIGNIN}`,
        component: EmailSigninComponent
    },
    {
        path: ERoutes.CREATION,
        loadChildren: () => import('./creation/creation.module').then(m => m.CreationModule),
        canActivate: [AuthGuardService]
    },
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuardService]
    }
    // {
    //     path: '',
    //     redirectTo: ERoutes.ELO,
    //     pathMatch: 'full'
    // },
    // {
    //     path: '**',
    //     redirectTo: ''
    // }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
