<ion-header class="">
    <ion-toolbar class="ion-text-center" color="myprimary">
        <ion-title>
            {{"filters" | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <div (click)="closeModal()" class="rounded-icon prim">
                <ion-icon name="close"></ion-icon>
            </div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-list class="ion-padding">
        <ng-container *ngIf="currentRoute === ERoutes.SPARK">
            <ion-item class="">
                <ion-label>{{"autoScroll" | translate}}</ion-label>
                <ion-toggle (ionChange)="onAutoSwipeChange()"
                            [(ngModel)]="autoSwipe"
                            color="myprimary"
                            mode="ios" slot="end"></ion-toggle>
            </ion-item>
            <ion-note>
                {{"autoScrollSub" | translate}}
            </ion-note>
        </ng-container>
        <ion-item *ngIf="currentRoute?.includes(ERoutes.ADMIN_REPORTS)">
            <ion-label>{{"filterBy" | translate}}</ion-label>
            <ion-select [(ngModel)]="selectedFilterBy"
                        [placeholder]="'select' | translate">
                <ion-select-option
                        [value]="EUsersFiltersAdmin.all">{{"all" | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EUsersFiltersAdmin.reported">{{"reported" | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EUsersFiltersAdmin.banned">{{"banned" | translate}}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label>{{"gender" | translate}}</ion-label>
            <ion-select [(ngModel)]="selectedGender"
                        [placeholder]="'select' | translate">
                <ion-select-option
                        [value]="EGender.MAN">{{translateGenderCall(EGender.MAN) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.WOMAN">{{translateGenderCall(EGender.WOMAN) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.OTHERS">{{translateGenderCall(EGender.OTHERS) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.EVERYONE">{{translateGenderCall(EGender.EVERYONE) | translate}}</ion-select-option>
            </ion-select>
        </ion-item>
        <!--        we don't lock gender-->
        <!--        <div *ngIf="isFromVote && !currentUser?.isPremium" class="note-premium">-->
        <!--            <ion-note class="error">-->
        <!--                {{"onlyPremiumCanVoteOthersGender" | translate}}-->
        <!--            </ion-note>-->
        <!--            <ion-button (click)="openPurchasePremium()"-->
        <!--                        class="ion-margin-top"-->
        <!--                        color="myprimary"-->
        <!--                        mode="ios"-->
        <!--                        shape="round"-->
        <!--                        size="small">-->
        <!--                {{"bePremium" | translate}}-->
        <!--            </ion-button>-->
        <!--        </div>-->

        <ion-item
                *ngIf="currentRoute === ERoutes.SPARK0 || currentRoute === ERoutes.ELO || currentRoute?.includes(ERoutes.ADMIN_REPORTS)">
            <ion-label>{{"sexualOrientation" | translate}}</ion-label>
            <ion-select [(ngModel)]="selectedSexualOrientation"
                        [placeholder]="'select' | translate">
                <ion-select-option
                        [value]="ESexualOrientation.STRAIGHT">{{translateSexualOrientation(ESexualOrientation.STRAIGHT) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="ESexualOrientation.GAY">{{translateSexualOrientation(ESexualOrientation.GAY) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="ESexualOrientation.LESBIAN">{{translateSexualOrientation(ESexualOrientation.LESBIAN) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="ESexualOrientation.BISEXUAL">{{translateSexualOrientation(ESexualOrientation.BISEXUAL) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="ESexualOrientation.OTHERS">{{translateSexualOrientation(ESexualOrientation.OTHERS) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="ESexualOrientation.EVERYONE">{{translateSexualOrientation(ESexualOrientation.EVERYONE) | translate}}</ion-select-option>
            </ion-select>
        </ion-item>

    </ion-list>


    <ion-list class="ion-margin-top ion-padding">
        <ion-grid class="ion-margin-top">
            <ion-row>
                <ion-col>
                    <ion-label>{{ "distance" | translate }}:
                        {{getDistance(kmRadiusValue)}}
                    </ion-label>

                    <ion-range [(ngModel)]="kmRadiusValue"
                               [max]="MAX_DISTANCE"
                               [min]="MIN_DISTANCE"
                               [step]="DISTANCE_STEP"
                               color="danger"
                               mode="ios">
                    </ion-range>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-grid *ngIf="currentRoute === ERoutes.SPARK0 || currentRoute === ERoutes.ELO">
            <ion-row>
                <ion-col>
                    <ion-label>{{ "age" | translate }}
                        : {{ageRange.lower}}&nbsp;-&nbsp;{{ageRange.upper >= MAX_AGE ? MAX_AGE + "+" : ageRange.upper}}</ion-label>
                    <ion-range [(ngModel)]="ageRange" [dualKnobs]="true"
                               [max]="MAX_AGE" [min]="MIN_AGE"
                               [step]="AGE_STEP"
                               color="danger"
                               mode="ios"></ion-range>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-grid *ngIf="currentRoute === ERoutes.ELO"
                  class="ion-margin-top">
            <ion-row>
                <ion-col>
                    <ion-label>{{ "elo" | translate }}
                        : {{eloRange.lower}}&nbsp;-&nbsp;{{eloRange.upper >= MAX_ELO ? MAX_ELO + "+" : eloRange.upper}}</ion-label>
                    <ion-range [(ngModel)]="eloRange" [dualKnobs]="true"
                               [max]="MAX_ELO"
                               [min]="0" [step]="ELO_STEP"
                               color="danger"
                               mode="ios"></ion-range>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-list>


    <ion-grid
            class="ion-margin-top">
        <ion-row class="ion-align-items-center">
            <ion-col class="ion-text-center" size="12">
                <ion-button (click)="submit()"
                            color="myprimary"
                            mode="ios"
                            shape="round">
                    {{"submit" | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-content>
