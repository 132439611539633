export interface IMessage {
    text: string;
    senderId: string;
    matchId?: string;
    created: number;
}

export interface IMatch {
    usersIds: string[];
    documentId?: string;
    messagesExist?: boolean;
    lastMessage?: string;
    lastMessageSender?: string; // pour afficher le "a ton tour"
    created: number;
    createdBy: string;
    unmatchedBy?: string;
    updated: number;
    matchDate?: number;
    unmatchDate?: number;
    isMatch: boolean;
    isSeen: boolean;
    spark?: boolean;
    messageUnread?: boolean; // use to display pastille on tab

    [key: string]: string | number | undefined | boolean | string[];
}

export enum EMatchKey {
    firstName = 'firstName',
    picture = 'picture',
    userId = 'userId',
}

export enum ELikesType {
    sent = 'sent',
    received = 'received'
}

export interface ILikesPayload {
    likesType: ELikesType;
    lastDocId?: string;
    lastDocIdSent?: string;
    lastDocIdReceived?: string;
}
