export enum EStripeUrl {
    BOOST_LEVEL1_URL = "https://buy.stripe.com/fZeaG10xj6IU4uI4gi",
    BOOST_LEVEL2_URL = "https://buy.stripe.com/4gw01n4Nzd7i7GU3cf",
    BOOST_LEVEL3_URL = "https://buy.stripe.com/00g8xTdk5ffq9P2dQU",
    INSTANT_MATCH_LEVEL1_URL = "https://buy.stripe.com/dR65lHfsd1oAbXa8wB",
    INSTANT_MATCH_LEVEL2_URL = "https://buy.stripe.com/fZe7tP0xjc3ee5ieV0",
    INSTANT_MATCH_LEVEL3_URL = "https://buy.stripe.com/aEU3dz1Bnc3ef9m007",
    PREMIUM_URL = "https://buy.stripe.com/5kA01n7ZL8R29P2145",
    BOOST_LEVEL1_URL_TEST = "https://buy.stripe.com/test_bIY5nDbUL93LbXGcMQ",
    BOOST_LEVEL2_URL_TEST = "https://buy.stripe.com/test_28oaHX5wn0xf6DmfZ1",
    BOOST_LEVEL3_URL_TEST = "https://buy.stripe.com/test_dR6dU9e2T1Bj6Dm005",
    INSTANT_MATCH_LEVEL1_URL_TEST = "https://buy.stripe.com/test_4gweYdcYPeo5gdW14a",
    INSTANT_MATCH_LEVEL2_URL_TEST = "https://buy.stripe.com/test_bIYg2h9MD7ZH7HqfZ7",
    INSTANT_MATCH_LEVEL3_URL_TEST = "https://buy.stripe.com/test_cN24jzgb15Rz8Lu14c",
    PREMIUM_URL_TEST = "https://buy.stripe.com/test_14k8zP5wneo5d1K8wD",
}

// https://dashboard.stripe.com/test/products?active=true
export enum EStripeProduct {
    BOOST_LEVEL1 = "prod_Pfu4YggTejHm9C",
    BOOST_LEVEL2 = "prod_Pfu4vdYOXAmafS",
    BOOST_LEVEL3 = "prod_Pfu4MkoFzi0IHI",
    INSTANT_MATCH_LEVEL1 = "prod_Pfu4f2LFGSI50H",
    INSTANT_MATCH_LEVEL2 = "prod_Pfu4TVyHvK7KLd",
    INSTANT_MATCH_LEVEL3 = "prod_Pfu5wO9fuAbVC7",
    PREMIUM = "prod_Pfu5HRNxjpb4Pc",
    BOOST_LEVEL1_TEST = "prod_PbnjSTfZN3BYJZ",
    BOOST_LEVEL2_TEST = "prod_Pbs8fZGaIaCYw1",
    BOOST_LEVEL3_TEST = "prod_PbwQYDcC0QJgfE",
    INSTANT_MATCH_LEVEL1_TEST = "prod_PeFLfQR6CzttGj",
    INSTANT_MATCH_LEVEL2_TEST = "prod_PfVjDTHVAwBcrB",
    INSTANT_MATCH_LEVEL3_TEST = "prod_PfVkHsYMqLKbpj",
    PREMIUM_TEST = "prod_PeFktYJqcw5Gzi",
}

export enum EStripeEventType {
    checkoutCompleted = 'checkout.session.completed',
    invoicePaid = 'invoice.paid',
    subscriptionCanceled = 'customer.subscription.deleted',
}

export interface IStripePurchase {
    productId: EStripeProduct,
    timestamp: number,
    isPublic: boolean,
}
