<ion-segment (ionChange)="onHeightUnitChange()"
             [(ngModel)]="selectedHeightUnit"
             color="myprimary"
             mode="ios">
    <ion-segment-button [value]="EHeight.centimeters">
        {{"cm" | translate}}
    </ion-segment-button>
    <ion-segment-button [value]="EHeight.feetInches">
        {{"ft/in" | translate}}
    </ion-segment-button>
</ion-segment>
<div class="ion-margin-top">
    <ion-item *ngIf="selectedHeightUnit === EHeight.centimeters" class="custom-bg">
        <ion-input (input)="heightValueChange()"
                   [(ngModel)]="heightCm"
                   [placeholder]="'centimeters' | translate"
                   type="number"></ion-input>
    </ion-item>
    <div *ngIf="selectedHeightUnit === EHeight.feetInches"
         class="feet-inches">
        <ion-item class="custom-bg">
            <ion-input (input)="heightValueChange($event)"
                       [(ngModel)]="heightFeet"
                       [placeholder]="'feet' | translate"
                       type="number"></ion-input>
        </ion-item>
        <ion-item class="custom-bg" style="margin-left: 10px">
            <ion-input (input)="heightValueChange()"
                       [(ngModel)]="heightInches"
                       [placeholder]="'inches' | translate"
                       type="number"></ion-input>
        </ion-item>
    </div>
    <ion-note>{{"youCanHideYourHeightLaterInSettings" | translate}}</ion-note>
</div>
