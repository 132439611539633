import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Preferences} from "@capacitor/preferences";
import {
    AGE_STEP,
    DISTANCE_STEP,
    ELO_STEP,
    EPlatform,
    ERoutes,
    MAX_AGE,
    MAX_DISTANCE,
    MAX_ELO,
    MIN_AGE,
    MIN_DISTANCE,
    STORAGE_AUTO_SWIPE,
    STORAGE_FILTERS
} from "@models/general";
import {EDistanceUnits, EGender, ESexualOrientation, IDoubleRange, IUser, IUserFilters} from "@models/user";
import {kilometersToMiles, translateGender, translateSexualOrientation} from "@helpers/misc";
import {EloService} from "@services/elo-service";
import {Capacitor} from "@capacitor/core";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {Subject} from "rxjs";
import {PurchasePremiumComponent} from "@shared/components/purchase-premium/purchase-premium.component";
import {ModalService} from "@services/modal-service";
import {CustomUnitPipe} from "@shared/pipes/custom-currency.pipe";
import {EUsersFiltersAdmin} from "@models/admin";
import {Router} from "@angular/router";
import {FiltersService} from "@services/filters.service";


@Component({
    selector: 'app-filter-users',
    templateUrl: './filter-users.component.html',
    styleUrls: ['./filter-users.component.scss'],
})
export class FilterUsersComponent implements OnInit, OnDestroy {


    currentRoute: ERoutes = ERoutes.SPARK;

    selectedFilterBy = EUsersFiltersAdmin.reported;
    selectedGender = EGender.WOMAN;
    selectedSexualOrientation = ESexualOrientation.STRAIGHT;
    selectedDistanceUnit = EDistanceUnits.km;
    ageRange: IDoubleRange = {lower: MIN_AGE, upper: MAX_AGE};
    eloRange: IDoubleRange = {lower: 0, upper: MAX_ELO};
    kmRadiusValue = MAX_DISTANCE;

    ERoutes = ERoutes;
    EGender = EGender;
    EUsersFiltersAdmin = EUsersFiltersAdmin;
    ESexualOrientation = ESexualOrientation;
    EPlatform = EPlatform;

    AGE_STEP = AGE_STEP;
    ELO_STEP = ELO_STEP;
    MIN_AGE = MIN_AGE;
    MAX_AGE = MAX_AGE;
    MIN_DISTANCE = MIN_DISTANCE;
    MAX_DISTANCE = MAX_DISTANCE;
    DISTANCE_STEP = DISTANCE_STEP;
    MAX_ELO = MAX_ELO;
    autoSwipe = true;
    currentUser?: IUser;
    platform = EPlatform.ios;
    genderStorageKey!: STORAGE_FILTERS;
    filterByStorageKey!: STORAGE_FILTERS;
    sexualOrientationStorageKey!: STORAGE_FILTERS;
    kmRadiusStorageKey!: STORAGE_FILTERS;
    ageRangeStorageKey!: STORAGE_FILTERS;
    eloRangeStorageKey!: STORAGE_FILTERS;
    private subscription = new Subject<void>();

    constructor(
        private eloService: EloService,
        private filtersService: FiltersService,
        private usersService: UsersService,
        private router: Router,
        private customUnitPipe: CustomUnitPipe,
        private modalService: ModalService,
        public modalController: ModalController) {
    }

    ngOnInit() {
        this.getCurrentUser();
        this.platform = Capacitor.getPlatform() as EPlatform;
        const url = this.router.url;
        this.currentRoute = url.replace(/\//g, '') as ERoutes;
        this.initValues();
        this.getAutoSwipe();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    closeModal(data?: IUserFilters) {
        this.modalController.dismiss(data);
    }

    getDistance(distanceKm: number): string {
        let unit = 'Km';
        let value = distanceKm.toString();
        if (this.selectedDistanceUnit === EDistanceUnits.mi) {
            unit = 'Mi';
            value = kilometersToMiles(distanceKm).toString();
        }

        if (distanceKm === MAX_DISTANCE) {
            value = `${value}+`
        }

        return `${value} ${unit}`
    }

    async onAutoSwipeChange() {
        if (!this.autoSwipe) {
            await Preferences.set({
                key: STORAGE_AUTO_SWIPE,
                value: "false",
            });
        } else {
            await Preferences.remove({
                key: STORAGE_AUTO_SWIPE
            });
        }
        this.eloService.setAutoSwiper(this.autoSwipe)
    }

    async submit() {
        console.log(this.ageRange);
        console.log(this.kmRadiusValue);
        await this.setStorageValue(this.genderStorageKey, this.selectedGender);
        await this.setStorageValue(this.sexualOrientationStorageKey, this.selectedSexualOrientation);
        await this.setStorageValue(this.ageRangeStorageKey, this.ageRange);
        await this.setStorageValue(this.eloRangeStorageKey, this.eloRange);
        await this.setStorageValue(this.kmRadiusStorageKey, this.kmRadiusValue);
        await this.setStorageValue(this.filterByStorageKey, this.selectedFilterBy);
        // we get parameters from the storage
        this.closeModal({
            gender: this.selectedGender
        })
    }

    translateSexualOrientation(orientation?: ESexualOrientation): string {
        return translateSexualOrientation(orientation);
    }

    translateGenderCall(gender?: EGender): string {
        return translateGender(gender);
    }

    async openPurchasePremium() {
        const modal = await this.modalController.create({
            component: PurchasePremiumComponent as any,
            componentProps: {
                user: this.currentUser
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            const data = res?.data;
            console.log(res);
            if (this.currentUser && data) {
                this.currentUser.isPremium = true;
            }
        })
    }

    async setStorageValue(key: STORAGE_FILTERS, value: any) {
        await this.filtersService.setStorageValue(key, value)
    }

    private initValues() {
        this.selectedDistanceUnit = this.filtersService.allFilters.selectedDistanceUnit;

        if (this.currentRoute === ERoutes.ELO) {
            this.genderStorageKey = STORAGE_FILTERS.STORAGE_GENDER_RANKING;
            this.sexualOrientationStorageKey = STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_RANKING;
            this.kmRadiusStorageKey = STORAGE_FILTERS.STORAGE_KM_RADIUS_RANKING;
            this.ageRangeStorageKey = STORAGE_FILTERS.STORAGE_AGE_RANGE_RANKING;
            this.eloRangeStorageKey = STORAGE_FILTERS.STORAGE_ELO_RANGE_RANKING;

            this.selectedGender = this.filtersService.allFilters.selectedGenderRanking;
            this.selectedSexualOrientation = this.filtersService.allFilters.selectedSexualOrientationRanking;
            this.ageRange = this.filtersService.allFilters.ageRangeRanking;
            this.eloRange = this.filtersService.allFilters.eloRangeRanking;
            this.kmRadiusValue = this.filtersService.allFilters.kmRadiusValueRanking;
        } else if (this.currentRoute === ERoutes.SPARK0) {
            this.genderStorageKey = STORAGE_FILTERS.STORAGE_GENDER_SPARKLES;
            this.sexualOrientationStorageKey = STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_SPARKLES;
            this.kmRadiusStorageKey = STORAGE_FILTERS.STORAGE_KM_RADIUS_SPARKLES;
            this.ageRangeStorageKey = STORAGE_FILTERS.STORAGE_AGE_RANGE_SPARKLES;

            this.selectedGender = this.filtersService.allFilters.selectedGenderSparkles;
            this.selectedSexualOrientation = this.filtersService.allFilters.selectedSexualOrientationSparkles;
            this.ageRange = this.filtersService.allFilters.ageRangeSparkles;
            this.kmRadiusValue = this.filtersService.allFilters.kmRadiusValueSparkles;
        } else if (this.currentRoute === ERoutes.SPARK) {
            this.genderStorageKey = STORAGE_FILTERS.STORAGE_GENDER_VOTE;
            this.kmRadiusStorageKey = STORAGE_FILTERS.STORAGE_KM_RADIUS_VOTE;

            this.selectedGender = this.filtersService.allFilters.selectedGenderVote;
            this.kmRadiusValue = this.filtersService.allFilters.kmRadiusValueVote;
        } else if (this.currentRoute?.includes(ERoutes.ADMIN_REPORTS)) {
            this.genderStorageKey = STORAGE_FILTERS.STORAGE_GENDER_ADMIN;
            this.filterByStorageKey = STORAGE_FILTERS.STORAGE_FILTER_BY_ADMIN;
            this.sexualOrientationStorageKey = STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_ADMIN;

            this.selectedGender = this.filtersService.allFilters.selectedGenderAdmin;
            this.selectedSexualOrientation = this.filtersService.allFilters.selectedSexualOrientationAdmin;
            this.selectedFilterBy = this.filtersService.allFilters.selectedFilterByAdmin;
        }
    }

    private async getAutoSwipe() {
        let value = (await Preferences.get({key: STORAGE_AUTO_SWIPE}))?.value;
        this.autoSwipe = !value;
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(async data => {
            this.currentUser = data;
            this.initValues();
        });
    }

}
