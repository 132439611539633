import {Capacitor} from "@capacitor/core";
import {EPlatform} from "@models/general";
import {TranslateService} from "@ngx-translate/core";
import {ILatLong} from "@models/geo";
import {Geolocation} from "@capacitor/geolocation";
import {isIOS} from "@helpers/misc";

export async function getUserLocation(translate: TranslateService): Promise<ILatLong | undefined> {
    const platform = Capacitor.getPlatform();
    if (platform === EPlatform.web) {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(successGeolocationCallback(position)),
                    (error) => reject(errorGeolocationCallback(error, translate)),
                    {enableHighAccuracy: true}
                );
            } else {
                const message = translate.instant("geolocationNotSupported");
                reject(alert(message));
            }
        });
    } else {
        return await allowLocation() as any;
    }

}


export async function allowLocation() {
    try {
        const res = await Geolocation.checkPermissions();
        console.log(res);
        if (res?.location !== 'granted') {
            const res2 = await Geolocation.requestPermissions();
            console.log(res2);
        } else {
            const coordinates = await Geolocation.getCurrentPosition();
            console.log('Current position:', coordinates);
            const longitude = coordinates?.coords?.longitude;
            const latitude = coordinates?.coords?.latitude;
            return {
                latitude,
                longitude
            }
        }
    } catch (e: any) {
        console.error(e);
    }

    return;
}

export function successGeolocationCallback(position: GeolocationPosition) {
    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;

    console.log(`Your location is: Latitude ${latitude}, Longitude ${longitude}`);

    return {
        latitude,
        longitude
    }
}

export function errorGeolocationCallback(error: GeolocationPositionError, translate: TranslateService) {
    let message = error?.message || '';
    switch (error?.code) {
        case error.PERMISSION_DENIED:
            message = translate.instant("geolocationDenied");
            if (isIOS()) {
                message = translate.instant("geolocationDeniedIos");
            }
            alert(message);
            break;
        case error.POSITION_UNAVAILABLE:
            if (!message) {
                message = translate.instant("geolocationUnavailable");
            }
            alert(message);
            break;
        case error.TIMEOUT:
            if (!message) {
                message = translate.instant("geolocationTimeout");
            }
            alert(message);
            break;
        default:
            if (!message) {
                message = translate.instant("geolocationError");
            }
            alert(message);
    }
}

