import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoadingToastAlertProvider} from '@services/loadingToastAlert';
import {PicturesService} from '@services/pictures-service';
import {Subject} from 'rxjs';
import {UsersService} from "@services/users-service";
import {Platform} from "@ionic/angular";
import {Camera, CameraDirection, CameraResultType} from "@capacitor/camera";
import {STORAGE_BASE} from "@models/general";


@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnChanges, OnInit, OnDestroy {

    @Input()
    userImages: string[] = [];

    images: string[] = [];
    index = 0;
    private subscription = new Subject<void>();

    constructor(
        private translate: TranslateService,
        private platform: Platform,
        private picturesService: PicturesService,
        private usersService: UsersService,
        private loadingToastAlertProvider: LoadingToastAlertProvider) {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        // @ts-ignore
        const userImages = changes?.userImages?.currentValue || [];
        // @ts-ignore
        if (userImages !== changes?.userImages?.previousValue) {
            console.log(userImages);
            this.images = [];
            for (const u of userImages) {
                const url = u.replace(STORAGE_BASE, '');
                this.images.push(url);
            }
            this.initBlankImages();
        }
    }

    ngOnInit() {
        this.initBlankImages();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    getStorageUrl(id: string) {
        return STORAGE_BASE + id;
    }

    async deletePicture(event: any, index: number, url: string) {
        event?.stopPropagation();
        await this.loadingToastAlertProvider.presentLoadingWithOptions("deletingPicture");
        try {
            await this.picturesService.deleteImageCall(encodeURIComponent(url));
        } catch (error) {
            console.error(error);
            this.loadingToastAlertProvider.displayError(error);
        }
        this.loadingToastAlertProvider.dismissLoading();
    }

    async cameraOrLibraryPopup(index: number) {
        this.index = index;
        const permissionsStatus = await Camera.checkPermissions();
        if (permissionsStatus?.camera === 'denied' || permissionsStatus?.photos === 'denied') {
            await Camera.requestPermissions({permissions: ['camera', 'photos']})
        }

        // Camera.pickImages({
        //     limit: 1,
        //     height: 500,
        //     quality: 50
        // }).then(async (data: any) => {
        //     if (!data?.photos?.length) {
        //         return;
        //     }
        //     const image = data.photos[0];
        //     let base64String = await this.blobToBase64(image.webPath);
        //     await this.uploadBase64(base64String);
        // }).catch((err: any) => {
        //     console.error('cameraOrLibraryPopup', err);
        //     this.loadingToastAlertProvider.displayError(err);
        // });

        Camera.getPhoto({
            quality: 70,
            height: 500,
            allowEditing: true,
            saveToGallery: false,
            correctOrientation: true,
            direction: CameraDirection.Front,
            resultType: CameraResultType.Base64
        }).then(async (image: any) => {
            let base64String = image?.base64String;
            await this.uploadBase64(base64String);
        }).catch((err: any) => {
            console.error('cameraOrLibraryPopup', err);
        });
    }

    private async uploadBase64(base64String: string) {
        if (base64String) {
            base64String = base64String.substring(base64String.indexOf(',') + 1);
            await this.loadingToastAlertProvider.presentLoadingWithOptions("uploadingPicture");
            try {
                const response = await this.picturesService.uploadImageCall(base64String) as any;
                console.log('Image successfully sent to the backend:', response);
                const url = response?.data?.url;
                console.log(url);
            } catch (error) {
                console.error('Error sending image to the backend:', error);
                this.loadingToastAlertProvider.displayError(error);
            }
            this.loadingToastAlertProvider.dismissLoading();
        }
    }

// Function to convert Blob URL to base64
    private async blobToBase64(blobUrl: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function (error) {
                reject(error);
            };
            xhr.open('GET', blobUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    private initBlankImages() {
        while (this.images?.length < 6) {
            this.images.push('');
        }
    }
}

