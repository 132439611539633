import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {IonicSlides, ModalController} from "@ionic/angular";

@Component({
    selector: 'app-modal-pwa',
    templateUrl: './modal-pwa.component.html',
    styleUrls: ['./modal-pwa.component.scss'],
})
export class ModalPWAComponent implements OnInit {

    @ViewChild('swiper', {static: false})
    swiper?: any;

    tutorial: any[] = [];
    isInit = true;
    swiperModules = [IonicSlides];

    constructor(
        private cdr: ChangeDetectorRef,
        private modalController: ModalController) {
    }

    ngOnInit() {
        this.initTutorial();
    }

    startTutorial() {
        this.isInit = false;
        this.cdr.detectChanges();
        if (this.swiper?.nativeElement?.swiper) {
            this.swiper.nativeElement.swiper.update();
        }
    }

    close() {
        this.modalController.dismiss();
    }

    private isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator?.userAgent) || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1);
    }

    private initTutorial() {
        this.tutorial = [{
            img: 'assets/pwa/android/1.png',
            label: 'clickOn3Dots'
        }, {
            img: 'assets/pwa/android/2.png',
            label: 'findInstallApp'
        }, {
            img: 'assets/pwa/android/3.png',
            label: 'clickOnInstallApp'
        }, {
            img: 'assets/pwa/android/4.png',
            label: 'youFindIconHomeScreen'
        }, {
            img: 'assets/pwa/android/5.png',
            label: 'enjoyPWA'
        }]

        if (this.isIOS()) {
            this.tutorial = [{
                img: 'assets/pwa/ios/1.png',
                label: 'clickOnShare'
            }, {
                img: 'assets/pwa/ios/2.png',
                label: 'findAddToHomeScreen'
            }, {
                img: 'assets/pwa/ios/3.png',
                label: 'clickOnAdd'
            }, {
                img: 'assets/pwa/ios/4.png',
                label: 'youFindIconHomeScreen'
            }, {
                img: 'assets/pwa/ios/5.png',
                label: 'enjoyPWA'
            }]
        }
    }

}
