import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EMatchKey, IMatch} from "@models/matches";
import {ModalController} from "@ionic/angular";
import {UsersService} from "@services/users-service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {takeUntil} from "rxjs/operators";
import {IUser} from "@models/user";
import {Subject} from "rxjs";
import {MessagesComponent} from "@shared/components/messages/messages.component";
import {ModalService} from "@services/modal-service";
import {getValueFromMatch} from "@helpers/elo";

@Component({
    selector: 'app-match-modal',
    templateUrl: './match-modal.component.html',
    styleUrls: ['./match-modal.component.scss'],
})
export class MatchModalComponent implements OnInit, OnDestroy {

    @Input()
    match!: IMatch;

    EMatchKey = EMatchKey;
    currentUser?: IUser;
    currentUserPicture = '';
    private subscription = new Subject<void>();

    constructor(
        private modalController: ModalController,
        private modalService: ModalService,
        private usersService: UsersService,
        private loadingToastAlertProvider: LoadingToastAlertProvider,) {
    }

    ngOnInit() {
        if (!this.match) {
            this.close();
            return;
        }
        this.getCurrentUser();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    close(data?: any) {
        this.modalController.dismiss(data);
    }


    getValue(key: EMatchKey, uid?: string): string {
        return getValueFromMatch(key, this.match, uid)
    }

    async sendMessage() {
        this.close();
        const modal = await this.modalController.create({
            component: MessagesComponent as any,
            componentProps: {
                match: this.match
            },
            backdropDismiss: false
        });
        await modal.present();
        this.modalService.openingModal();
    }


    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
            if (data?.images?.length) {
                this.currentUserPicture = data.images[0];
            }
        });
    }

}
