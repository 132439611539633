import {EStripeProduct, EStripeUrl} from "../models/stripe";

export function getStripeUrl(product: EStripeProduct,
                             client_reference_id: string,
                             prefilled_email?: string,
                             prefilled_promo_code?: string
): string {
    let url = '';
    switch (product) {
        case EStripeProduct.BOOST_LEVEL1:
            url = `${EStripeUrl.BOOST_LEVEL1_URL}`;
            break;
        case EStripeProduct.BOOST_LEVEL2:
            url = `${EStripeUrl.BOOST_LEVEL2_URL}`;
            break;
        case EStripeProduct.BOOST_LEVEL3:
            url = `${EStripeUrl.BOOST_LEVEL3_URL}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL1:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL1_URL}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL2:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL2_URL}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL3:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL3_URL}`;
            break;
        case EStripeProduct.PREMIUM:
            url = `${EStripeUrl.PREMIUM_URL}`;
            break;
        case EStripeProduct.BOOST_LEVEL1_TEST:
            url = `${EStripeUrl.BOOST_LEVEL1_URL_TEST}`;
            break;
        case EStripeProduct.BOOST_LEVEL2_TEST:
            url = `${EStripeUrl.BOOST_LEVEL2_URL_TEST}`;
            break;
        case EStripeProduct.BOOST_LEVEL3_TEST:
            url = `${EStripeUrl.BOOST_LEVEL3_URL_TEST}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL1_TEST:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL1_URL_TEST}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL2_TEST:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL2_URL_TEST}`;
            break;
        case EStripeProduct.INSTANT_MATCH_LEVEL3_TEST:
            url = `${EStripeUrl.INSTANT_MATCH_LEVEL3_URL_TEST}`;
            break;
        case EStripeProduct.PREMIUM_TEST:
            url = `${EStripeUrl.PREMIUM_URL_TEST}`;
            break;
    }

    if (client_reference_id) {
        url += `?client_reference_id=${client_reference_id}`;
    }
    if (prefilled_email) {
        url += `&prefilled_email=${prefilled_email}`;
    }
    if (prefilled_promo_code) {
        url += `&prefilled_promo_code=${prefilled_promo_code}`;
    }

    return url;
}

