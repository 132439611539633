import {Injectable} from '@angular/core';
import 'firebase/messaging';
import {messaging} from "@app/app.module";
import {PUBLIC_VAPID_KEY, TOAST_POSITION} from "@models/general";
import {getToken, isSupported, onMessage} from "firebase/messaging";
import {UsersService} from "@services/users-service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {ENotificationType} from "@models/notifications";
import {isMobileWeb} from "@helpers/misc";

@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingService {

    messageModalOpen = false;

    constructor(
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private usersService: UsersService
    ) {
    }

    // requestPermission() {
    //     Notification.requestPermission()
    //         .then((permission) => {
    //             if (permission === 'granted') {
    //                 console.log('Notification permission granted.');
    //                 this.getUserToken();
    //                 this.receiveMessage();
    //             }
    //
    //         })
    //         .catch(err => console.error('Permission denied', err));
    // }

    async getUserToken() {
        const supported = await isSupported();
        console.log(supported);
        if (!supported) {
            return;
        }
        const isMobile = await isMobileWeb();
        if (!isMobile) {
            return
        }
        // Add the public key generated from the console here.
        getToken(messaging, {vapidKey: PUBLIC_VAPID_KEY})
            .then(async (currentToken) => {
                console.log(currentToken);
                if (currentToken) {
                    this.usersService.notificationToken = currentToken;
                    this.usersService.updateUserTokenCall(currentToken);
                    this.receiveMessage();
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });
    }

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.

    receiveMessage() {
        console.log(messaging);

        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            const data = payload?.data;
            const type = data?.type as ENotificationType;
            if (type === ENotificationType.message && this.messageModalOpen) {
                return;
            }
            if (data?.body) {
                this.loadingToastAlertProvider.presentToastWithOptions(
                    data.body, false, TOAST_POSITION.TOP
                )
            }
        });
    }
}
