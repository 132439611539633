import {EDistanceUnits, EGender, EHeightUnits, ESexualOrientation} from "../models/user";
import {
    EPlatform,
    MAX_AGE,
    MAX_DISTANCE,
    MAX_ELO,
    MAX_HEIGHT_CM,
    MAX_IN_QUERY,
    MIN_AGE,
    MIN_DISTANCE,
    MIN_HEIGHT_CM,
    STORAGE_DARK_MODE
} from "../models/general";
import {Preferences} from "@capacitor/preferences";
import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";
import {EUsersFiltersAdmin} from "../models/admin";

export function deepClone(value: any) {
    if (!value) {
        return value;
    }
    return JSON.parse(JSON.stringify(value));
}

export function capitalizeFirstLetter(text?: string): string {
    if (!text) {
        return '';
    }
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * copyToClipBoard
 */
export function copyToClipboard(text: string) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log("Texte copié dans le presse-papiers : " + text);
            })
            .catch(err => {
                console.error("Erreur lors de la copie dans le presse-papiers : ", err);
            });
    } else {
        // Gestion de secours pour les navigateurs non compatibles avec l'API Clipboard
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}

export function firestoreDocsToArray(docs: any[]): any[] {
    const returnArr: any[] = [];
    let index = 0;
    docs.forEach(d => {
        if (d.exists) {
            const data = d.data();
            // data.ref = d.ref;
            data.documentId = d.id;
            data.index = index;
            returnArr.push(data);
            index++;
        }
    })

    return returnArr;
}


export function guid(): string {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function getRandomMinMax(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
}

export function isEmpty(obj = {}) {
    return Object.keys(obj).length === 0;
}

export function isValidCoordinates(latitude: any, longitude: any): boolean {
    // Vérifiez si la latitude est dans la plage valide (-90 à 90 degrés)
    // et si la longitude est dans la plage valide (-180 à 180 degrés)
    if (
        latitude && longitude &&
        typeof latitude === 'number' &&
        typeof longitude === 'number' &&
        latitude >= -90 &&
        latitude <= 90 &&
        longitude >= -180 &&
        longitude <= 180
    ) {
        return true; // Les coordonnées sont valides
    }

    return false; // Les coordonnées ne sont pas valides
}

export function getAge(birthday?: string): number {
    if (!birthday) {
        return 0;
    }
    const birthDate = new Date(birthday);
    const currentDate = new Date();

    // Calculate the age by subtracting birth year from the current year
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    const hasBirthdayOccurred =
        currentDate.getMonth() > birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() >= birthDate.getDate());

    // If the birthday hasn't occurred yet this year, subtract 1 from the age
    if (!hasBirthdayOccurred) {
        age--;
    }

    return age;
}

export function isImageUrl(url: string): boolean {
    if (!url) {
        return false;
    }
    // Extract the file extension from the URL
    let fileExtension: any = url.split('.')?.pop()?.split('?');

    if (fileExtension?.length) {
        fileExtension = fileExtension[0].toLowerCase();
    } else {
        return false;
    }

    // List of common image file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

    // Check if the file extension indicates an image
    return imageExtensions.includes(fileExtension);
}

// '2022-12-31'
export function getRandomTimestamp(start: string | number, end: string | number): number {
    // Convert dates to milliseconds
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    // Generate a random number of milliseconds between start and end dates
    const randomMillis = Math.floor(Math.random() * (endMillis - startMillis + 1)) + startMillis;

    return randomMillis;
}

// Function to convert centimeters to feet and inches
export function cmToFeetAndInches(cm?: number) {
    cm = cm || 0;
    const inches = cm / 2.54; // Convert centimeters to inches
    const feet = Math.floor(inches / 12); // Calculate whole feet
    const remainingInches = inches % 12; // Calculate remaining inches

    return {feet: Math.round(feet), inches: Math.round(remainingInches)};
}

// Function to convert feet and inches to centimeters
export function feetAndInchesToCm(feet?: number, inches?: number): number {
    feet = feet || 0;
    inches = inches || 0;
    const totalInches = feet * 12 + inches; // Convert feet and inches to total inches
    const cm = totalInches * 2.54; // Convert total inches to centimeters

    return Math.round(cm);
}

export function isValidDate(dateString = ''): boolean {
    // Attempt to create a Date object from the given string
    const date = new Date(dateString);

    // Check if the created date is a valid date and the input string doesn't contain invalid characters
    return !isNaN(date.getTime()) && dateString.trim() !== '';
}

export function generateShortWords(numWords: number): string[] {
    const vowels = 'aeio';
    const consonants = 'bdflmnprtvz';
    const words: string[] = [];

    const getRandomChar = (characters: string) => characters[Math.floor(Math.random() * characters.length)];

    const generateWord = () => {
        let word = '';

        for (let i = 0; i < 5; i++) {
            if (i % 2 === 0) {
                word += getRandomChar(consonants);
            } else {
                word += getRandomChar(vowels);
            }
        }

        return word;
    };

    for (let i = 0; i < numWords; i++) {
        words.push(generateWord());
    }

    return words;
}

export function getBirthdayTimestampFromAge(age: number): number {

    const currentDate = new Date();
    const birthYear = currentDate.getUTCFullYear() - age;

    // Set the birthdate to January 1st
    const birthdayUTC = new Date(Date.UTC(birthYear, 0, 1));

    // Get the timestamp in milliseconds
    const timestamp = birthdayUTC.getTime();

    return timestamp;
}

export function isValidGender(gender: any): boolean {
    return gender === EGender.MAN || gender === EGender.WOMAN
        || gender === EGender.OTHERS || gender === EGender.EVERYONE
}

export function isValidSexualOrientation(sexualOrientation: any): boolean {
    return sexualOrientation === ESexualOrientation.STRAIGHT || sexualOrientation === ESexualOrientation.GAY
        || sexualOrientation === ESexualOrientation.LESBIAN || sexualOrientation === ESexualOrientation.BISEXUAL
        || sexualOrientation === ESexualOrientation.OTHERS || sexualOrientation === ESexualOrientation.EVERYONE
}

export function isValidFilterBy(filterBy: any): boolean {
    return filterBy === EUsersFiltersAdmin.all || filterBy === EUsersFiltersAdmin.reported
        || filterBy === EUsersFiltersAdmin.banned;
}

export function isValidAgeRange(age: any): boolean {
    return age && !isNaN(age?.lower) && !isNaN(age?.upper) && age?.lower >= MIN_AGE && age?.lower <= MAX_AGE;
}

export function isValidEloRange(elo: any): boolean {
    return elo && !isNaN(elo?.lower) && !isNaN(elo?.upper) && elo?.lower >= 0 && elo?.lower <= MAX_ELO;
}

export function isValidKmRadius(value: any): boolean {
    value = +value;
    return value && !isNaN(value) && value >= MIN_DISTANCE && value <= MAX_DISTANCE;
}

export function isValidDistanceUnit(unit: any): boolean {
    return unit === EDistanceUnits.km || unit === EDistanceUnits.mi;
}

export function isValidHeightUnit(unit: any): boolean {
    return unit === EHeightUnits.centimeters || unit === EHeightUnits.feetInches;
}

export function kilometersToMiles(kilometers: number): number {
    if (!kilometers) {
        return 0;
    }
    // Conversion factor: 1 kilometer = 0.621371 miles
    const conversionFactor = 0.621371;
    const miles = Math.floor(kilometers * conversionFactor);
    return miles ? miles : 1;
}

export function translateGender(gender?: EGender): string {
    switch (gender) {
        case EGender.MAN:
            return 'man';
        case EGender.WOMAN:
            return 'woman';
        case EGender.OTHERS:
            return 'others';
        case EGender.EVERYONE:
            return 'everyone';
        default:
            return 'unknown'
    }
}

export function translateSexualOrientation(orientation?: ESexualOrientation): string {
    switch (orientation) {
        case ESexualOrientation.STRAIGHT:
            return 'straight';
        case ESexualOrientation.GAY:
            return 'gay';
        case ESexualOrientation.LESBIAN:
            return 'lesbian';
        case ESexualOrientation.BISEXUAL:
            return 'bisexual';
        case ESexualOrientation.OTHERS:
            return 'others';
        case ESexualOrientation.EVERYONE:
            return 'everyone';
        default:
            return 'unknown'
    }
}

export function isValidHeightCm(heightCm?: number): boolean {
    return !!(heightCm && !isNaN(heightCm) && heightCm <= MAX_HEIGHT_CM && heightCm >= MIN_HEIGHT_CM);
}

export function isUrlEncoded(url: string): boolean {
    // Decode the URL and compare with the original
    const decodedUrl = decodeURIComponent(url);
    return url !== decodedUrl;
}

export function roundToNearestHundred(input?: number): number {
    if (!input) {
        return 0;
    }
    return Math.ceil(input / 100) * 100;
}

// export function roundUpToNearestMultipleOfFour(input: number): number {
//     const remainder = input % 4;
//     return remainder === 0 ? input : input + (4 - remainder);
// }


export function roundUpToNearestFourYears(timestamp?: number): number {
    if (!timestamp) {
        return 0;
    }
    const millisecondsInYear = 365.25 * 24 * 60 * 60 * 1000;
    const date1974 = new Date(Date.UTC(1924, 0, 1));
    const timestamp1974 = date1974.getTime();
    const yearsSince1924 = (timestamp - timestamp1974) / millisecondsInYear;
    const roundedYears = Math.ceil(yearsSince1924 / 4) * 4;
    const roundedTimestamp = timestamp1974 + roundedYears * millisecondsInYear;

    return roundedTimestamp;
}

export function getEloFilters(minElo: number, maxElo: number): number[] {
    const eloFilters: number[] = [];
    const minEloHundred = roundToNearestHundred(minElo);
    const maxEloHundred = roundToNearestHundred(maxElo);
    let count = minEloHundred;
    do {
        eloFilters.push(count);
        count += 100;
    } while (count <= maxEloHundred)

    const filtersLength = eloFilters?.length || 0;
    const diff = filtersLength - MAX_IN_QUERY;
    if (diff > 0) {
        eloFilters.splice(0, diff);
    }

    return eloFilters;
}

export function getAgeFilters(minAge: number, maxAge: number): number[] {
    const minAgeTimestamp = getBirthdayTimestampFromAge(minAge);
    const maxAgeTimestamp = getBirthdayTimestampFromAge(maxAge);
    const minAgeRoundedTimestamp = roundUpToNearestFourYears(minAgeTimestamp);
    const maxAgeRoundedTimestamp = roundUpToNearestFourYears(maxAgeTimestamp);
    const ageFilters: number[] = [];
    let timestamp = maxAgeRoundedTimestamp;
    do {
        const date = new Date(timestamp);
        // Add 4 years to the current year
        date.setUTCFullYear(date.getUTCFullYear() + 4);
        // Set the month and day to January 1st
        date.setUTCMonth(0);
        date.setUTCDate(1);
        timestamp = date.getTime();
        ageFilters.push(timestamp)
    } while (timestamp <= minAgeRoundedTimestamp)


    const filtersLength = ageFilters?.length || 0;
    const diff = filtersLength - MAX_IN_QUERY;
    if (diff > 0) {
        ageFilters.splice(-diff);
    }

    return ageFilters;
}

export function isTwitterProvider(providerData: any = []): boolean {
    providerData = providerData || [];
    const isTwitter = providerData.find((p: any) => p.providerId?.toLowerCase().includes('twitter'));
    return !!isTwitter;
}

export async function onDarkModeChange(darkMode = false) {

    if (darkMode) {
        document.body.classList.toggle('dark', true);
        await Preferences.set({
            key: STORAGE_DARK_MODE,
            value: 'true',
        });
    } else {
        document.body.classList.toggle('dark', false);
        await Preferences.remove({
            key: STORAGE_DARK_MODE,
        });
    }
}

export function removeDuplicates(array: any[], key: string[]): any[] {
    const unique = array.reduce((acc, obj) => {
        const found = acc.find((item: any) => key.every((k: any) => item[k] === obj[k]));
        if (!found) {
            acc.push(obj);
        }
        return acc;
    }, []);
    return unique;
}

export function generateRandomString(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < 28; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function shuffleArray<T>(array: T[]): T[] {
    // Copy the array to avoid modifying the original array
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        // Generate a random index from 0 to i
        const randomIndex = Math.floor(Math.random() * (i + 1));

        // Swap elements at i and the random index
        [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
    }

    return shuffledArray;
}

export function isStandalone(): boolean {
    const isStandalone = !!window?.matchMedia('(display-mode: standalone)')?.matches;
    return Capacitor.getPlatform() === EPlatform.web && !isStandalone && navigator?.maxTouchPoints > 1
}

export async function isMobileWeb(): Promise<boolean> {
    const info = await Device.getInfo();
    return info.platform === EPlatform.web && (info.operatingSystem === EPlatform.ios || info.operatingSystem === EPlatform.android);
}

export function isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}
