import {IUser} from "./user";

export interface IEloVotePayload {
    winnerId: string;
    loserId: string;
    winnerImg: string
}

export interface IEloNewRating {
    newRatingA: number;
    newRatingB: number;
}

export interface IEloVote {
    created: number;
    userId: string;
    documentId?: string;
    userName: string; // The name of the user who vote
    userPicture: string; // The picture of the user who vote
    winnerPicture: string;
    loserPicture: string;
    winnerName: string;
    loserName: string;
    winnerBirthday: string;
    loserBirthday: string;
    playersIds: string[]; // pour pouvoir faire une query plus facilement
    winnerId: string;
    loserId: string;
    previousWinnerElo: number;
    newWinnerElo: number;
    previousLoserElo: number;
    newLoserElo: number;
}

export enum EResultElo {
    win = 1,
    lose = 0,
    draw = 0.5
}

export interface IVoteWinner {
    winner: IUser,
    loser: IUser,
    img: string
}

export interface IGameResponse {
    total: number,
    battles: IUser[][]
}

export interface IVoteUser {
    winPoints: number,
    losePoints: number
}

export enum EPromiseId {
    waitingMatchs = "waitingMatchs",
    byUpdated = "byUpdated",
    byGeo = "byGeo",
    byGenderByBoost = "byGenderByBoost",
    byGenderByLastVoteTimestamp = "byGenderByLastVoteTimestamp",
    byGenderByVotesReceived = "byGenderByVotesReceived",
    byEloFilterByLastVoteTimestampLow = "byEloFilterByLastVoteTimestampLow",
    byEloFilterByLastVoteTimestampHigh = "byEloFilterByLastVoteTimestampHigh",
}

export enum EPromiseVoteId {
    currentUser = "currentUser",
    winnerUser = "winnerUser",
    loserUser = "loserUser",
}


export interface IUsersFilter {
    lastDocId?: string,
    lastDocIdEloFilter?: string,
    lastDocIdAgeFilter?: string,
    kmRadius?: number,
    total?: number,
    results: IUser[]
}
