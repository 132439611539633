import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/general";

@Pipe({
    name: 'customUnit'
})
export class CustomUnitPipe implements PipeTransform {

    constructor(
        private translate: TranslateService
    ) {
    }

    transform(value: any, ...args: unknown[]): unknown {

        const local = this.translate.currentLang?.toLowerCase();
        let unit = '';
        if (args?.length) {
            const arg = args[0] as string;
            unit = arg?.toString();
        }

        if (local?.includes(ELang.fr)) {
            return new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(value) + ' ' + unit;
        } else {
            if (unit === '€' || unit === '$') {
                return unit + new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                }).format(value);
            } else {
                return new Intl.NumberFormat('en-US',
                    {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    }).format(value) + ' ' + unit;
            }
        }

        return value;

    }
}
