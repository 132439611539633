import {Component, OnDestroy, OnInit} from '@angular/core';
import {EMatchKey, IMatch} from "@models/matches";
import {IUser} from "@models/user";
import {UserDetailsComponent} from "@shared/components/user-details/user-details.component";
import {ModalController} from "@ionic/angular";
import {getValueFromMatch} from "@helpers/elo";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {Subject} from "rxjs";
import {deepClone} from "@helpers/misc";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {HistoryService} from "@services/history.service";
import {EHistoryType, EVoteType, IHistoryPayload} from "@models/history";
import {IEloVote} from "@models/elo";
import {PurchasePremiumComponent} from "@shared/components/purchase-premium/purchase-premium.component";
import {ModalService} from "@services/modal-service";
import {ERoutes} from "@models/general";
import {Router} from "@angular/router";
import {PurchaseConsumableComponent} from "@shared/components/purchase-consumable/purchase-consumable.component";
import {EPremiumType} from "@models/premium";

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {

    EVoteType = EVoteType;
    EPremiumType = EPremiumType;
    EHistoryType = EHistoryType;
    dataInit = false;
    isLoading = false;
    lastDocIdSent = '';
    lastDocIdReceived = '';
    historyType = EHistoryType.received;
    votesReceived: IEloVote[] = [];
    votesSent: IEloVote[] = [];
    votesDisplayed: IEloVote[] = [];
    currentUser?: IUser;
    private subscription = new Subject<void>();

    constructor(
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private modalController: ModalController,
        private modalService: ModalService,
        private router: Router,
        private usersService: UsersService,
        private historyService: HistoryService
    ) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.getCurrentUser();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    closeModal() {
        this.modalController.dismiss();
    }

    goToVote() {
        this.closeModal();
        this.router.navigate([ERoutes.SPARK], {replaceUrl: false});
    }

    getValueFromMatch(match: IMatch, key: EMatchKey): string {
        return getValueFromMatch(key, match, this.currentUser?.documentId)
    }

    async openPurchasePremium() {
        const modal = await this.modalController.create({
            component: PurchasePremiumComponent as any,
            componentProps: {
                user: this.currentUser
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            const data = res?.data;
            console.log(res);
            if (this.currentUser && data) {
                this.currentUser.isPremium = true;
                this.refreshContent();
            }
        })
    }


    typeChange() {
        if (this.historyType === EHistoryType.sent) {
            this.votesDisplayed = deepClone(this.votesSent);
        } else {
            this.votesDisplayed = deepClone(this.votesReceived);
        }
    }

    async getUserDetails(vote: IEloVote, type: EVoteType) {
        let user: IUser | undefined = undefined;
        if (type === EVoteType.win) {
            user = {
                documentId: vote.winnerId,
                images: [vote.winnerPicture],
                firstName: vote.winnerName
            } as IUser
        } else if (type === EVoteType.lose) {
            user = {
                documentId: vote.loserId,
                images: [vote.loserPicture],
                firstName: vote.loserName
            } as IUser
        } else if (type === EVoteType.by) {
            user = {
                documentId: vote.userId,
                images: [vote.userPicture],
                firstName: vote.userName
            } as IUser
        }
        const modal = await this.modalController.create({
            component: UserDetailsComponent as any,
            componentProps: {
                user
            }
        });
        await modal.present();
        this.modalService.openingModal();
    }


    displayInfiniteScroll(): boolean {
        if (!this.votesDisplayed?.length) {
            return false;
        }
        if (this.historyType === EHistoryType.sent) {
            return !!this.lastDocIdSent;
        }
        if (this.historyType === EHistoryType.received) {
            return !!this.lastDocIdReceived;
        }

        return false;
    }

    async openPurchaseConsumable(type: EPremiumType) {
        const modal = await this.modalController.create({
            component: PurchaseConsumableComponent as any,
            componentProps: {
                type
            }
        });
        await modal.present();
        this.modalService.openingModal();
    }

    refreshContent(ev?: any) {
        this.votesDisplayed = [];
        if (this.historyType === EHistoryType.sent) {
            this.votesSent = [];
            this.lastDocIdSent = '';
        } else if (this.historyType === EHistoryType.received) {
            this.votesReceived = [];
            this.lastDocIdReceived = '';
        }
        this.getHistoryByUserIdCall(this.historyType, ev);
    }

    async getHistoryByUserIdCall(historyType: EHistoryType, ev?: any) {
        if (historyType === EHistoryType.received && !this.currentUser?.isPremium) {
            ev?.target?.complete();
            this.isLoading = false;
            return;
        }
        try {
            let lastDocId = historyType === EHistoryType.sent ? this.lastDocIdSent : this.lastDocIdReceived;
            const payload: IHistoryPayload = {
                historyType: historyType,
                lastDocId
            }
            const res = await this.historyService.getHistoryByUserIdCall(payload);
            console.log(res);
            const history = res?.data?.result as IEloVote[] || [];
            lastDocId = res?.data?.lastDocId;
            if (!history?.length) {
                lastDocId = "";
            }
            if (historyType === EHistoryType.received) {
                this.lastDocIdReceived = lastDocId;
                this.votesReceived = this.votesReceived.concat(history);
            } else {
                this.lastDocIdSent = lastDocId;
                this.votesSent = this.votesSent.concat(history);
            }
            this.typeChange();
        } catch (e: any) {
            console.error(e);
            this.loadingToastAlertProvider.displayError(e);
        }
        ev?.target?.complete();
        this.isLoading = false;
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
            if (data && !this.dataInit) {
                this.dataInit = true;
                this.getHistoryByUserIdCall(EHistoryType.sent);
                if (data?.isPremium) {
                    this.getHistoryByUserIdCall(EHistoryType.received);
                }
            }
        });
    }

}

