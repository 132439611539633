<ion-header class="">
    <ion-toolbar class="ion-text-center" color="myprimary">
        <ion-title>
            {{"settings" | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <div (click)="closeModal()" class="rounded-icon prim">
                <ion-icon name="close"></ion-icon>
            </div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="">

    <ion-list class="ion-padding">

        <ion-item>
            <ion-label>{{"showMe" | translate}}</ion-label>
            <ion-select (ionChange)="genderChange()"
                        [(ngModel)]="selectedGender"
                        [placeholder]="'select' | translate">
                <ion-select-option
                        [value]="EGender.MAN">{{translateGenderCall(EGender.MAN) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.WOMAN">{{translateGenderCall(EGender.WOMAN) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.OTHERS">{{translateGenderCall(EGender.OTHERS) | translate}}</ion-select-option>
                <ion-select-option
                        [value]="EGender.EVERYONE">{{translateGenderCall(EGender.EVERYONE) | translate}}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item class="">
            <ion-label>{{"language" | translate}}</ion-label>
            <ion-text (click)="setLanguage()" slot="end" style="cursor: pointer">
                {{getLangLabel()}}
            </ion-text>
        </ion-item>
        <ion-item class="">
            <ion-label>{{"darkMode" | translate}}</ion-label>
            <ion-toggle (ionChange)="darkModeChange()"
                        [(ngModel)]="darkMode"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>

        <ion-item class="">
            <ion-label>{{"visible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="visible"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note>{{"visibleNote" | translate}}</ion-note>
        <ion-item class="">
            <ion-label>{{"eloVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="eloVisible"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>

        <ion-item *ngIf="currentUser?.isPremium" class="">
            <ion-label>{{"rankVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="rankVisible"
                        [disabled]="!visible || !currentUser?.isPremium"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <!--        <ion-note *ngIf="!currentUser?.isPremium" class="error">-->
        <!--            {{"onlyPremiumCanChangeThisOption" | translate}}-->
        <!--        </ion-note>-->
        <ion-note>{{"rankVisibleNote" | translate}}</ion-note>

        <ion-item class="">
            <ion-label>{{"ageVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="ageVisible"
                        [disabled]="!currentUser?.isPremium"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note *ngIf="!currentUser?.isPremium" class="error">
            {{"onlyPremiumCanChangeThisOption" | translate}}
        </ion-note>

        <ion-item *ngIf="platform !== EPlatform.ios" class="">
            <ion-label>{{"distanceVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="distanceVisible"
                        [disabled]="!currentUser?.isPremium"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note *ngIf="!currentUser?.isPremium" class="error">
            {{"onlyPremiumCanChangeThisOption" | translate}}
        </ion-note>

        <ion-item class="">
            <ion-label>{{"sexualOrientationVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="sexualOrientationVisible"
                        [disabled]="!currentUser?.isPremium"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note *ngIf="!currentUser?.isPremium" class="error">
            {{"onlyPremiumCanChangeThisOption" | translate}}
        </ion-note>

        <ion-item class="">
            <ion-label>{{"heightVisible" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="heightVisible"
                        [disabled]="!currentUser?.isPremium"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note *ngIf="!currentUser?.isPremium" class="error">
            {{"onlyPremiumCanChangeThisOption" | translate}}
        </ion-note>


        <ion-item *ngIf="platform !== EPlatform.ios">
            <ion-label>{{"distanceUnit" | translate}}</ion-label>
            <ion-segment (ionChange)="setStorageValue(STORAGE_FILTERS.STORAGE_DISTANCE_UNITS, selectedDistanceUnit)"
                         [(ngModel)]="selectedDistanceUnit"
                         color="myprimary"
                         mode="ios">
                <ion-segment-button [value]="EDistanceUnits.km">
                    {{"Km" | translate}}
                </ion-segment-button>
                <ion-segment-button [value]="EDistanceUnits.mi">
                    {{"Mi" | translate}}
                </ion-segment-button>
            </ion-segment>
        </ion-item>

        <ion-item class="column-item">
            <ion-label>{{"heightUnit" | translate}}</ion-label>

            <ion-segment (ionChange)="setStorageValue(STORAGE_FILTERS.STORAGE_HEIGHT_UNITS, selectedHeightUnit)"
                         [(ngModel)]="selectedHeightUnit"
                         color="myprimary"
                         mode="ios">
                <ion-segment-button [value]="EHeightUnits.centimeters">
                    {{"cm" | translate}}
                </ion-segment-button>
                <ion-segment-button [value]="EHeightUnits.feetInches">
                    {{"ft/in" | translate}}
                </ion-segment-button>
            </ion-segment>
        </ion-item>
    </ion-list>

    <ion-list class="ion-padding">
        <ion-list-header>
            <ion-label>
                {{"notifications" | translate}}
            </ion-label>
        </ion-list-header>
        <ion-item class="">
            <ion-label>{{"voteNotification" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="eloNotification"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-note>{{"voteNotificationNote" | translate}}</ion-note>
        <ion-item class="">
            <ion-label>{{"matchNotification" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="matchNotification"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-item class="">
            <ion-label>{{"likeNotification" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="likeNotification"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
        <ion-item class="">
            <ion-label>{{"messageNotification" | translate}}</ion-label>
            <ion-toggle (ionChange)="updateUserSettingsCall()"
                        [(ngModel)]="messageNotification"
                        color="myprimary"
                        mode="ios" slot="end"></ion-toggle>
        </ion-item>
    </ion-list>

    <ion-button (click)="logout()"
                class="logout"
                color="medium"
                expand="full" fill="clear"
                mode="ios">
        <ion-label slot="end">{{"logout" | translate}}</ion-label>
    </ion-button>

</ion-content>
