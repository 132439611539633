import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {REGION, STORAGE_LIKES_SENT} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {encryptChunk} from "@helpers/forge";
import {BehaviorSubject, Subject} from "rxjs";
import {ILikesPayload, IMatch} from "@models/matches";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {Router} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {ModalService} from "@services/modal-service";
import {Preferences} from "@capacitor/preferences";
import {MatchModalComponent} from "@shared/components/match-modal/match-modal.component";

@Injectable({
    providedIn: 'root'
})
export class MatchesService {

    // this is set from app.component
    uid = '';
    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);
    private _onMoveCard = new Subject<number>();
    private _onEndCard = new Subject<number>();
    private allMatches: IMatch[] = [];
    private _allMatches = new BehaviorSubject<IMatch[]>([]);
    private allLikesUsersIds: string[] = [];
    private _allLikesUsersIds = new BehaviorSubject<string[]>([]);
    private _allMatchsUsersIds = new BehaviorSubject<string[]>([]);

    constructor(
        private router: Router,
        private modalController: ModalController,
        private modalService: ModalService,
        private loadingToastAlertProvider: LoadingToastAlertProvider) {


        this.init();
    }

    getAllMatches() {
        return this._allMatches.asObservable();
    }

    setAllMatches(matches: IMatch[]) {
        this.allMatches = matches;
        this._allMatches.next(matches);
    }

    async setLikesUsersIds(data: string[]) {
        await Preferences.set({
            key: STORAGE_LIKES_SENT,
            value: JSON.stringify(data)
        } as any);
        this._allLikesUsersIds.next(data);
    }

    addLikesUsersIds(data: string) {
        this.allLikesUsersIds.push(data);
        this.setLikesUsersIds(this.allLikesUsersIds);
    }

    removeLikesUsersId(data: string) {
        let filteredArray = this.allLikesUsersIds.filter(element => element !== data);
        this.allLikesUsersIds = filteredArray;
        this.setLikesUsersIds(this.allLikesUsersIds);
    }

    getAllLikesUsersIds() {
        return this._allLikesUsersIds.asObservable();
    }

    setAllMatchsUsersIds(data: string[]) {
        this._allMatchsUsersIds.next(data);
    }

    getAllMatchsUsersIds() {
        return this._allMatchsUsersIds.asObservable();
    }

    getOnMoveCard() {
        return this._onMoveCard.asObservable();
    }

    setOnMoveCard(pos: number) {
        this._onMoveCard.next(pos);
    }

    getOnEndCard() {
        return this._onEndCard.asObservable();
    }

    setOnEndCard(pos: number) {
        this._onEndCard.next(pos);
    }

    async sendMessageCall(text: string, matchId: string) {
        const callRequest = httpsCallable(this.functions, 'matches-sendMessageCall');
        return callRequest({text, matchId});
    }

    async onMessageCloseCall(matchId: string) {
        const callRequest = httpsCallable(this.functions, 'matches-onMessageCloseCall');
        return callRequest({matchId});
    }

    async likeCall(userId: string, instantMatch = false) {
        try {
            const callRequest = httpsCallable(this.functions, 'matches-likeCall');
            const res = await callRequest({userId, instantMatch});
            this.addLikesUsersIds(userId);
            const data = res?.data as IMatch;
            if (data?.isMatch) {
                this.openMatchModal(data);
            }
        } catch (e: any) {
            console.error(e);
            this.loadingToastAlertProvider.displayError(e);
            throw new Error(e);
        }
    }

    async unlikeCall(likeId: string) {
        const callRequest = httpsCallable(this.functions, 'matches-unlikeCall');
        return callRequest({likeId});
    }

    async cancelMatchCall(userId: string) {
        const callRequest = httpsCallable(this.functions, 'matches-cancelMatchCall');
        const payloadString = JSON.stringify({userId});
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }

    async getLikesByUserIdCall(payload: ILikesPayload): Promise<any> {
        const callRequest = httpsCallable(this.functions, 'matches-getLikesByUserIdCall');
        return callRequest(payload);
    }

    async getMatchesByUserIdCall() {
        try {
            const callRequest = httpsCallable(this.functions, 'matches-getMatchesByUserIdCall');
            const res = await callRequest();
            const allMatches = res.data as IMatch[] || [];
            this.setAllMatches(allMatches);
        } catch (e: any) {
            console.error(e);
        }
    }

    async getMatchByIdCall(matchId: string) {
        try {
            const callRequest = httpsCallable(this.functions, 'matches-getMatchByIdCall');
            const payloadString = JSON.stringify({matchId});
            const encryptedPayload = encryptChunk(payloadString);
            const res = await callRequest(encryptedPayload);
            console.log(res);
            const match = res?.data as IMatch;
            if (match) {
                if (this.allMatches?.length) {
                    const matchIndex = this.allMatches.findIndex(m => m.documentId === matchId);
                    if (matchIndex > -1) {
                        if (!match.isMatch) {
                            this.allMatches.splice(matchIndex, 1);
                        } else {
                            this.allMatches[matchIndex] = match;
                        }
                    } else {
                        this.allMatches.push(match)
                    }
                    this.setAllMatches(this.allMatches);
                } else {
                    if (match.isMatch) {
                        this.setAllMatches([match]);
                    }
                }
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    private async openMatchModal(match: IMatch) {
        if (!match?.isMatch) {
            return;
        }
        const modal = await this.modalController.create({
            component: MatchModalComponent as any,
            cssClass: 'match-modal',
            componentProps: {
                match
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            const data = res?.data;
            console.log(res);
        })
    }

    private async init() {
        const {value} = await Preferences.get({key: STORAGE_LIKES_SENT});
        if (value) {
            this.allLikesUsersIds = JSON.parse(value);
            this.setLikesUsersIds(this.allLikesUsersIds);
        }
    }

}
