<ion-content class="profile-page">
    <ng-container *ngIf="user">

        <div (click)="close(false, $event)"
             class="rounded-action">
            <ion-icon name="chevron-back"></ion-icon>
        </div>

        <app-custom-swiper [displayInstantMatch]="displayInstantMatch"
                           [displayLike]="displayLike"
                           [fromAdmin]="!!currentUser?.roles?.includes(ERole.ADMIN)"
                           [isUserDetails]="true"
                           [swiperPagination]="true"
                           [user]="user"></app-custom-swiper>

        <div class="profile-info ion-margin-top">

            <div *ngIf="currentUser?.roles?.includes(ERole.ADMIN)" class="from-admin">
                <div *ngIf="user.email">
                    {{user?.email}}
                </div>
                <div *ngIf="user.banned" class="elo banned">
                    <ion-icon name="alert-circle-outline" size="default"></ion-icon>
                    Banned
                </div>
                <div class="elo">
                    <ion-icon color="danger" name="warning" size="small"></ion-icon>
                    Reports: {{user?.reportsCount || 0}}
                </div>

                <div *ngIf="user?.spark" class="custom-select-container">
                    <div (click)="setGenderOrientation(g.id, true)"
                         *ngFor="let g of adminGender"
                         [class.selected]="g.selected"
                         class="label">
                        {{g.label | translate}}
                    </div>
                </div>

                <div *ngIf="user?.spark" class="custom-select-container">
                    <div (click)="setGenderOrientation(g.id)"
                         *ngFor="let g of adminSexualOrientation"
                         [class.selected]="g.selected"
                         class="label">
                        {{g.label | translate}}
                    </div>
                </div>
            </div>

            <div class="name-age">
                <div class="name">
                    <ion-icon *ngIf="user?.isPremium"
                              color="myprimary"
                              name="diamond"
                              size="default" style="margin-right: 5px"></ion-icon>
                    <ng-container *ngIf="!user.age">
                        {{user.firstName}}
                    </ng-container>
                    <ng-container *ngIf="user.age">
                        {{user.firstName}}, {{ user.age }}
                    </ng-container>
                </div>

                <div *ngIf="!fromVote && user?.elo" class="elo">
                    <ion-icon color="myprimary"
                              name="flame"
                              size="default"></ion-icon>
                    {{user.elo}} elo
                </div>
            </div>


            <div *ngIf="user?.bio" class="bio info">
                <blockquote>
                    {{user.bio}}
                </blockquote>
            </div>

            <div *ngIf="user.heightCm" class="info">
                <ion-icon name="resize-outline"
                          size="small"></ion-icon>
                {{ getHeight(user.heightCm) }}
            </div>

            <div *ngIf="platform !== EPlatform.ios && user.distanceKm" class="info">
                <ion-icon name="location-outline"
                          size="small"></ion-icon>
                {{getDistance(user.distanceKm)}}
            </div>
            <div *ngIf="user.gender && user.sexualOrientation" class="info">
                <ion-icon *ngIf="user.gender === EGender.OTHERS" name="transgender-outline"
                          size="small"></ion-icon>
                <ion-icon *ngIf="user.gender === EGender.MAN" name="male"
                          size="small"></ion-icon>
                <ion-icon *ngIf="user.gender === EGender.WOMAN" name="female"
                          size="small"></ion-icon>
                {{translateGender(user.gender) | translate}}&nbsp;-&nbsp;{{translateSexualOrientation(user.sexualOrientation) | translate}}
            </div>


            <ion-button
                    (click)="openLink(user.instagram)"
                    *ngIf="user?.instagram"
                    class="ion-margin-top" expand="full" fill="clear">
                <ion-icon class="instagram-icon" name="logo-instagram"
                          size="default"></ion-icon>
                <ion-label class="instagram-text" slot="end">{{user?.instagram}}</ion-label>
            </ion-button>
            <ion-button
                    (click)="openLink(user.tiktok, true)"
                    *ngIf="user?.tiktok" class="" expand="full" fill="clear"
                    fill="clear">
                <ion-icon class="" color="dark" name="logo-tiktok"
                          size="default"></ion-icon>
                <ion-label class="" color="dark" slot="end">{{user?.tiktok}}</ion-label>
            </ion-button>
        </div>

        <div class="buttons-container">
            <ion-button
                    (click)="shareProfile()"
                    class="ion-margin-top share-btn"
                    expand="full" mode="ios">
                {{"shareProfile" | translate}}
            </ion-button>

            <ng-container
                    *ngIf="currentUser && currentUser?.documentId !== user?.documentId">
                <ion-button
                        (click)="cancelMatchUser(user?.documentId)"
                        *ngIf="isAMatch()"
                        class="ion-margin-top cancel-btn"
                        expand="full" mode="ios">
                    {{"cancelMatch" | translate}}
                </ion-button>
                <ion-button (click)="reportUser()"
                            class="ion-margin-top report-btn"
                            expand="full" mode="ios">
                    {{"report" | translate}}  {{user.firstName}}
                </ion-button>
            </ng-container>

            <ng-container *ngIf="currentUser?.roles?.includes(ERole.ADMIN)">
                <ion-button (click)="openUserMessages()"
                            class="ion-margin-top"
                            color="primary"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    See all messages
                </ion-button>
                <ion-button (click)="updateAdminUser(EUpdateUserAdmin.ban)"
                            *ngIf="!user?.banned && currentUser?.documentId !== user?.documentId"
                            class="ion-margin-top"
                            color="tertiary"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Ban  {{user.firstName}}
                </ion-button>
                <ion-button (click)="updateAdminUser(EUpdateUserAdmin.unban)"
                            *ngIf="user?.banned"
                            class="ion-margin-top"
                            color="secondary"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Unban  {{user.firstName}}
                </ion-button>
                <ion-button (click)="updateAdminUser(EUpdateUserAdmin.resetCount)"
                            *ngIf="user?.reportsCount"
                            class="ion-margin-top"
                            color="dark"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Reset report count
                </ion-button>
                <ion-button (click)="deleteUserAdmin()"
                            *ngIf="currentUser?.roles?.includes(ERole.ADMIN) && currentUser?.documentId !== user?.documentId"
                            class="ion-margin-top"
                            color="danger"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Delete user
                </ion-button>
                <ion-button (click)="setUserAdminCall([ERole.ADMIN])"
                            *ngIf="!user?.spark && currentUser?.roles?.includes(ERole.SUPER_ADMIN) && !user?.roles?.includes(ERole.ADMIN)"
                            class="ion-margin-top"
                            color="warning"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Give admin role
                </ion-button>
                <ion-button (click)="setUserAdminCall([])"
                            *ngIf="!user?.spark && currentUser?.roles?.includes(ERole.SUPER_ADMIN) && user?.roles?.includes(ERole.ADMIN)"
                            class="ion-margin-top"
                            color="warning"
                            expand="full" fill="solid" mode="ios"
                            shape="round">
                    Remove admin role
                </ion-button>
            </ng-container>
        </div>

    </ng-container>

</ion-content>
