import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {getAuth, getIdTokenResult, signOut} from 'firebase/auth';
import {ERole, ERoutes} from "@models/general";

@Injectable({
    providedIn: 'root'
})
export class AuthAdminGuardService implements CanActivate {

    constructor(public router: Router,
                private ngZone: NgZone) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        return new Promise(async (resolve) => {
            let isAdmin = await this.checkAdminRole();
            if (isAdmin) {
                resolve(true);
            } else {
                // user is refreshing the page so we have to wait to firebase to init
                setTimeout(async () => {
                    let isAdmin = await this.checkAdminRole();
                    if (isAdmin) {
                        resolve(true);
                        return;
                    }
                    resolve(false);
                    signOut(getAuth());
                    this.navigate(ERoutes.LOGIN);
                }, 2000)
            }
        });
    }

    navigate(route: string) {
        this.ngZone.run(() => {
            this.router.navigate([route], {replaceUrl: true});
        })
    }

    private async checkAdminRole(): Promise<boolean> {
        const auth = getAuth();
        const currentUser = auth?.currentUser;
        if (currentUser) {
            const idTokenResult = await getIdTokenResult(currentUser) as any;
            console.log(idTokenResult);
            const roles = idTokenResult?.claims?.roles || [];
            return roles.includes(ERole.ADMIN) || roles.includes(ERole.SUPER_ADMIN);
        }

        return false;
    }


}
