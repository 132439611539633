<ion-header class="">
    <ion-toolbar (click)="getUserDetails(currentUser?.documentId)" color="myprimary">
        <ion-avatar *ngIf="getValue(EMatchKey.picture, currentUser?.documentId)"
                    slot="start">
            <img [src]="getValue(EMatchKey.picture, currentUser?.documentId)" alt="">
        </ion-avatar>
        <ion-title>
            {{getValue(EMatchKey.firstName, currentUser?.documentId)}}
        </ion-title>
        <ion-buttons slot="end">
            <div (click)="closeModal($event)" class="rounded-icon prim">
                <ion-icon name="close"></ion-icon>
            </div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div class="chat-container">


        <ng-container *ngIf="isLoading || !currentUser">
            <p>
                <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
                <ion-skeleton-text [animated]="true" style="width: 50%;"></ion-skeleton-text>
            </p>
        </ng-container>

        <ng-container *ngIf="currentUser">

            <div *ngFor="let message of allMessages"
                 [class.user-message]="message.senderId === currentUser?.documentId"
                 class="message-container">
                <div (click)="getUserDetails(currentUser?.documentId)"
                     *ngIf="message.senderId !== currentUser?.documentId"
                     [ngStyle]="{ 'background-image': 'url(' + getValue(EMatchKey.picture, currentUser?.documentId) + ')'}"
                     class="avatar-image">
                </div>
                <div [class.user-message]="message.senderId === currentUser?.documentId"
                     class="my-messages">
                    <div class="message">
                        {{message.text}}
                    </div>
                    <div (click)="getUserDetails(match?.createdBy)"
                         *ngIf="message.senderId === currentUser?.documentId && fromAdmin"
                         [ngStyle]="{ 'background-image': 'url(' + getValue(EMatchKey.picture, match?.createdBy) + ')'}"
                         class="avatar-image">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

</ion-content>

<ion-footer *ngIf="!hideInput">
    <ion-item>
        <ion-input #messageInput
                   (keyup.enter)="sendMessage()"
                   [(ngModel)]="newMessage"
                   [maxlength]="MESSAGE_MAX_LENGTH"
                   [placeholder]="'typeAMessage' | translate"></ion-input>
        <ion-button (click)="sendMessage($event)"
                    [disabled]="!newMessage || disableSend"
                    slot="end">
            <ion-icon name="send-outline" size="large"></ion-icon>
        </ion-button>
    </ion-item>
</ion-footer>
