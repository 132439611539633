import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {REGION} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";

@Injectable({
    providedIn: 'root'
})
export class PremiumService {

    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);

    constructor() {
    }

    async premiumResetEloCall(): Promise<any> {
        const callRequest = httpsCallable(this.functions, 'premium-premiumResetEloCall');
        return callRequest();
    }

    async premiumBoostCall(): Promise<any> {
        const callRequest = httpsCallable(this.functions, 'premium-premiumBoostCall');
        return callRequest();
    }

}
