import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from "@services/admin-service";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {EMatchKey, IMatch, IMessage} from "@models/matches";
import {MessagesComponent} from "@shared/components/messages/messages.component";
import {ModalController} from "@ionic/angular";
import {getValueFromMatch} from "@helpers/elo";
import {IUser} from "@models/user";
import {ModalService} from "@services/modal-service";

@Component({
    selector: 'app-admin-messages-modal',
    templateUrl: './admin-messages-modal.component.html',
    styleUrls: ['./admin-messages-modal.component.scss'],
})
export class AdminMessagesModalComponent implements OnInit {

    @Input()
    user: IUser | null = null;
    allChats: IMatch[] = [];
    EMatchKey = EMatchKey;

    constructor(
        public modalController: ModalController,
        private modalService: ModalService,
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private adminService: AdminService
    ) {
    }

    ngOnInit() {
        this.getChatsByUserIdCall();
    }

    close() {
        this.modalController.dismiss();
    }

    async sendMessage(match?: IMatch) {

        const modal = await this.modalController.create({
            component: MessagesComponent as any,
            componentProps: {
                match,
                fromAdmin: true,
                hideInput: true
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            const data = res?.data as IMessage;
            console.log(data);
            if (data?.matchId) {
                const matchIndex = this.allChats.findIndex(m => m.documentId === data.matchId);
                if (matchIndex > -1) {
                    this.allChats[matchIndex].lastMessage = data.text;
                    this.allChats[matchIndex].lastMessageSender = data.senderId;
                }
            }
        })
    }


    getValueFromMatch(match: IMatch, key: EMatchKey, firstUser = true): string {
        return getValueFromMatch(key, match, this.user?.documentId);
    }

    async getChatsByUserIdCall(event?: any) {
        this.allChats = [];
        if (!this.user?.documentId) {
            return;
        }
        await this.loadingToastAlertProvider.presentLoadingWithOptions();
        try {
            const res = await this.adminService.getChatsByUserIdCall(this.user?.documentId);
            console.log(res);
            this.allChats = res?.data as IMatch[] || [];
        } catch (error) {
            console.log(error);
            this.loadingToastAlertProvider.displayError(error);
        }
        event?.target?.complete();
        this.loadingToastAlertProvider.dismissLoading();
    }

}

