import {Component, Input, OnInit} from '@angular/core';
import {IonicSlides, ModalController} from "@ionic/angular";
import {BOOSTS_COUNT, EBonusCount, EPremiumType, IConsumable} from "@models/premium";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {PremiumService} from "@services/premium.service";
import {TranslateService} from "@ngx-translate/core";
import {getStripeUrl} from "@helpers/stripe";
import {EStripeProduct} from "@models/stripe";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {Subject} from "rxjs";
import {IUser} from "@models/user";
import {getAuth} from "firebase/auth";
import {environment} from "@environments/environment";

@Component({
    selector: 'app-purchase-consumable',
    templateUrl: './purchase-consumable.component.html',
    styleUrls: ['./purchase-consumable.component.scss'],
})
export class PurchaseConsumableComponent implements OnInit {

    @Input()
    type!: EPremiumType;

    isProd = environment.production;
    swiperModules = [IonicSlides];
    EPremiumChange = EPremiumType;
    BOOSTS_COUNT = BOOSTS_COUNT;
    consumables: IConsumable[] = [];
    currentUser?: IUser;

    private subscription = new Subject<void>();

    constructor(
        private loadingToastAlertProvider: LoadingToastAlertProvider,
        private translate: TranslateService,
        private premiumService: PremiumService,
        private usersService: UsersService,
        private modalController: ModalController) {
    }

    ngOnInit() {
        this.getCurrentUser();
        if (this.type === EPremiumType.boost) {
            this.consumables = [{
                title: "",
                count: EBonusCount.BOOST_LEVEL1,
                price: 1.99,
                product: this.isProd ? EStripeProduct.BOOST_LEVEL1 : EStripeProduct.BOOST_LEVEL1_TEST
            }, {
                title: "popular",
                count: EBonusCount.BOOST_LEVEL2,
                price: 1.66,
                product: this.isProd ? EStripeProduct.BOOST_LEVEL2 : EStripeProduct.BOOST_LEVEL2_TEST
            }, {
                title: "mostAdvantageous",
                count: EBonusCount.BOOST_LEVEL3,
                price: 0.99,
                product: this.isProd ? EStripeProduct.BOOST_LEVEL3 : EStripeProduct.BOOST_LEVEL3_TEST
            }];
        } else if (this.type === EPremiumType.instantMatch) {
            this.consumables = [{
                title: "",
                count: EBonusCount.INSTANT_MATCH_LEVEL1,
                price: 1.99,
                product: this.isProd ? EStripeProduct.INSTANT_MATCH_LEVEL1 : EStripeProduct.INSTANT_MATCH_LEVEL1_TEST
            }, {
                title: "popular",
                count: EBonusCount.INSTANT_MATCH_LEVEL2,
                price: 1.66,
                product: this.isProd ? EStripeProduct.INSTANT_MATCH_LEVEL2 : EStripeProduct.INSTANT_MATCH_LEVEL2_TEST
            }, {
                title: "mostAdvantageous",
                count: EBonusCount.INSTANT_MATCH_LEVEL3,
                price: 0.99,
                product: this.isProd ? EStripeProduct.INSTANT_MATCH_LEVEL3 : EStripeProduct.INSTANT_MATCH_LEVEL3_TEST
            }];
        }
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
    }

    getDiscount(price: number): number {
        let highestPricedProduct = this.consumables.reduce((lowest, product) => {
            return (lowest.price > product.price) ? lowest : product;
        });
        if (!highestPricedProduct?.price) {
            return 0;
        }
        const percent = Math.round((1 - (price / highestPricedProduct.price)) * 100)
        return parseInt(percent.toString());
    }

    close() {
        this.modalController.dismiss();
    }

    async submit(product: EStripeProduct) {

        let uid = getAuth()?.currentUser?.uid;
        if (!uid) {
            this.usersService.logout();
            this.close();
            return;
        }
        const url = getStripeUrl(product, uid, this.currentUser?.email);
        window.open(url, "_blank");
        this.close();
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
        });
    }

}
