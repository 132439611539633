import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {REGION} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {encryptChunk} from "@helpers/forge";
import {IAdminUserPayload, IUpdateAdminUser} from "@models/admin";
import {IUserFilters} from "@models/user";

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);

    constructor() {

    }

    async getAllSparkMatchCall() {
        const callRequest = httpsCallable(this.functions, 'admin-getAllSparkMatchCall');
        return callRequest();
    }

    async randomCall(data?: any) {
        const callRequest = httpsCallable(this.functions, 'admin-randomCall');
        await callRequest(data);
    }

    async sendMessageAdminCall(text: string, matchId: string) {
        const callRequest = httpsCallable(this.functions, 'admin-sendMessageAdminCall');
        return callRequest({text, matchId});
    }

    async getChatsByUserIdCall(documentId: string) {
        const callRequest = httpsCallable(this.functions, 'admin-getChatsByUserIdCall');
        return callRequest({documentId});
    }

    async getUserByIdAdminCall(documentId: string) {
        const callRequest = httpsCallable(this.functions, 'admin-getUserByIdAdminCall');
        return callRequest({documentId});
    }

    async updateUserAdminCall(payload: IUpdateAdminUser) {
        const callRequest = httpsCallable(this.functions, 'admin-updateUserAdminCall');
        return callRequest(payload);
    }

    async setUserRole(payload: IAdminUserPayload) {
        const callRequest = httpsCallable(this.functions, 'admin-setUserRoleCall');
        const payloadString = JSON.stringify(payload);
        const encryptedPayload = encryptChunk(payloadString);
        return callRequest(encryptedPayload);
    }


    async getUsersFilterAdminCall(params: IUserFilters) {
        const callRequest = httpsCallable(this.functions, 'admin-getUsersFilterAdminCall');
        return callRequest(params);
    }

}
