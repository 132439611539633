import {AfterViewInit, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    getAuth,
    indexedDBLocalPersistence,
    isSignInWithEmailLink,
    setPersistence,
    signInWithEmailLink
} from "firebase/auth";
import {Router} from "@angular/router";
import {firebaseApp} from "@app/app.module";
import {Preferences} from "@capacitor/preferences";
import {STORAGE_EMAIL, STORAGE_REMEMBER_ME, TOAST_POSITION} from "@models/general";
import {decryptStorageData} from "@helpers/forge";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";

@Component({
    selector: 'app-email-signin',
    templateUrl: './email-signin.component.html',
    styleUrls: ['./email-signin.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class EmailSigninComponent implements AfterViewInit {

    constructor(private router: Router,
                private loadingToastAlertProvider: LoadingToastAlertProvider) {
    }


    async ngAfterViewInit() {

// Confirm the link is a sign-in with email link.
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let {value} = await Preferences.get({key: STORAGE_EMAIL});
            let email: any = decryptStorageData(value);
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            if (!email) {
                return;
            }

            this.setPersistenceLocal(email);
        }
    }

    private setPersistenceLocal(email: string) {
        const auth = getAuth(firebaseApp);

        setPersistence(auth, indexedDBLocalPersistence).then(() => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            // The client SDK will parse the code from the link for you.
            return signInWithEmailLink(auth, email, window.location.href)
                .then(async (result) => {
                    this.loadingToastAlertProvider.presentToastWithOptions(
                        "successfullyConnected", false, TOAST_POSITION.MIDDLE);
                    console.log(result);
                    // Clear email from storage.
                    // window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    this.removeEmailStorage();
                    // this.router.navigate(['']);
                })
                .catch(async (error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    console.error(error);
                    this.removeEmailStorage();
                    this.loadingToastAlertProvider.displayError(error);
                });
        }).catch((error) => {
            console.error(error);
        });
    }

    private async removeEmailStorage() {
        let {value} = await Preferences.get({key: STORAGE_REMEMBER_ME});
        if (!value) {
            await Preferences.remove({
                key: STORAGE_EMAIL,
            });
        }
    }

}

