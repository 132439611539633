import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonContent, ModalController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {collection, getFirestore, limit, onSnapshot, orderBy, query, Unsubscribe} from "firebase/firestore";
import {firebaseApp} from "@app/app.module";
import {LoadingToastAlertProvider} from "@services/loadingToastAlert";
import {IUser} from "@models/user";
import {MatchesService} from "@services/matches.service";
import {ECollections, MESSAGE_LIMIT, MESSAGE_MAX_LENGTH, TOAST_POSITION} from "@models/general";
import {EMatchKey, IMatch, IMessage} from "@models/matches";
import {takeUntil} from "rxjs/operators";
import {UsersService} from "@services/users-service";
import {deepClone, firestoreDocsToArray} from "@helpers/misc";
import {UserDetailsComponent} from "@app/shared/components/user-details/user-details.component";
import {getValueFromMatch} from "@helpers/elo";
import {DomSanitizer} from "@angular/platform-browser";
import {AdminService} from "@services/admin-service";
import {ModalService} from "@services/modal-service";
import {FirebaseMessagingService} from "@services/firebase-messaging-service";

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {

    @ViewChild('messageInput')
    messageInputRef?: ElementRef;

    @Input()
    match?: IMatch;

    @Input()
    fromAdmin = false;

    @Input()
    hideInput = false;

    @ViewChild(IonContent)
    content?: IonContent;

    isAdmin = false;
    MESSAGE_MAX_LENGTH = MESSAGE_MAX_LENGTH
    EMatchKey = EMatchKey;
    isLoading = false;
    disableSend = false;
    currentUser?: IUser;
    db = getFirestore(firebaseApp);
    allMessages: IMessage[] = [];
    newMessage = '';
    private subscription = new Subject<void>();
    private messagesSnapshotListener!: Unsubscribe;

    constructor(
        public modalController: ModalController,
        public usersService: UsersService,
        public firebaseMessagingService: FirebaseMessagingService,
        public adminService: AdminService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        public matchesService: MatchesService,
        private loadingToastAlertProvider: LoadingToastAlertProvider) {
    }

    ngOnInit() {
        this.firebaseMessagingService.messageModalOpen = true;
        if (!this.match) {
            this.closeModal();
            return;
        }
        if (this.fromAdmin) {
            const usersIds = this.match?.usersIds || [];
            const createdBy = this.match.createdBy;
            let secondUserId = usersIds.find(u => u !== createdBy) as string;
            this.getUserByIdAdminCall(secondUserId);
        } else {
            this.getCurrentUser();
        }
        this.getMessagesLive();
    }

    ngOnDestroy() {
        this.subscription.next();
        this.subscription.complete();
        if (this.messagesSnapshotListener) {
            this.messagesSnapshotListener();
        }
    }

    getValue(key: EMatchKey, uid?: string): string {
        return getValueFromMatch(key, this.match, uid)
    }

    closeModal(event?: any) {
        event?.stopPropagation();
        let lastMessage: IMessage | null = null;
        if (this.allMessages?.length) {
            lastMessage = this.allMessages[this.allMessages.length - 1];
            lastMessage.matchId = this.match?.documentId;
        }
        this.firebaseMessagingService.messageModalOpen = false;
        this.modalController.dismiss(lastMessage);
    }

    async getUserDetails(uid?: string) {
        if (!this.match?.documentId || !uid) {
            return;
        }
        const modal = await this.modalController.create({
            component: UserDetailsComponent as any,
            componentProps: {
                user: {
                    documentId: getValueFromMatch(EMatchKey.userId, this.match, uid),
                    images: [getValueFromMatch(EMatchKey.picture, this.match, uid)],
                    firstName: getValueFromMatch(EMatchKey.firstName, this.match, uid)
                },
                fromMessage: true
            }
        });
        await modal.present();
        this.modalService.openingModal();
        modal.onWillDismiss().then(async (res) => {
            if (res?.data?.isUnmatch) {
                setTimeout(() => {
                    this.closeModal();
                }, 500)
            }
        })
    }

    async getUserDetailsAdmin(firstUser = true) {
        const usersIds = this.match?.usersIds || [];
        let selectedId = '';
        if (usersIds?.length) {
            selectedId = firstUser ? usersIds[0] : usersIds[1];
        }
        const modal = await this.modalController.create({
            component: UserDetailsComponent as any,
            componentProps: {
                user: {
                    documentId: getValueFromMatch(EMatchKey.userId, this.match, selectedId),
                    images: [getValueFromMatch(EMatchKey.picture, this.match, selectedId)],
                    firstName: getValueFromMatch(EMatchKey.firstName, this.match, selectedId)
                },
                fromMessage: true
            }
        });
        await modal.present();
        this.modalService.openingModal();
    }

    // Too dangerous
    // formatMessage(content: string): SafeHtml {
    //     const urlRegex = /(https?:\/\/[^\s]+)/g;
    //
    //     // Replace URLs in the text with clickable links
    //     const formattedContent = content.replace(urlRegex, (url) => {
    //         return `<a href="${url}" target="_blank">${url}</a>`;
    //     });
    //
    //     // Use DomSanitizer to mark the HTML content as safe
    //     return this.sanitizer.bypassSecurityTrustHtml(formattedContent);
    // }

    async sendMessage(event?: any) {
        if (!this.newMessage || this.disableSend || !this.match?.documentId) {
            return;
        }
        event?.preventDefault();
        this.messageInputRef?.nativeElement?.focus();
        this.allMessages.push({
            text: this.newMessage,
            senderId: this.currentUser?.documentId
        } as IMessage);
        this.cdr.detectChanges();
        const originalMessage = deepClone(this.newMessage);
        this.newMessage = '';
        // this.disableSend = true;
        setTimeout(() => {
            this.scrollToBottom()
        }, 50)
        if (this.fromAdmin) {
            try {
                const res = await this.adminService.sendMessageAdminCall(originalMessage, this.match.documentId);
                console.log(res);
            } catch (error) {
                console.log(error);
                this.newMessage = originalMessage;
                this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            }
        } else {
            try {
                const res = await this.matchesService.sendMessageCall(originalMessage, this.match.documentId);
                console.log(res);
            } catch (error) {
                console.log(error);
                this.newMessage = originalMessage;
                this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            }
        }
        this.disableSend = false;
    }

    async getUserByIdAdminCall(userId: string) {
        try {
            const res = await this.adminService.getUserByIdAdminCall(userId);
            console.log(res);
            this.currentUser = res?.data as IUser;
        } catch (error) {
            console.log(error);
            this.loadingToastAlertProvider.presentToastWithOptions(error, true, TOAST_POSITION.MIDDLE);
            this.closeModal();
        }
    }

    private getCurrentUser() {
        this.usersService.getUser().pipe(takeUntil(this.subscription)).subscribe(data => {
            this.currentUser = data;
        });
    }

    private async getMessagesLive() {
        if (!this.match?.documentId) {
            return;
        }

        const referralQuery = collection(this.db, ECollections.matches, this.match.documentId, ECollections.messages);
        const q = query(referralQuery,
            orderBy('created', 'desc'), limit(MESSAGE_LIMIT));

        this.messagesSnapshotListener = onSnapshot(q, async (d) => {
                console.log(d);
                if (!d?.empty) {
                    const allMessages = firestoreDocsToArray(d.docs) as IMessage[];
                    this.allMessages = allMessages.reverse();
                    this.scrollToBottom();
                }
            },
            (error) => {
                console.error(error);
            }
        )
    }

    private scrollToBottom() {
        if (this.content) {
            this.content.scrollToBottom();
        }
    }

}
