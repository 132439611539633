import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {MAX_AGE, MAX_DISTANCE, MAX_ELO, MIN_AGE, STORAGE_FILTERS} from "@models/general";
import {Preferences} from "@capacitor/preferences";
import {EDistanceUnits, EGender, EHeightUnits, ESexualOrientation, IDoubleRange, IUser} from "@models/user";
import {
    deepClone,
    isValidAgeRange,
    isValidDistanceUnit,
    isValidEloRange,
    isValidFilterBy,
    isValidGender,
    isValidHeightUnit,
    isValidKmRadius,
    isValidSexualOrientation
} from "@helpers/misc";
import {getOppositeGender} from "@helpers/elo";
import {UsersService} from "@services/users-service";
import {EUsersFiltersAdmin} from "@models/admin";

export interface IFilters {
    selectedGenderVote: EGender;
    selectedGenderAdmin: EGender;
    selectedGenderSparkles: EGender;
    selectedGenderRanking: EGender;
    selectedSexualOrientationSparkles: ESexualOrientation;
    selectedSexualOrientationRanking: ESexualOrientation;
    selectedSexualOrientationAdmin: ESexualOrientation;
    ageRangeSparkles: IDoubleRange;
    ageRangeRanking: IDoubleRange;
    eloRangeRanking: IDoubleRange;
    kmRadiusValueVote: number;
    kmRadiusValueSparkles: number;
    kmRadiusValueRanking: number;
    selectedDistanceUnit: EDistanceUnits;
    selectedHeightUnit: EHeightUnits;
    selectedFilterByAdmin: EUsersFiltersAdmin;

    [key: string]: EGender | ESexualOrientation | IDoubleRange | number | EDistanceUnits | EHeightUnits | EUsersFiltersAdmin | string;
}


@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    currentUser?: IUser;
    allFilters: IFilters = {} as IFilters;

    constructor(
        private usersService: UsersService) {
        this.init();
    }

    async setStorageValue(key: STORAGE_FILTERS, value: any) {
        if (!key || !value) {
            return;
        }
        // @ts-ignore
        this.allFilters[key] = deepClone(value) as any;
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        await Preferences.set({
            key,
            value,
        });
    }

    private async init() {
        this.getCurrentUser();
        this.getDistanceUnitsStorage();
        this.getHeightUnitsStorage();
        this.getFilterByStorage();

        const ageRangeRanking = await this.getAgeStorage(STORAGE_FILTERS.STORAGE_AGE_RANGE_RANKING);
        const ageRangeSparkles = await this.getAgeStorage(STORAGE_FILTERS.STORAGE_AGE_RANGE_SPARKLES);
        const eloRangeRanking = await this.getEloStorage(STORAGE_FILTERS.STORAGE_ELO_RANGE_RANKING);

        const kmRadiusValueVote = await this.getKmRadiusStorage(STORAGE_FILTERS.STORAGE_KM_RADIUS_VOTE);
        const kmRadiusValueSparkles = await this.getKmRadiusStorage(STORAGE_FILTERS.STORAGE_KM_RADIUS_SPARKLES);
        const kmRadiusValueRanking = await this.getKmRadiusStorage(STORAGE_FILTERS.STORAGE_KM_RADIUS_RANKING);

        this.allFilters = {
            ...this.allFilters,
            ageRangeRanking,
            ageRangeSparkles,
            eloRangeRanking,
            kmRadiusValueVote,
            kmRadiusValueSparkles,
            kmRadiusValueRanking
        }
        console.log(this.allFilters);
    }

    private async getDistanceUnitsStorage() {
        let selectedDistanceUnit = EDistanceUnits.km;
        let unitStorage = (await Preferences.get({key: STORAGE_FILTERS.STORAGE_DISTANCE_UNITS}))?.value;
        if (isValidDistanceUnit(unitStorage)) {
            selectedDistanceUnit = unitStorage as EDistanceUnits;
        }
        this.allFilters = {
            ...this.allFilters,
            selectedDistanceUnit
        }
    }

    private async getHeightUnitsStorage() {
        let selectedHeightUnit = EHeightUnits.centimeters;
        let unitStorage = (await Preferences.get({key: STORAGE_FILTERS.STORAGE_HEIGHT_UNITS}))?.value;
        if (isValidHeightUnit(unitStorage)) {
            selectedHeightUnit = unitStorage as EHeightUnits;
        }
        this.allFilters = {
            ...this.allFilters,
            selectedHeightUnit
        }
    }

    private async getFilterByStorage() {
        let selectedFilterByAdmin = EUsersFiltersAdmin.reported;
        let filterByStorage = (await Preferences.get({key: STORAGE_FILTERS.STORAGE_FILTER_BY_ADMIN}))?.value;
        if (isValidFilterBy(filterByStorage)) {
            selectedFilterByAdmin = filterByStorage as EUsersFiltersAdmin;
        }
        this.allFilters = {
            ...this.allFilters,
            selectedFilterByAdmin
        }
    }

    private async getCurrentSexualOrientationStorage(key: STORAGE_FILTERS): Promise<ESexualOrientation> {
        const {value} = await Preferences.get({key});
        if (isValidSexualOrientation(value)) {
            return value as ESexualOrientation;
        } else {
            return this.currentUser?.sexualOrientation || ESexualOrientation.EVERYONE;
        }
    }

    private async getCurrentGenderStorage(key: STORAGE_FILTERS): Promise<EGender> {
        const {value} = await Preferences.get({key});
        if (isValidGender(value)) {
            return value as EGender;
        } else {
            return getOppositeGender(this.currentUser?.gender);
        }
    }

    private async getAgeStorage(key: STORAGE_FILTERS): Promise<IDoubleRange> {
        let range: IDoubleRange = {lower: MIN_AGE, upper: MAX_AGE};
        const {value} = await Preferences.get({key});
        if (value) {
            const storageValue = JSON.parse(value);
            if (isValidAgeRange(storageValue)) {
                range = storageValue;
            }
        }

        return range;
    }

    private async getEloStorage(key: STORAGE_FILTERS): Promise<IDoubleRange> {
        let range: IDoubleRange = {lower: 0, upper: MAX_ELO};
        const {value} = await Preferences.get({key});
        if (value) {
            const storageValue = JSON.parse(value);
            if (isValidEloRange(storageValue)) {
                range = storageValue;
            }
        }

        return range;
    }

    private async getKmRadiusStorage(key: STORAGE_FILTERS): Promise<number> {
        let kmRadiusValue = MAX_DISTANCE;
        const {value} = await Preferences.get({key: key});
        if (value) {
            const parsedValue = JSON.parse(value);
            if (isValidKmRadius(parsedValue)) {
                kmRadiusValue = parsedValue;
            }
        }
        return kmRadiusValue;
    }

    private getCurrentUser() {
        this.usersService.getUser().subscribe(async data => {
            this.currentUser = data;
            const selectedGenderVote = await this.getCurrentGenderStorage(STORAGE_FILTERS.STORAGE_GENDER_VOTE);
            const selectedGenderSparkles = await this.getCurrentGenderStorage(STORAGE_FILTERS.STORAGE_GENDER_SPARKLES);
            const selectedGenderRanking = await this.getCurrentGenderStorage(STORAGE_FILTERS.STORAGE_GENDER_RANKING);
            const selectedGenderAdmin = await this.getCurrentGenderStorage(STORAGE_FILTERS.STORAGE_GENDER_ADMIN);
            const selectedSexualOrientationSparkles = await this.getCurrentSexualOrientationStorage(STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_SPARKLES);
            const selectedSexualOrientationRanking = await this.getCurrentSexualOrientationStorage(STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_RANKING);
            const selectedSexualOrientationAdmin = await this.getCurrentSexualOrientationStorage(STORAGE_FILTERS.STORAGE_SEXUAL_ORIENTATION_ADMIN);
            this.allFilters = {
                ...this.allFilters,
                selectedGenderVote,
                selectedGenderSparkles,
                selectedGenderRanking,
                selectedGenderAdmin,
                selectedSexualOrientationSparkles,
                selectedSexualOrientationRanking,
                selectedSexualOrientationAdmin
            }
            console.log(this.allFilters);
        });

    }

}
