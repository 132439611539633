<ion-header class="">
    <ion-toolbar color="danger">
        <ion-title class="">
            <div class="name-age">
                {{user?.firstName}}
                <ng-container *ngIf="user?.age">
                    -&nbsp;{{user?.age}}
                </ng-container>
            </div>
        </ion-title>

        <ion-buttons slot="end">
            <div (click)="close()" class="rounded-icon">
                <ion-icon name="close"></ion-icon>
            </div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-refresher (ionRefresh)="getChatsByUserIdCall($event)" slot="fixed">
        <ion-refresher-content
                [pullingText]="'pullToRefresh' | translate"
                [refreshingText]="'refreshing' | translate"
                pullingIcon="chevron-down-circle-outline"
                refreshingSpinner="circles"></ion-refresher-content>
    </ion-refresher>


    <ion-list>
        <ng-container *ngFor="let c of allChats">
            <ion-item (click)="sendMessage(c)"
                      *ngIf="c?.documentId">
                <ion-avatar slot="start">
                    <img [src]="getValueFromMatch(c, EMatchKey.picture)" alt="">
                </ion-avatar>
                <ion-label>
                    <h2>{{ getValueFromMatch(c, EMatchKey.firstName) }}</h2>
                    <p>{{ c?.lastMessage }}</p>
                </ion-label>
            </ion-item>
        </ng-container>
    </ion-list>
</ion-content>

