import {Injectable} from "@angular/core";
// import {Device} from "@ionic-native/device/ngx";
import {Firestore, getFirestore} from 'firebase/firestore';
import {firebaseApp} from "@app/app.module";
import {REGION} from "@models/general";
import {Functions, getFunctions, httpsCallable} from "firebase/functions";
import {BehaviorSubject, Subject} from "rxjs";
import {IMatch} from "@models/matches";
import {IHistoryPayload} from "@models/history";

@Injectable({
    providedIn: 'root'
})
export class HistoryService {

    // this is set from app.component
    uid = '';
    db: Firestore = getFirestore(firebaseApp);
    functions: Functions = getFunctions(firebaseApp, REGION);
    private allMatches: IMatch[] = [];
    private _allMatches = new BehaviorSubject<IMatch[]>([]);
    private _onMoveCard = new Subject<number>();
    private _onEndCard = new Subject<number>();
    private allLikesUsersIds: string[] = [];
    private allMatchsUsersIds: string[] = [];
    private _allLikesUsersIds = new BehaviorSubject<string[]>([]);
    private _allMatchsUsersIds = new BehaviorSubject<string[]>([]);

    constructor() {

    }

    async getHistoryByUserIdCall(payload: IHistoryPayload): Promise<any> {
        const callRequest = httpsCallable(this.functions, 'history-getHistoryByUserIdCall');
        return callRequest(payload);
    }

}
