export const PRIVATE_KEY = "-----BEGIN RSA PRIVATE KEY-----\n" +
    "MIIEogIBAAKCAQEA4M7jSu4NHKUZtMnnQBV6fIKtFq6JHhyouw4HfHjTtpocM8Ej\n" +
    "+s93V41Mp5/7kZcWqo9PMcVitkOT4M2NXexT1SgNzUttUPqtya5j3deHBzT6gl7R\n" +
    "9xyqn1ePQSuz2Kkt8q5bGo/qLSDPOa7hVt4QgiBuYQafJKYdkmRtFw8/TU/6fLEi\n" +
    "wEzCiUX/2eJQY0UtZ5QtSxN3d62Yy2AOxflt1AcZDfqxBkYI1qgq7dvw4P1YFjHh\n" +
    "uGZMxcvT6hei5+gG210QVn7kxEFx2WMQjFo7QFE5vWSKgI5Btj9qSUCvnyoKuaLw\n" +
    "2ncZ252rGBV+4o2WrCMtz1LpTD8ThdsqzyTvjQIDAQABAoIBAAhSYEOT0FRxICR7\n" +
    "HN5YbTJSvAmM6HJ+6RgTit9msZ+e0NBxo91S9L3+8wl5eJyJFUO3Dl1vSxooIj8+\n" +
    "HK+PYgMP+oLQ+fj3bpt6oyPBKLRxl5xf2jHlk8NWQG1PKtcoXBURszmmfiqxfjpK\n" +
    "aPkIO2A1xyA8WIPIymty4D7sR5GbBI3rgDs2OolMSBRfXfUC4VCt0yR0jkRLCNMU\n" +
    "ae4ArDRD/vfFMhAOBATxFNpFKTzQ1AB+sch0K8BhAWcQM+9rC5ZOpSn5rGkS5Mbr\n" +
    "G0g7ERZ9uLfZ1Knqd+9yYwhGuBgDJlZlxQtGjeAhS7qeJZg4Bt6iAGJ0FRs/bCDJ\n" +
    "CMXJNcECgYEA8FvFmUsh8OEnWtpZxF/SnfCUviWYN+AZXtUcyvsGa0L8rWHu0OqD\n" +
    "4uH4jZLZOUIkupSpmPxz6wMpHq0Glw6K5+NxOT/Oqfw+rYRkoD/3AbI2N2uG/iFT\n" +
    "XCYuI6srFNDZy4vxH4XoKW/BgFeSzqS81/gu6TQENKUtbkUaEzVtyHkCgYEA73AO\n" +
    "jgaHtMsRNATeMn1YJSTHvJIz1py3xoHYXlc7YJgx957xuSTTPDnLEo4VIVuD5i12\n" +
    "UNgFWrzbgK46umzIOg0AXhfdxmphHuQYlltVyitABpX5xjnBWW++SKhzsU+N1env\n" +
    "7Kf23STTG1rMiFCjd0ypByTcXVmPEsOxGWnPQrUCgYBJW5kJWjb3r6A1SGMRXjWH\n" +
    "4r9i8VJt60WS/fQVt1uULRO1Tyh2Nr0d593WR7VollljYLAKczJlzcclB6X1QIkA\n" +
    "kRgjYugOVWBD0R5tEliu+dc2Pukqcr1gaH64gCtb5Qoz53gmKNZcnzEtnIy/6OOB\n" +
    "/stCfY+zCZ6+AvUcW7gv8QKBgBlUXGXP8QlCNjavkSX8NpfvVSXwvYUlnRdl4xLP\n" +
    "3ZsxXo/Yg6/XYnVLk90JpNibis+N3addwus/cWUiiVnxahkiCPjrQ23bER0oeiVl\n" +
    "0iSKAMLxmLPMplSkypd7IooOZW3MyrHZbvckDyUkrOB6z2Aufu8fiiRh9g3BH/oV\n" +
    "RHTFAoGAbz9nwSBKnAtYC6cI9jaJm9iPvNXtGC6lyS1KMqX6gHsblHn9YzDNVOBI\n" +
    "9Lj1eKi8xNUJyCOsy6BRo6OxifZJGnHKfABJA4xKbQ5cj11Zn/fS1cvSLs2mQvLQ\n" +
    "Ct2zU5Y6fiv5mOoxMcOAXDouw3mVzLbsRQuLN/bYvq38sAKXl20=\n" +
    "-----END RSA PRIVATE KEY-----"
