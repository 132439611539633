import {EStripeProduct} from "./stripe";

export const BOOSTS_COUNT = 100;
export const PREMIUM_PRICE_USD = 9.99;

export enum EBonusCount {
    BOOST_LEVEL1 = 1,
    BOOST_LEVEL2 = 5,
    BOOST_LEVEL3 = 10,
    INSTANT_MATCH_LEVEL1 = 1,
    INSTANT_MATCH_LEVEL2 = 5,
    INSTANT_MATCH_LEVEL3 = 10
}

export interface IConsumable {
    title?: string,
    count: number,
    price: number,
    product: EStripeProduct,
    discount?: number,
}

export interface IPremium {
    title?: string,
    bonus: {
        label: string,
        sub: string
    }[]
}

export interface IPremiumPayload {
    type: EPremiumType,
    count?: number
}

export enum EPremiumType {
    premium = "premium",
    boost = "boost",
    instantMatch = "instantMatch",
}
