<ion-header>
    <div class="top-container-title">
        <div class="content-title">
            <ng-container *ngIf="type === EPremiumChange.boost">
                {{"profileBoostsTitle" | translate}}
            </ng-container>

            <ng-container *ngIf="type === EPremiumChange.instantMatch">
                {{"instantMatchTitle" | translate}}
            </ng-container>
        </div>

        <div class="top-actions">
            <div (click)="close()" class="rounded-icon">
                <ion-icon color="myprimary" name="close"></ion-icon>
            </div>
        </div>
    </div>
</ion-header>
<ion-content class="ion-padding">

    <div *ngIf="type === EPremiumChange.boost" class="description">
        {{"profileBoostsDescription" | translate:{boostsCount: BOOSTS_COUNT} }}
    </div>
    <div *ngIf="type === EPremiumChange.instantMatch" class="description">
        {{"instantMatchDescription" | translate}}
    </div>


    <swiper-container
            [loop]="false"
            [modules]="swiperModules" [slidesPerView]="1" effect="coverflow"
            pagination="true">
        <swiper-slide *ngFor="let c of consumables">
            <div class="item">
                <div *ngIf="c.title" class="title">
                    {{c.title | translate}}
                </div>

                <div class="content">
                    <div class="count">
                        {{c.count}}
                        <ng-container *ngIf="type === EPremiumChange.boost">
                            {{"boosts" | translate}}
                        </ng-container>

                        <ng-container *ngIf="type === EPremiumChange.instantMatch">
                            {{"instantMatchPurchase" | translate}}
                        </ng-container>
                    </div>

                    <div class="rounded-icon">
                        <ion-icon *ngIf="type === EPremiumChange.boost" color="myprimary" name="flash"></ion-icon>
                        <ion-icon *ngIf="type === EPremiumChange.instantMatch" color="myprimary"
                                  name="magnet"></ion-icon>
                    </div>

                    <div class="price">
                        {{"priceEach" | translate:{price: c.price | customUnit:'$'} }}
                    </div>

                    <div *ngIf="getDiscount(c.price)" class="discount">
                        {{"discount" | translate:{discount: getDiscount(c.price) | customUnit:'%'} }}
                    </div>
                    <ion-button (click)="submit(c.product)"
                                class="ion-margin-top"
                                color="myprimary"
                                expand="full"
                                mode="ios"
                                shape="round">
                        {{"select" | translate}}
                    </ion-button>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>

</ion-content>
