import * as forge from "node-forge";
import {STORAGE_KEY} from "../models/storage_key";
import {PUBLIC_KEY} from "../models/public_key";
import {PRIVATE_KEY} from "../models/private_key";


export function encryptChunk(fullText: string): string[] {
    const chunkLength = 80; // Length of each chunk.

    const chunks = splitIntoChunks(fullText, chunkLength);
    // console.log(chunks);
    const arrayEncrypt: string[] = [];
    for (const c of chunks) {
        const encrypted = encryptData(c);
        arrayEncrypt.push(encrypted)
    }

    return arrayEncrypt;
}

export function encryptData(data: string): string {
    if (!data) {
        return '';
    }
    // Convertissez la clé publique PEM en une clé publique Forge
    const publicKey = forge.pki.publicKeyFromPem(PUBLIC_KEY);

// Chiffrez des données avec la clé publique
    const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });
    // console.log("encrypted", encrypted);
    return encrypted;
}

export function encryptStorageData(dataToEncrypt: string): string {
    if (!dataToEncrypt) {
        return '';
    }
// Génération d'un vecteur d'initialisation (IV) aléatoire
    const iv = forge.random.getBytesSync(16);

    // Décoder la clé base64 en un tableau d'octets
    const keyBytes = forge.util.decode64(STORAGE_KEY);


// Création d'un objet de chiffrement AES en mode CBC
    const cipher = forge.cipher.createCipher('AES-CBC', keyBytes);

// Initialisation avec la clé et le IV
    cipher.start({iv: iv});

// Chiffrement des données
    cipher.update(forge.util.createBuffer(dataToEncrypt, 'utf8'));
    cipher.finish();

// Récupération des données chiffrées et IV
    const encryptedData = cipher.output.getBytes();
    const base64IV = forge.util.encode64(iv);
    const encryptedData64 = forge.util.encode64(encryptedData);

    // on concatene les data et le iv
    return encryptedData64 + '.' + base64IV;
}

export function decryptStorageData(data: string | null): string {
    if (!data) {
        return '';
    }
    const parts = data.split('.');

// Données chiffrées (en base64)
    const base64EncryptedData = parts[0]; // Remplacez par les données chiffrées
    const base64IV = parts[1]; // Remplacez par l'IV

// Décodage de l'IV depuis la base64
    const iv = forge.util.decode64(base64IV);

    const keyBytes = forge.util.decode64(STORAGE_KEY);

// Création d'un objet de déchiffrement AES en mode CBC
    const decipher = forge.cipher.createDecipher('AES-CBC', keyBytes);

// Initialisation avec la clé et le IV
    decipher.start({iv: iv});

// Décodage des données chiffrées depuis la base64
    const encryptedData = forge.util.decode64(base64EncryptedData);

// Déchiffrement des données
    decipher.update(forge.util.createBuffer(encryptedData));
    decipher.finish();

// Récupération des données originales déchiffrées
    const decryptedData = decipher.output.toString();

    // console.log('Données déchiffrées :', decryptedData);

    return decryptedData;
}

// Utilisé pour generer les clés privées et publics
export function generateKeys(bits = 2048) {
    // Générez une paire de clés RSA (clé privée et clé publique)
    const rsa = forge.pki.rsa;
    const keyPair = rsa.generateKeyPair({bits: bits});

// Convertissez les clés en format PEM (Privacy-Enhanced Mail)
    const privateKeyPem = forge.pki.privateKeyToPem(keyPair.privateKey);
    const publicKeyPem = forge.pki.publicKeyToPem(keyPair.publicKey);
    console.log("publicKeyPem", publicKeyPem);
    console.log("privateKeyPem", privateKeyPem);
}

export function generateStorageKeys() {
    const aes256Key = forge.random.getBytesSync(32);
    const key = forge.util.encode64(aes256Key)
    console.log('Clé de chiffrement AES-256 générée :', key);
    return key;
}

function splitIntoChunks(text: string, chunkLength: number) {
    const chunks: string[] = [];
    for (let i = 0; i < text.length; i += chunkLength) {
        chunks.push(text.slice(i, i + chunkLength));
    }
    return chunks;
}


export function decryptData(encrypted: string): string {
    try {
        // Convertissez la clé privée PEM en une clé privée Forge
        const privateKey = forge.pki.privateKeyFromPem(PRIVATE_KEY);

// Déchiffrez les données
        const decryptedData = privateKey.decrypt(encrypted, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha256.create()
            }
        });
        // console.log("decryptedData", decryptedData);
        return decryptedData;
    } catch (e) {
        console.error(e);
    }

    return '';
}

export function decryptChunk(chunks: string[]): any {
    let decryptedString = ''
    for (const c of chunks) {
        // console.log(c);
        decryptedString += decryptData(c);
    }

    try {
        const data = JSON.parse(decryptedString);
        return data;
    } catch (e) {
        console.error(e);
    }

    return {};
}

export function generateUniqueValue(string1: string, string2: string): string {

    // Hash the sorted string using SHA-256
    const md1 = forge.md.sha256.create();
    md1.update(string1, 'utf8');
    const hash1 = md1.digest().toHex();
    const md2 = forge.md.sha256.create();
    md2.update(string2, 'utf8');
    const hash2 = md2.digest().toHex();

    // Concatenate the strings
    const concatenatedString = hash1 + hash2;

    // Convert the concatenated string to an array of characters
    const charArray = concatenatedString.split('');

    // Sort the array of characters
    charArray.sort();

    // Join the sorted characters back into a string
    const sortedString = charArray.join('');

    const md3 = forge.md.sha256.create();
    md3.update(sortedString, 'utf8');
    const hash3 = md3.digest().toHex();

    return hash3;
}


// TODO
export function convertHexToDeterministicString(inputHex: string): string {
    const hexArray = inputHex.match(/.{1,2}/g); // Split the hex string into pairs
    if (!hexArray) {
        throw new Error('Invalid hex string');
    }

    // Convert each pair from hex to decimal and then to the corresponding character
    // const asciiString = hexArray.map((hexPair) => String.fromCharCode(parseInt(hexPair, 16))).join('');

    // Use node-forge to produce a deterministic random string
    const md = forge.md.sha256.create();
    md.update(inputHex);
    const hashedHex = md.digest().toHex();

    // Convert the hashed hex to a string
    const characters = 'MFGSn42op7qrs9aTkQcDEOwHIJKLtuRxyPUd3efg56hiAZ8lmbBCVNj1WXY0vz';
    let result = '';
    for (let i = 0; i < 28; i++) {
        const index = parseInt(hashedHex.charAt(i), 16) % characters.length;
        result += characters.charAt(index);
    }

    // YXVfh6R8EghTxFCCTO4C4dFP4Vv2
    // apnqGGoTanqTqn2p94ssMFsT2pGa
    return result;
}

