import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {getAuth, signOut} from 'firebase/auth';
import {ERoutes} from "@models/general";
import {isTwitterProvider} from "@helpers/misc";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(public router: Router,
                private ngZone: NgZone) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        // console.log(role);
        return new Promise((resolve) => {
            const auth = getAuth();
            const currentUser = auth?.currentUser;
            if (isTwitterProvider(currentUser?.providerData) || currentUser?.emailVerified) {
                resolve(true);
            } else {
                // user is refreshing the page so we have to wait to firebase to init
                setTimeout(() => {
                    const auth2 = getAuth();
                    const currentUser2 = auth2?.currentUser;
                    if (isTwitterProvider(currentUser2?.providerData) || currentUser2?.emailVerified) {
                        resolve(true);
                    } else {
                        signOut(auth2);
                        this.navigate(ERoutes.LOGIN);
                        resolve(false);
                    }
                }, 2000);
            }
        });
    }

    navigate(route: string) {
        this.ngZone.run(() => {
            this.router.navigate([route], {replaceUrl: true});
        })
    }


}
